import "./index.scss";
import React from "react";

export default function NotFound() {
  return (
    <div className="md-not-found-page">
      <img
        src="/assets/img/offer-images/on-your-team.png"
        className="md:max-h-96 max-h-72 mmr-4"
      />

      <div className="not-found-content block text-center md:mr-24 mr-4">
        <h2 className="text-9xl">404</h2>
        <p className="text-xl w-60">
          The page you are looking for does not exist
        </p>
        <a href="/">Return to Home Page</a>
      </div>
    </div>
  );
}
