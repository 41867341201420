import "./index.scss";
import React from "react";
import ProCard from "./ProCard";
import {
  Anchor,
  // Anchor,
  Box,
  Center,
  Divider,
  ScrollArea,
  Stack,
  Title,
  useMantineTheme,
} from "@mantine/core";
import ThumbtackCredit from "../../../components/ThumbtackCredit";

export default function ThumbtackPros({
  pros,
  setShowPros,
  reportId,
  numericId,
}) {
  const theme = useMantineTheme();

  if (!pros || (pros && !pros.length)) {
    return null;
  }

  return (
    <Stack style={{ position: "relative" }}>
      <Anchor
        size="sm"
        onClick={() => setShowPros(false)}
        sx={(theme) => ({
          position: "absolute",
          left: theme.fn.smallerThan("sm") ? 10 : -15,
          top: theme.fn.smallerThan("sm") ? 10 : 5,
          display: "flex",
          alignItems: "center",
        })}
        weight="bold"
        color="dimmed"
      >
        <i className="fa fa-angle-left fa-lg mr-1" /> Back
      </Anchor>

      {!!pros && !!pros.length && (
        <Title
          order={1}
          align="center"
          mx="md"
          sx={() => ({
            fontSize: "auto",
            marginTop: 0,
            [theme.fn.smallerThan("sm")]: {
              fontSize: 24,
              marginTop: 50,
            },
          })}
        >
          Great News - we have local professionals ready to help!
        </Title>
      )}

      <ScrollArea
        style={{
          height: pros.length * 250,
          maxHeight: 700,
          overflowX: "none",
        }}
      >
        <Stack spacing="sm" mt="md">
          {pros.map((pro) => {
            return (
              <Box key={pro.service_id} mb="sm">
                <ProCard
                  key={pro.service_id}
                  pro={pro}
                  reportId={reportId}
                  numericId={numericId}
                />
                <Divider color={theme.colors.gray[1]} />
              </Box>
            );
          })}
        </Stack>
      </ScrollArea>

      <Center my="sm">
        <ThumbtackCredit />
      </Center>
    </Stack>
  );
}
