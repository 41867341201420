import "./index.scss";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RootFooter from "../Root-Footer";
import Geocode from "react-geocode";
import GoogleMapReact from "google-map-react";
import DemoModal from "../../ui/modals/DemoModal";
import LoadingSpinner from "../Loading-Spinner";
import PendingOrder from "../Pending-Order";
import RepairTable from "./RepairTable/index";
import SharingModal from "../../ui/modals/SharingModal";
import UserRepairTable from "./UserRepairTable";
import { parseQueryString } from "../../services/parseQueryString";
import { v1, v2 } from "../../services/axios";
import { formatCost } from "../../services/helpers";
import { Button, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import { formatAddress } from "../../helpers/utils";
import FeatureHeader from "../FeatureHeader";

export default function Domoreport({
  showNotes,
  accountType,
  center,
  domoreport,
  reportID,
  permission,
  expDate,
  setCenter,
  toggleNotes,
  toggleModal,
}) {
  const [repairsSummary, setRepairsSummary] = useState(null);
  const [listNotes, setListNotes] = useState(null);
  const [requestListMemo, setRequestListMemo] = useState(null);
  const [showCreateRequestModal, setShowCreateRequestModal] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showExampleNotes, setShowExampleNotes] = useState(false);
  const [showRequestList, setShowRequestList] = useState(false);
  const [showShares, setShowShares] = useState(false);
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState(false);
  const [priority, setPriority] = useState(
    parseQueryString(window.location.search).priority || "All"
  );
  const address = formatAddress(reportData);
  const theme = useMantineTheme();

  const { location, history } = window;
  const is_public = parseQueryString(location.search).public || null;

  useEffect(() => {
    if (priority === "Requests") {
      setShowRequestList(true);
    }

    localStorage.removeItem("md-public-list");
    getDomoreport();
  }, []);

  useEffect(() => {
    setLoading(true);
    getDomoreport();
  }, [reportID]);

  useEffect(() => {
    if (reportData) {
      const allRepairs = [...reportData.repairs, ...reportData.userRepairs];
      updateRequestList(allRepairs);
      updateRepairsSummary(reportData.repairs);
      if (reportData.request.notes && listNotes == null) {
        setListNotes(reportData.request.notes);
      }
    }
  }, [reportData]);

  useEffect(() => {
    if (priority === "Requests" && !showRequestList) {
      setShowRequestList(true);
    } else if (priority !== "Requests" && showRequestList) {
      setShowRequestList(false);
    }
  }, [priority]);

  async function getDomoreport(id) {
    if (id) {
      await getV2Report(id);
    } else {
      const { data } = await v1.get(`/domoreport/${reportID}`);
      if (data.sharingId) {
        await getV2Report(data.sharingId);
      }
    }
    setLoading(false);
  }

  async function getV2Report(id) {
    const v2ReportRes = await v2.get(`/domoreports/${id}`);
    if (v2ReportRes.data) {
      setReportData(v2ReportRes.data);
      Geocode.setApiKey("AIzaSyDq3s89D1S_E4d28YjgmeiXwlLz1LlFAaU");
      Geocode.fromAddress(formatAddress(v2ReportRes.data)).then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter(lat, lng);
      });
    }
  }

  function showSharing(e) {
    if (
      expDate != null &&
      expDate < new Date() &&
      window.location.pathname !== "/login"
    ) {
      localStorage.removeItem("majordomo-token");
      localStorage.removeItem("majordomo-temp-token");
      localStorage.removeItem("majordomo-user");
      history.pushState(null, "", "/login");
    } else if (!showShares) {
      setShowShares(true);
    }
  }

  function updatePriority(key) {
    const show = priority === "Requests";
    setShowRequestList(show);

    const searchParams = new URLSearchParams({
      report: reportID,
      priority: key,
    });

    setPriority(key);
    history.pushState(
      null,
      "",
      `/repair-estimate?${is_public ? `public=1` : ""}` +
        searchParams.toString()
    );
  }

  function openIntercom(e) {
    e.preventDefault();

    let message = `Hello! I have a question about my Home Repair Estimate for address ${address}. Specifically…`;
    window.Intercom("showNewMessage", message);
  }

  async function updateDisplaySettings(data) {
    const reportID = parseQueryString(window.location.search).report;

    try {
      const updatedDomoreportData = await v1.put(
        `/domoreport/${reportID}/request-list`,
        data
      );
      if (updatedDomoreportData.data) {
        // console.log(updatedDomoreportData.data);
      }
    } catch (error) {
      console.error("Update Repair Estimate Error", error);
    }
  }

  function updateRepairsSummary(repairs) {
    const summaryMemo = {};
    for (let i = 0; i < repairs.length; i++) {
      const repair = repairs[i];
      const { quote, request, totalEstimates } = repair;
      const costs = { low: 0, high: 0 };

      if (
        (request.type === "Repair" || request.type === "Replace") &&
        quote.amount
      ) {
        costs.low = quote.amount;
        costs.high = quote.amount;
      } else {
        const combined =
          totalEstimates.combined.low !== totalEstimates.separate.low &&
          totalEstimates.combined.high !== totalEstimates.separate.high;

        costs.low = combined
          ? totalEstimates.combined.low
          : totalEstimates.separate.low;
        costs.high = combined
          ? totalEstimates.combined.high
          : totalEstimates.separate.high;
      }

      if (summaryMemo[repair.priority]) {
        ++summaryMemo[repair.priority].total;

        summaryMemo[repair.priority].low =
          Number(summaryMemo[repair.priority].low) + costs.low;
        summaryMemo[repair.priority].high =
          Number(summaryMemo[repair.priority].high) + costs.high;
      } else {
        summaryMemo[repair.priority] = {
          total: 1,
          low: costs.low,
          high: costs.high,
        };
      }

      if (summaryMemo.All) {
        ++summaryMemo.All.total;
        summaryMemo.All.low = Number(summaryMemo.All.low) + costs.low;
        summaryMemo.All.high = Number(summaryMemo.All.high) + costs.high;
      } else {
        summaryMemo.All = {
          total: 1,
          low: costs.low,
          high: costs.high,
        };
      }
    }

    setRepairsSummary(summaryMemo);
  }

  function updateRequestList(repairs) {
    const requestMemo = {};
    for (let i = 0; i < repairs.length; i++) {
      const repair = repairs[i];
      const { quote, request, repairType, totalEstimates } = repair;
      let low = 0;
      let high = 0;

      let requestType = request && request.type;
      if (!requestType || requestType === "No-Request") {
        requestType = "Exclude";
      }

      if (requestType === "Credit" && request.credit) {
        low = request.credit;
        high = request.credit;
      } else if (
        (requestType === "Repair" || requestType === "Replace") &&
        quote.amount
      ) {
        low = quote.amount;
        high = quote.amount;
      } else if (
        (requestType === "Repair" || requestType === "Replace") &&
        !quote.amount
      ) {
        const combined =
          totalEstimates.combined.low !== totalEstimates.separate.low &&
          totalEstimates.combined.high !== totalEstimates.separate.high;

        if (combined) {
          low = totalEstimates.combined.low;
          high = totalEstimates.combined.high;
        } else {
          low = totalEstimates.separate.low;
          high = totalEstimates.separate.high;
        }
      } else if (repairType && repairType === "user" && quote.amount) {
        low = quote.amount;
        high = quote.amount;
      } else if (
        totalEstimates &&
        totalEstimates.separate.low &&
        totalEstimates.separate.high
      ) {
        low = totalEstimates.separate.low;
        high = totalEstimates.separate.high;
      }

      if (requestType in requestMemo) {
        ++requestMemo[requestType].total;
        requestMemo[requestType].low =
          Number(requestMemo[requestType].low) + low;
        requestMemo[requestType].high =
          Number(requestMemo[requestType].high) + high;
        requestMemo[requestType].repairs.push(repair);
      } else {
        requestMemo[requestType] = {
          total: 1,
          low: low,
          high: high,
          repairs: [repair],
        };
      }

      if (!requestMemo.All && requestType !== "Exclude") {
        requestMemo.All = {
          total: 1,
          low: low,
          high: high,
          repairs: [repair],
        };
      } else if (requestType !== "Exclude") {
        ++requestMemo.All.total;
        requestMemo.All.low = Number(requestMemo.All.low) + low;
        requestMemo.All.high = Number(requestMemo.All.high) + high;
        requestMemo.All.repairs.push(repair);
      }
    }

    setRequestListMemo(requestMemo);
  }

  function handlePrint(e) {
    e.preventDefault();
    if (document.execCommand("print", false, undefined)) {
      return true;
    } else {
      return window.print();
    }
  }

  function renderAvailable(center) {
    const userCanEdit = permission !== "VIEWER" || accountType === "ADMIN";

    return (
      <div className="md-domoreport-page no-print">
        <div className="row md-actions-container">
          <div className="col md-actions">
            <Group spacing="xs" style={{ justifyContent: "flex-end" }}>
              {(permission === "OWNER" || accountType === "ADMIN") &&
                !is_public && (
                  <Button
                    onClick={showSharing}
                    style={{ backgroundColor: theme.colors.blue[4] }}
                    rightIcon={<i className="fa fa-share" />}
                  >
                    Share Report
                  </Button>
                )}
              <Button
                onClick={handlePrint}
                style={{ backgroundColor: theme.colors.blue[4] }}
                rightIcon={<i className="fa fa-print" />}
              >
                Print Report
              </Button>

              <Button
                component="a"
                style={{ backgroundColor: theme.colors.blue[4] }}
                href={`/home-inspection?${
                  is_public ? "public=1&" : ""
                }report=${reportID}`}
              >
                View Original Inspection
              </Button>
            </Group>
          </div>
        </div>

        <div className="md-domoreport-body container bg-white">
          {showShares && (
            <SharingModal
              address={address}
              hideModal={hideModal}
              reportID={reportID}
            />
          )}

          {showDemoModal && (
            <DemoModal
              hideModal={() => setShowDemoModal(false)}
              img="/assets/img/demos/additional-request-demo.png"
              text="upload any additional requests for projects not included in your home inspection"
            />
          )}

          <FeatureHeader
            title="Home Repair Estimate"
            variant="center"
            showCredit={true}
          />

          {renderMapSection(center, "sm")}

          <div className="overview-wrapper">
            <div className="section col-12 col-md-3">
              <div>
                <p className="label">Original Inspection</p>
                <p className="data">
                  {reportData.inspection.date
                    ? reportData.inspection.date
                    : "Unknown"}
                </p>
                <p className="label">Conditions</p>
                <p className="data">
                  {reportData.inspection.condition
                    ? reportData.inspection.condition
                    : "Unknown"}
                </p>
              </div>
            </div>
            <div className="section col-12 col-md-3">
              <div>
                <p className="label">Performed By</p>
                {reportData.inspector && reportData.inspector.name ? (
                  <p className="data">
                    {reportData.inspector.name}
                    <br />
                    of {reportData.inspector.company}
                  </p>
                ) : (
                  <p className="data">Unknown</p>
                )}

                {reportData.inspector && reportData.inspector.email && (
                  <>
                    <p className="label">{reportData.inspector.email}</p>
                    <p className="label">{reportData.inspector.phone}</p>
                  </>
                )}
              </div>
            </div>
            <div className="section col-12 col-md-3">
              <div>
                <p className="label">Real Estate Agent</p>
                {reportData.broker && reportData.broker.name && (
                  <p className="data">
                    {reportData.broker.name}
                    <br />
                    of {reportData.broker.company}
                  </p>
                )}

                {reportData.broker && reportData.broker.email && (
                  <>
                    <p className="label">{reportData.broker.email}</p>
                    <p className="label">{reportData.broker.phone}</p>
                  </>
                )}
              </div>
            </div>
            <div className="section col-12 col-md-3">
              <div>
                <p className="label">Property Address</p>
                <p className="data">{address}</p>
              </div>
            </div>
          </div>

          {center && renderMapSection(center, "md")}

          {renderSummarySection(repairsSummary)}

          {!showRequestList && renderNotesSection(reportData.majordomo.note)}

          {showRequestList &&
            requestListMemo &&
            renderRequestListSummary(reportID, userCanEdit)}
          {showRequestList &&
            requestListMemo &&
            renderRequestListNotes(userCanEdit)}

          {/* Repair Table */}
          <div className="repair-detail-wrapper" id="repairWrapper">
            <RepairTable
              reportData={reportData}
              getDomoreport={getDomoreport}
              priority={priority}
              reportID={reportID}
              showRequestList={showRequestList}
              updateRepairsSummary={updateRepairsSummary}
              userCanEdit={userCanEdit}
            />
          </div>

          {
            <div className="repair-detail-wrapper">
              {showRequestList && (
                <h4 className="report-section-header">Additional Requests</h4>
              )}
              <UserRepairTable
                reportData={reportData}
                getDomoreport={getDomoreport}
                reportID={reportID}
                setShowCreateRequestModal={setShowCreateRequestModal}
                showCreateRequestModal={showCreateRequestModal}
                showRequestList={showRequestList}
                userCanEdit={userCanEdit}
              />
            </div>
          }

          <div className="md-disclaimer">
            <p>
              Disclaimer: Thumbtack is not a home inspection company and is not
              responsible for the contents of your home inspection report.
              Prices listed in your Home Repair Estimate are estimates and
              actual prices will vary depending on many factors. Prioritizations
              in your Home Repair Estimate are based upon either the original
              inspection report results, or Thumbtack’s judgment made without
              expertise and without an opportunity to view the property.
              Prioritizations are solely an opinion that should not be relied
              upon without consulting a service professional with a
              specialization in the issue at hand. Inspector Notes are based
              solely on a review of your home inspection report and should not
              be relied upon without consulting a service professional. Always
              consult a professional to determine next steps. Your use of the
              Home Repair Estimate constitutes your agreement with Thumbtack’s{" "}
              <a
                href="https://www.thumbtack.com/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://www.thumbtack.com/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>

        <RootFooter />
      </div>
    );
  }

  function renderMapSection(center, display) {
    const MapMarker = () => (
      <Group style={{ width: 50 }}>
        <div className="map-marker" />
        <div className="map-marker-text">
          <p>
            <i className="fas fa-map-marker-alt" /> {address}
          </p>
        </div>
      </Group>
    );

    return (
      <div
        className={
          display === "md"
            ? "map-wrapper d-none d-md-flex"
            : "map-wrapper d-flex d-md-none"
        }
      >
        <div className="col-12 col-md-6 image">
          {reportData.property.photo ? (
            <img src={reportData.property.photo} alt="property" width="100%" />
          ) : address ? (
            <div
              className="property-street-image"
              style={{
                backgroundImage: `url(https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${encodeURI(
                  address
                )}&pitch=-0.76&key=AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU)`,
              }}
            />
          ) : (
            <div className="property-street-image" />
          )}
        </div>

        <div
          className="col-12 col-md-6 map"
          style={{ height: "340px", width: "100%" }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU",
            }}
            center={center}
            defaultZoom={16}
          >
            <MapMarker lat={center.lat} lng={center.lng} />
          </GoogleMapReact>
        </div>
      </div>
    );
  }

  function renderSummarySection(summary) {
    const keys = ["All", "Urgent", "Pressing", "Cosmetic", "Requests"];
    const requestListLoaded = !!(requestListMemo && requestListMemo["All"]);

    return (
      <div className="summary-wrapper">
        <h4 className="report-section-header">Repairs Summary</h4>
        <div className="summary-cards">
          {keys.map((key) => {
            let label = key;

            if (key === "Requests") {
              return (
                <div
                  key={key}
                  className={`card ${priority !== key ? "card-inactive" : ""}`}
                  onClick={() => updatePriority(key)}
                >
                  <div className="heading bg-uigray500">Request List</div>
                  <div className="number-repairs-wrapper">
                    <div className="label">Number of Requests</div>
                    <div className="number">
                      {requestListLoaded ? requestListMemo["All"].total : "-"}
                    </div>
                    <div className="label">Estimated Cost</div>
                    <div className="number">
                      {requestListLoaded
                        ? formatCost(
                            Number(
                              (requestListMemo["All"].low +
                                requestListMemo["All"].high) /
                                2
                            ).toFixed(0)
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={key}
                  className={
                    (key === "All" && priority == null) || priority === key
                      ? "card"
                      : "card card-inactive"
                  }
                  onClick={() => updatePriority(key)}
                >
                  <div className={`heading md-${key}`}>{label} Repairs</div>
                  <div className="number-repairs-wrapper">
                    <div className="label">Number of Repairs</div>
                    <div className="number">
                      {summary && summary[key] ? summary[key].total : "-"}
                    </div>
                    <div className="label">Estimated Cost</div>
                    <div className="number">
                      {summary && summary[key]
                        ? formatCost(
                            Number(
                              (summary[key].low + summary[key].high) / 2
                            ).toFixed(0)
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }

  function renderNotesSection() {
    const { note, name, picture, title } = reportData.majordomo;
    const showDefault = localStorage.getItem("show-default");
    const show = !!(showNotes || parseInt(showDefault) === 1);

    return (
      <div className={`notes ${show ? "show" : ""}`}>
        <Link to="#" className="float-right" onClick={toggleNotes}>
          {show ? (
            <i className="fa fa-angle-up fa-lg" />
          ) : (
            <i className="fa fa-angle-down fa-lg" />
          )}
        </Link>

        <h2 className={`${show ? "" : "p-0"}`}>Inspector Notes</h2>
        <div className={`notes-footer-wrapper ${show ? "" : "d-none"}`}>
          <div className="footer-content">
            <p className="desc" dangerouslySetInnerHTML={{ __html: note }} />
            <div
              className="img"
              style={{
                background: `url(${picture})`,
                backgroundSize: "cover",
              }}
            />
            <div className="name-wrapper">
              <div className="name">
                {name}
                <span className="icon icon-6">
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleModal();
                    }}
                  >
                    <img src="/assets/icons/Contact.svg" width="20" alt="" />
                  </Link>
                </span>
              </div>
              <div className="title">{title}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderRequestListSummary(reportID, userCanEdit) {
    let repairReplace = { total: 0, low: 0, high: 0 };
    if (requestListMemo["Repair"]) {
      repairReplace.total += requestListMemo["Repair"].total;
      repairReplace.low += requestListMemo["Repair"].low;
      repairReplace.high += requestListMemo["Repair"].high;
    }

    if (requestListMemo["Replace"]) {
      repairReplace.total += requestListMemo["Replace"].total;
      repairReplace.low += requestListMemo["Replace"].low;
      repairReplace.high += requestListMemo["Replace"].high;
    }

    return (
      <div className="my-list-summary">
        <div className="list-item col-12 col-md-3">
          <div className="list-img-wrapper">
            <img src="/assets/icons/Request-List/Repair.svg" alt="" />
          </div>
          <Stack spacing={1}>
            <Text size="xs" color="dimmed">
              Repair/Replace
            </Text>
            <Text size="sm" weight="bold">
              {repairReplace.total} Requests
            </Text>
            <Text size="sm" weight="bold">
              {formatCost(repairReplace.low, repairReplace.high)}
            </Text>
          </Stack>
        </div>
        <div className="list-item col-12 col-md-3">
          <div className="list-img-wrapper">
            <img src="/assets/icons/Request-List/Credit.svg" alt="" />
          </div>

          <Stack spacing={1}>
            <Text size="xs" color="dimmed">
              Credit
            </Text>
            <Text size="sm" weight="bold">
              {requestListMemo["Credit"] ? requestListMemo["Credit"].total : 0}{" "}
              Requests
            </Text>
            <Text size="sm" weight="bold">
              {requestListMemo["Credit"]
                ? formatCost(requestListMemo["Credit"].low)
                : `$${0}`}
            </Text>
          </Stack>
        </div>
        <div className="list-item col-12 col-md-3">
          <div className="list-img-wrapper">
            <img src="/assets/icons/Request-List/As-Is.svg" alt="" />
          </div>

          <Stack spacing={1}>
            <Text size="xs" color="dimmed">
              As-Is
            </Text>
            <Text size="sm" weight="bold">
              {requestListMemo["As-Is"] ? requestListMemo["As-Is"].total : 0}{" "}
              Requests
            </Text>
            <Text size="sm" weight="bold">
              {requestListMemo["As-Is"]
                ? formatCost(
                    requestListMemo["As-Is"].low,
                    requestListMemo["As-Is"].high
                  )
                : `$${0}`}
            </Text>
          </Stack>
        </div>

        <div className="list-item actions col-12 col-md-3">
          {userCanEdit ? (
            <button
              className="btn add-new-btn md-black-text"
              onClick={() =>
                is_public
                  ? setShowDemoModal(true)
                  : setShowCreateRequestModal(true)
              }
            >
              + Add Additional Request
            </button>
          ) : (
            <div />
          )}
          <a
            href={`/request-list?${
              is_public ? "public=1&" : ""
            }report=${reportID}`}
            className={`btn preview-btn ${
              Object.keys(requestListMemo).length === 0 ? "disabled" : ""
            }`}
            rel="noopener noreferrer"
          >
            Preview & Share
          </a>
        </div>
      </div>
    );
  }

  function renderRequestListNotes(userCanEdit) {
    return (
      <div className={!userCanEdit && !listNotes ? "d-none" : "my-list-notes"}>
        <h4 className="report-section-header">
          {userCanEdit ? "Add" : ""} Overall Comments
        </h4>
        <div className="col-12">
          {userCanEdit ? (
            <textarea
              rows="4"
              placeholder="Include any overall comments about your request list here..."
              onChange={(e) => setListNotes(e.target.value)}
              onBlur={() => updateDisplaySettings({ notes: listNotes })}
              value={listNotes || ""}
            />
          ) : (
            <p>{listNotes ? listNotes : ""}</p>
          )}
        </div>
        <div className="md-example-notes-wrapper">
          <p
            onMouseEnter={() => {
              setShowExampleNotes(true);
            }}
            onMouseLeave={() => {
              setShowExampleNotes(false);
            }}
          >
            See example
          </p>
          <div
            className={`md-example-notes ${
              showExampleNotes ? "" : "hidden-notes"
            }`}
          >
            <p>
              <b>Example</b>: "During the general home inspection, the inspector
              found small issues with the siding, so we are including a small
              credit request to cover the cost of that repairs. Please find more
              details below. The buyer is taking on the responsibility for all
              as-is items."
            </p>
          </div>
        </div>
      </div>
    );
  }

  function hideModal() {
    const query = parseQueryString(location.search);
    const reportID = query.report || null;

    setShowShares(false);

    history.pushState(
      null,
      "",
      `?${is_public ? "public=1&" : ""}report=${reportID}` +
        (priority ? `&priority=${priority}` : ``)
    );
  }

  if (!reportData || loading) {
    return <LoadingSpinner />;
  }

  if (reportData && !reportData.publishedAt && accountType !== "ADMIN") {
    return (
      <PendingOrder
        title="DOMOREPORT"
        domoreport={domoreport}
        permission={permission}
        reportID={reportID}
      />
    );
  }

  if (reportData && (reportData.publishedAt || accountType === "ADMIN")) {
    return renderAvailable(center);
  }
}
