import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { string } from "prop-types";

import analytics from "../../services/analytics";

function SEO(props) {
  const { title, description, url } = props;

  // Record pageview event on mount
  useEffect(() => {
    // Give verbose titles semantic event names
    switch (title) {
      case "The Second Half of Your Home Inspection":
        analytics.page("Home");
        break;
      default:
        analytics.page(title);
    }
  }, [title]);

  return (
    <Helmet titleTemplate="%s | Thumbtack">
      {/* Core SEO information */}
      <title>{title}</title>
      <meta name="description" content={description || ""} />

      {/* OpenGraph meta tags */}
      <meta name="og:url" content={url || "https://repair.thumbtack.com/"} />
      <meta name="og:site_name" content="Thumbtack" />
      <meta name="og:type" content="website" />
      <meta name="og:description" content={description || ""} />
      <meta
        name="og:image"
        content={`${process.env.PUBLIC_URL}/assets/img/OpenGraphLogo.png`}
      />

      {/* Twitter meta tags */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@majordomohq" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description || ""} />
      <meta
        name="twitter:image"
        content={`${process.env.PUBLIC_URL}/assets/img/TwitterCardLogo.png`}
      /> */}
    </Helmet>
  );
}

SEO.propTypes = {
  // The title of the webpage. Will be templated with `%s | Majordomo`
  title: string.isRequired,

  // Canonical URL of the webpage.
  url: string,

  // The description of the webpage.
  description: string,
};

SEO.defaultProps = {
  url: "",
  description: "",
};

export default SEO;
