import React from "react";
import { Stack, Text, Image } from "@mantine/core";

export default function ThumbtackCredit() {
  return (
    <Stack spacing={0} align="center">
      <Text size="xs" weight="bold" color="gray">
        POWERED BY
      </Text>

      <Image src="/assets/img/thumbtack/wordmark_dark.png" width={95} />
    </Stack>
  );
}
