import "./index.scss";
import React from "react";
import { Modal } from "react-bootstrap";

export default function DemoModal({ hideModal, img, text }) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={hideModal}
      className="md-demo-modal"
    >
      <Modal.Header closeButton>
        <h4>Preview</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="demo-img-wrapper">
          <img src={img} alt="" />
        </div>
        <div className="demo-info-wrapper">
          <p>
            This modal will allow you to <b>{text}</b>. However, this is just a
            preview of the Home Repair Estimate. To access all available
            features features{" "}
            <a href="/onboard">Order A Home Repair Estimate</a>.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-cancel" onClick={hideModal}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}
