import "./index.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withFetch, { baseUrl } from "../../hocs/withFetch";
import PropertyCard from "../PropertyCard";
import { withRouter } from "react-router-dom";
import PersonalDetails from "./personalDetails";
import RootFooter from "../Root-Footer";
import { Modal, ModalTitle } from "react-bootstrap";
import SharingModal from "../../ui/modals/SharingModal";
import LoadingSpinner from "../Loading-Spinner";
import { v1 } from "../../services/axios";
// import { AffiliateDetails } from "./AffiliateDetails";
import { parseQueryString } from "../../services/parseQueryString";

@withRouter
@withFetch(() => ({
  userReportsData: `/domoreport/userReports`,
  chargeData: `/users/charges`,
  updateUser: (formData) => ({
    userUpdate: {
      url: "/users",
      method: "PUT",
      body: JSON.stringify(formData),
    },
  }),
  updateProperty: (propertyID, name) => ({
    updatedProperty: {
      url: `/property/${propertyID}`,
      method: "PUT",
      force: true,
      refresh: true,
      body: JSON.stringify({ property_name: name }),
    },
  }),
  postProfileImg: (formData, userid) => ({
    postedProfileImg: {
      url: "/users",
      method: "PUT",
      headers: {
        "Content-Type": null,
      },
      body: formData,
      force: true,
      refresh: true,
      andThen: (res) => ({
        newUser: {
          url: `/users/public/${userid}`,
          force: true,
          refreshing: true,
        },
      }),
    },
  }),
  deleteFile: (domoreportID, documentID) => ({
    deletedDocument: {
      url: `domoreport/${domoreportID}/domoreportDocument/${documentID}`,
      method: "DELETE",
    },
  }),
  validatePhone: (user) => ({
    validatedPhone: {
      url: `/contacts/validate/phone?number=${user.phone_number}`,
      force: true,
      refresh: true,
      andThen: (res) => ({
        userUpdate: {
          url: "/users",
          method: "PUT",
          body: res.validNumber ? JSON.stringify(user) : JSON.stringify({}),
        },
      }),
    },
  }),

  archiveDomoreport: (reportID, body) => ({
    archivedDomoreport: {
      body: JSON.stringify(body),
      force: true,
      refresh: true,
      method: "PUT",
      url: `/domoreport/${reportID}/archive`,
    },
  }),
}))
export default class Settings extends Component {
  state = {
    allReports: {},
    showArchived: false,
    showOrderDetails: false,
    showOrderHistory: false,
    showShares: false,
    sharingInputs: 1,
    propertiesPhotos: [],
    domoreportID: null,
    documentID: null,
    mapURL: null,
    showConfirmModal: false,
    showImageModal: false,
    confirm: false,
    userPhoto: null,
  };

  componentDidMount() {
    if (!localStorage.getItem("majordomo-token")) {
      this.props.history.push("/login");
    }
  }

  componentDidUpdate() {
    const { allReports } = this.state;
    const { userReportsData } = this.props;

    if (
      userReportsData &&
      !userReportsData.pending &&
      userReportsData.value &&
      !Object.keys(allReports).length
    ) {
      const { archived, reports, unpublished } = userReportsData.value;
      //Fetch property photos for each report that the user owns.
      let propertiesPhotos = [];
      let activeReports = [...archived, ...reports, ...unpublished];
      if (activeReports.length) {
        activeReports.map(async (report, index) => {
          if (report.domoreport_log_id) {
            const photosData = await v1.get(
              `${baseUrl}/api/1/domoreport/propertyPhotosForDomoreport/${report.domoreport_log_id}`
            );
            if (photosData.data) {
              propertiesPhotos.push({
                photos: photosData.data.documents,
                domoreport_log_id: report.domoreport_log_id,
                property_log_id: report.property_log_id,
              });

              if (index === activeReports.length - 1) {
                const showArchived = !!(
                  reports.length + unpublished.length === 0 &&
                  archived.length > 0
                );
                this.setState({
                  allReports: {
                    owned: [...reports, ...unpublished],
                    archived: [...archived],
                    total:
                      archived.length + reports.length + unpublished.length,
                  },
                  propertiesPhotos: propertiesPhotos,
                  showArchived: showArchived,
                });
              }
            }
          }
        });
      }
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  //Show, hide property image and/or google map modal
  toggleImageModal = (e, fileData, reportID, docID, mapURL) => {
    if (e) {
      e.preventDefault();
      if (e.target.className !== "fas fa-trash-alt fa-lg") {
        this.setState({
          showImageModal: !this.state.showImageModal,
          domoreportID: reportID,
          documentID: docID,
          img: fileData,
          mapURL: mapURL,
        });
      } else {
        this.setState({
          showImageModal: false,
          img: null,
        });
      }
    } else {
      this.setState({
        showImageModal: !this.state.showImageModal,
        domoreportID: reportID,
        documentID: docID,
        img: fileData,
        mapURL: mapURL,
      });
    }
  };

  //Show, hide order details modal for a specific property
  toggleOrderDetails = (e, reportID) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      showOrderDetails: !this.state.showOrderDetails,
      reportID: reportID,
    });
  };

  // Show, hide order history modal. (shows only domoreports that the current user has ordered)
  toggleOrderHistory = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showOrderHistory: !this.state.showOrderHistory });
  };

  // Show, hide sharing preferences modal.
  toggleSharing = (e, reportID, address) => {
    if (e) {
      e.preventDefault();
    }

    if (
      this.props.expDate != null &&
      this.props.expDate < new Date() &&
      window.location.pathname != "/login"
    ) {
      localStorage.removeItem("majordomo-token");
      localStorage.removeItem("majordomo-temp-token");
      localStorage.removeItem("majordomo-user");
      this.props.history.push("/login");
    } else {
      this.setState({
        showShares: !this.state.showShares,
        domoreportID: reportID,
        address: address,
      });
    }
  };

  // Show, hide confirmation text in Image modal.
  toggleConfirm = () => {
    this.setState({ confirm: !this.state.confirm });
  };

  // Show, hide confirmation modal for deleting images.
  toggleConfirmModal = (reportID, docID) => {
    this.setState({
      showConfirmModal: !this.state.showConfirmModal,
      domoreportID: reportID,
      documentID: docID,
    });
  };

  // Handle adding, removing sharing inputs in Sharing Modal.
  setSharing = (e) => {
    e.preventDefault();

    if (e.currentTarget.className === "btn-add-share") {
      this.setState({ sharingInputs: this.state.sharingInputs + 1 });
    } else if (e.currentTarget.className === "btn-remove") {
      this.setState({ sharingInputs: this.state.sharingInputs - 1 });
    }
  };

  // Set user-uploaded profile photo in state.
  setPhoto = (photo) => {
    this.setState({ userPhoto: URL.createObjectURL(photo) });
  };

  // Handle adding a new property photo.
  addPhoto = (e, domoreportID) => {
    let file = e.target.files[0];
    let propertiesPhotos = [...this.state.propertiesPhotos];
    let propertyPhotos = propertiesPhotos.filter((property) => {
      return property.domoreport_log_id == domoreportID;
    });

    if (file) {
      var formData = new FormData();
      formData.append("doc_file", file);
      formData.append("doc_file_name", file.name);
      formData.append("doc_title", file.name);
      formData.append("doc_description", "property photo");
      formData.append("property_photo", true);
      let photos = [...propertyPhotos[0].photos];

      if (formData) {
        fetch(
          `${baseUrl}/api/1/domoreport/documentForDomoreport/${domoreportID}`,
          {
            method: "POST",
            body: formData,
            headers: {
              authorization: localStorage.getItem("majordomo-token"),
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.document_id) {
              photos.push({
                contentType: "application/octet-stream",
                doc_description: "property photo",
                doc_log_id: data.document_id,
                doc_title: file.name,
                mimetype: file.type,
                size: file.size,
              });

              propertiesPhotos.map((property, index) => {
                if (property.domoreport_log_id == domoreportID) {
                  propertiesPhotos[index] = {
                    photos: photos,
                    domoreport_log_id: domoreportID,
                    property_log_id: property.property_log_id,
                  };
                }
              });
              this.setState({ propertiesPhotos: propertiesPhotos });
            } else {
              console.log("something went wrong");
            }
          });
      }
    } else {
      console.log("Please upload a valid file");
    }
  };

  // Handle removing an existing property photo.
  removePhoto = (domoreportID, documentID) => {
    let propertiesPhotos = [...this.state.propertiesPhotos];
    let propertyPhotos = propertiesPhotos.filter((property) => {
      return property.domoreport_log_id == domoreportID;
    });

    let removeIndex = null;
    propertyPhotos[0].photos.map((photo, index) => {
      if (photo.doc_log_id == documentID) {
        removeIndex = index;
      }
    });

    propertyPhotos[0].photos.splice(removeIndex, 1);
    this.props.deleteFile(domoreportID, documentID);
    this.setState({
      propertiesPhotos: propertiesPhotos,
      showConfirmModal: false,
    });
  };

  handleArchive = (reportID, archive) => {
    const { allReports } = this.state;

    let owned = [...allReports.owned];
    let archived = [...allReports.archived];
    let removeIndex = null;

    if (archive) {
      let domoreport = owned.filter((report, index) => {
        if (report.domoreport_log_id === reportID) {
          removeIndex = index;
          return report;
        }
      });

      owned.splice(removeIndex, 1);
      archived.push(domoreport[0]);
    } else {
      let domoreport = archived.filter((report, index) => {
        if (report.domoreport_log_id === reportID) {
          removeIndex = index;
          return report;
        }
      });

      archived.splice(removeIndex, 1);
      owned.push(domoreport[0]);
    }
    this.setState({
      allReports: {
        owned: owned,
        archived: archived,
        total: owned.length + archived.length,
      },
    });
    this.props.handleArchive(reportID, archive);
  };

  // openIntercom = (e) => {
  //   e.preventDefault();
  //   window.Intercom(
  //     "showNewMessage",
  //     "I have a question about Majordomo's Affiliate Program"
  //   );
  // };

  render() {
    const { chargeData, user } = this.props;
    const { allReports } = this.state;
    const { archived, owned } = allReports;
    const chargesLoaded = !!(
      chargeData &&
      !chargeData.pending &&
      chargeData.value
    );
    let propertiesPhotos = [...this.state.propertiesPhotos];

    /* 
			Sort properties by domoreport_log_id 
			and charges by stripe_charge_id.
		*/
    const compare = (a, b) => {
      let sortA = "";
      let sortB = "";
      let comparison = 0;

      if (a.domoreport_log_id) {
        sortA = a.domoreport_log_id;
        sortB = b.domoreport_log_id;
      } else if (a.stripe_charge_id) {
        sortA = a.stripe_charge_id;
        sortB = b.stripe_charge_id;
      }

      if (sortA < sortB) {
        comparison = 1;
      } else if (sortA > sortB) {
        comparison = -1;
      }
      return comparison;
    };

    if (archived && archived.length) {
      archived.sort(compare);
    }

    if (owned && owned.length) {
      owned.sort(compare);
    }

    let sortedCharges = null;
    if (chargesLoaded && chargeData.value.charges) {
      chargeData.value.charges.sort(compare);
    }

    let reportIDs = [];
    if (sortedCharges) {
      reportIDs = sortedCharges.map((charge) => {
        return charge.domoreport_log_id;
      });
    }

    if (!user || !Object.keys(user)) {
      return <LoadingSpinner />;
    } else {
      return this._renderAvailable(
        archived,
        user,
        propertiesPhotos,
        owned,
        reportIDs,
        sortedCharges
      );
    }
  }

  _renderAvailable = (
    archived,
    user,
    propertiesPhotos,
    owned,
    reportIDs,
    sortedCharges
  ) => {
    const {
      address,
      allReports,
      documentID,
      domoreportID,
      img,
      mapURL,
      // reportID,
      showArchived,
      showConfirmModal,
    } = this.state;
    const { referrer } = parseQueryString(window.location.search);
    let loading = true;

    return (
      <div
        className="container col md-settings-page"
        style={{ padding: "15px", paddingBottom: "0px" }}
      >
        {/* {this.state.showOrderDetails &&
          this._renderOrderDetailsModal(user, reportID, sortedCharges)} */}
        {/* {this.state.showOrderHistory &&
          this._renderOrderHistoryModal(user, sortedCharges)} */}
        {this.state.showShares && (
          <SharingModal
            reportID={domoreportID}
            hideModal={this.toggleSharing}
            address={address}
          />
        )}
        {this.state.showImageModal &&
          this._renderImageModal(img, domoreportID, documentID, mapURL)}
        {showConfirmModal && this._renderConfirmModal(domoreportID, documentID)}

        <div>
          <PersonalDetails
            user={user}
            photo={this.state.userPhoto}
            setPhoto={this.setPhoto}
            updateUser={this.props.updateUser}
            postProfileImg={this.props.postProfileImg}
            postedProfileImg={this.props.postedProfileImg}
            postedUserData={this.props.userUpdate}
            validatePhone={this.props.validatePhone}
            validatedPhone={this.props.validatedPhone}
            expDate={this.props.expDate}
          />

          {/* {referrer && referrer === "stripe" && (
            <div className="card affiliate-message">
              <div className="card-title">
                Welcome to the Affiliate Program!
              </div>
              <p>We're so excited that you've joined!</p>
              <p>
                Now you can start earning a commission whenever your client
                orders a Home Repair Estimate.{" "}
                <b>
                  The final step is to attach the flyer to the end of your home
                  inspection
                </b>
                . You can download the PDF from the link below that says
                “Preview and Download.” If your inspection software provider is
                integrated with our system, copy and paste the “Majordomo
                Affiliate ID” code below into their back office to complete the
                integration.{" "}
                <a
                  href="https://intercom.help/majordomo/en/collections/2863032-majordomo-inspector-affiliate-program"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>View instructions for your specific software provider</b>
                </a>
                .
              </p>

              <p>
                Questions or concerns?{" "}
                <a href="#" onClick={this.openIntercom}>
                  Send us a chat message
                </a>{" "}
                and we’ll help you get set up.
              </p>
            </div>
          )}

          {user.user_type === "INSPECTOR" && <AffiliateDetails user={user} />} */}
        </div>

        {!!Object.keys(allReports).length && owned && !!owned.length && (
          <div className="card">
            <div className="card-title">Home Repair Estimates</div>

            {owned.map((property, index) => {
              let reportChargeID = reportIDs.find((reportID) => {
                return property.domoreport_log_id == reportID;
              });

              let address =
                property.address + "," + property.city + "," + property.state;
              address = address.replace(/\s+/g, "+");

              let propertyPhotos = propertiesPhotos.filter((photos) => {
                return photos.property_log_id == property.property_log_id;
              });

              if (index === owned.length - 1) {
                loading = false;
              }

              if (property.domoreport_log_id && property.domoreport_published) {
                return (
                  <PropertyCard
                    addPhoto={this.addPhoto}
                    address={address}
                    archived={false}
                    archiveDomoreport={this.props.archiveDomoreport}
                    chargeData={sortedCharges}
                    deleteFile={this.props.deleteFile}
                    handleArchive={this.handleArchive}
                    index={index}
                    key={property.domoreport_log_id}
                    permission={this.props.permission}
                    property={property}
                    propertyPhotos={propertyPhotos[0]}
                    showConfirmModal={showConfirmModal}
                    toggleConfirmModal={this.toggleConfirmModal}
                    toggleImageModal={this.toggleImageModal}
                    toggleOrderDetails={this.toggleOrderDetails}
                    toggleSharing={this.toggleSharing}
                    updateProperty={this.props.updateProperty}
                    updatedProperty={this.props.updatedProperty}
                  />
                );
              } else {
                return (
                  <div
                    className="md-pending-property"
                    key={property.domoreport_log_id}
                  >
                    <h4 key={property.domoreport_log_id}>
                      Pending Home Repair Estimate #{owned.length - index}
                    </h4>

                    {reportChargeID && (
                      <Link
                        to="#"
                        onClick={(e) => {
                          this.toggleOrderDetails(
                            e,
                            property.domoreport_log_id
                          );
                        }}
                        className="btn btn-tertiary"
                      >
                        View Order Details
                      </Link>
                    )}
                  </div>
                );
              }
            })}

            {loading ? <LoadingSpinner /> : <div />}
          </div>
        )}

        {showArchived &&
          Object.keys(allReports).length &&
          archived &&
          !!archived.length && (
            <div className="card" id="archivedReports">
              <div className="card-title">Archived Home Repair Estimates</div>
              {archived &&
                !!archived.length &&
                archived.map((property, index) => {
                  let address =
                    property.address +
                    "," +
                    property.city +
                    "," +
                    property.state;
                  address = address.replace(/\s+/g, "+");

                  let propertyPhotos = propertiesPhotos.filter((photos) => {
                    return photos.property_log_id == property.property_log_id;
                  });

                  return (
                    <PropertyCard
                      addPhoto={this.addPhoto}
                      address={address}
                      archived={true}
                      archiveDomoreport={this.props.archiveDomoreport}
                      chargeData={sortedCharges}
                      deleteFile={this.props.deleteFile}
                      handleArchive={this.handleArchive}
                      index={index}
                      key={property.domoreport_log_id}
                      permission={this.props.permission}
                      property={property}
                      propertyPhotos={propertyPhotos[0]}
                      showConfirmModal={showConfirmModal}
                      toggleConfirmModal={this.toggleConfirmModal}
                      toggleImageModal={this.toggleImageModal}
                      toggleOrderDetails={this.toggleOrderDetails}
                      toggleSharing={this.toggleSharing}
                      updateProperty={this.props.updateProperty}
                      updatedProperty={this.props.updatedProperty}
                    />
                  );
                })}
            </div>
          )}

        {/* {Object.keys(allReports).length && archived && !!archived.length ? (
          <div className="row add-property-footer">
            <div className="col-lg-12">
              {showArchived && !!archived && !!archived.length && (
                <a
                  className="toggle-archive"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleChange("showArchived", false);
                  }}
                >
                  Hide Archived Home Repair Estimates
                </a>
              )}
              {!showArchived && !!archived && !!archived.length && (
                <a
                  className="toggle-archive"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleChange("showArchived", true);
                  }}
                >
                  Show Archived Home Repair Estimates
                </a>
              )}

              {!!archived && !!archived.length && <br />}
              <a
                className="btn btn-accentlightblue btn-cut-bottom-left"
                href="/checkout"
              >
                + Add Another Property
              </a>
            </div>
          </div>
        ) : (
          <div />
        )} */}

        <RootFooter />
      </div>
    );
  };

  _renderImageModal = (image, reportID, docID, mapURL) => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={this.toggleImageModal}
        className="md-image-modal"
      >
        <Modal.Body>
          <div className="col">
            <img
              src={image || mapURL}
              height="100%"
              width="100%"
              alt={`Home Repair Estimate #${docID} Property`}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex d-sm-none">
          {this.state.confirm ? (
            <div className="md-confirm-wrapper">
              <p>
                <b>Are you sure you want to delete this photo?</b>
              </p>
              <div className="row">
                <button
                  className="btn btn-uigray500"
                  onClick={this.toggleConfirm}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => this.removePhoto(reportID, docID)}
                >
                  Yes
                </button>
              </div>
            </div>
          ) : (
            <div>
              <button
                className="btn btn-uigray500"
                onClick={this.toggleImageModal}
              >
                Close
              </button>
              <button className="btn btn-danger" onClick={this.toggleConfirm}>
                Delete Photo
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  _renderOrderDetailsModal = (user, reportID, chargeData) => {
    let order = chargeData.filter((charge) => {
      return charge.domoreport_log_id == reportID;
    });

    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={this.toggleOrderDetails}
        className="md-order-modal"
      >
        <Modal.Header closeButton>
          <ModalTitle>
            Order Details
            <span className="d-none d-sm-inline">
              {" "}
              |
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  window.print();
                }}
                className="md-print-receipt"
              >
                <i className="fa fa-print" />
              </a>
            </span>
            <img
              src="/assets/logo/MDlogo.png"
              width="200"
              alt="majordomo logo"
            />
          </ModalTitle>
        </Modal.Header>

        <Modal.Body>
          <div className="col">
            <div className="row">
              <div className="col-6 col-md-4">
                <h4>Name</h4>
                <p>{user.user_first_name + " " + user.user_last_name}</p>
              </div>

              <div className="col-6 col-md-4">
                <h4>Payment Method</h4>
                <p>
                  {order[0].brand} {order[0].payment_method}
                </p>
              </div>

              <div className="col-6 col-md-4">
                <h4>Transaction Date</h4>
                <p>{order[0].transactionDate}</p>
              </div>

              <div className="col-6 col-md-4">
                <h4>Transaction Amount</h4>
                <p>{order[0].amount}</p>
              </div>

              <div className="col-6 col-md-4">
                <h4>Reference #</h4>
                <p>{order[0].majordomo_refrence_number}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  _renderOrderHistoryModal = (user, chargeData) => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={this.toggleOrderHistory}
        className="md-order-modal"
      >
        <Modal.Header closeButton>
          <ModalTitle>
            Order History
            <span className="d-none d-sm-inline">
              {" "}
              |
              <a href="javascript:window.print()" className="md-print-receipt">
                <i className="fa fa-print" />
              </a>
            </span>
            <img
              src="/assets/logo/MDlogo.png"
              width="200"
              alt="majordomo icon"
            />
          </ModalTitle>
        </Modal.Header>

        <Modal.Body>
          {chargeData.map((order, index) => {
            return (
              <div className={"col"} key={index}>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <h4>Name</h4>
                    <p>{user.user_first_name + " " + user.user_last_name}</p>
                  </div>

                  <div className="col-6 col-md-4">
                    <h4>Payment Method</h4>
                    <p>
                      {order.brand} {order.payment_method}
                    </p>
                  </div>

                  <div className="col-6 col-md-4">
                    <h4>Transaction Date</h4>
                    <p>{order.transactionDate}</p>
                  </div>

                  <div className="col-6 col-md-4">
                    <h4>Transaction Amount</h4>
                    <p>{order.amount}</p>
                  </div>

                  <div className="col-6 col-md-4">
                    <h4>Reference #</h4>
                    <p>{order.majordomo_refrence_number}</p>
                  </div>
                </div>

                {index < chargeData.length - 1 ? <hr /> : ""}
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
    );
  };

  _renderConfirmModal = (reportID, docID) => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={this.toggleConfirmModal}
        className="md-confirm-modal"
      >
        <Modal.Body>
          <p className="">
            <b>Are you sure you want to delete this photo?</b>
          </p>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => this.removePhoto(reportID, docID)}
          >
            Yes
          </button>
          <button
            className="btn btn-uigray500"
            onClick={this.toggleConfirmModal}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  };
}
