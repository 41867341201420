import "./index.scss";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CreateRequestModal from "../../../ui/modals/CreateRequestModal";
import DemoModal from "../../../ui/modals/DemoModal";
import UserRepairCard from "./UserRepairCard";
import { parseQueryString } from "../../../services/parseQueryString";

function UserRepairTable({
  reportData,
  setShowCreateRequestModal,
  reportID,
  showCreateRequestModal,
  showRequestList,
  getDomoreport,
  userCanEdit,
}) {
  const [repair, setRepair] = useState(null);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const is_public = parseQueryString(window.location.search).public;

  return (
    <div
      className={showRequestList ? "md-user-repair-table" : "d-none"}
      id="repairTable"
    >
      {showCreateRequestModal && (
        <CreateRequestModal
          getDomoreport={getDomoreport}
          hideModal={() => setShowCreateRequestModal(false)}
          repair={repair}
          reportID={reportID}
          setRepair={setRepair}
        />
      )}

      {showDemoModal && (
        <DemoModal
          hideModal={() => setShowDemoModal(false)}
          img="/assets/img/demos/additional-request-demo.png"
          text="upload any additional quotes for repairs not included in your home inspection"
        />
      )}

      <div className="table-header-wrapper col-12">
        <div className="table-header row">
          <div className="table-actions col-12 col-md-4">
            <div className="row">
              <div className="table-action col-12">
                <p>Title</p>
              </div>
            </div>
          </div>

          <div className="table-filters col-12 col-md-8 d-none d-lg-flex">
            <div className="row">
              <div className="table-filter col-3">
                <p>Contractor Quote</p>
              </div>
              <div className="table-filter col-6">
                <p>Quote File</p>
              </div>
              <div className="table-filter col-3 text-right" />
            </div>
          </div>
        </div>
      </div>

      {reportData.userRepairs.map((repair) => {
        return (
          <div className="table-body col-12" key={repair.id}>
            <UserRepairCard
              getDomoreport={getDomoreport}
              setShowCreateRequestModal={setShowCreateRequestModal}
              repair={repair}
              reportID={reportID}
              setRepair={setRepair}
              showRequestList={showRequestList}
              userCanEdit={userCanEdit}
            />
          </div>
        );
      })}

      {!reportData.userRepairs.length && (
        <p className="no-requests-message">No Additional Requests</p>
      )}

      {userCanEdit ? (
        <div className="additional-request-wrapper">
          <button
            className="btn add-new-btn"
            onClick={() => {
              is_public
                ? setShowDemoModal(true)
                : setShowCreateRequestModal("showCreateRequestModal", true);
            }}
          >
            + Add Additional Request
          </button>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
export default withRouter(UserRepairTable);
