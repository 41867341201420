import "./index.scss";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import LoadingSpinner from "../../../components/Loading-Spinner";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfModal({ pdfFile, end, hideModal, start }) {
  const [pages, setPages] = useState(null);

  useEffect(() => {
    if (start && end) {
      const allPages = [];
      for (var i = start; i <= end; i++) {
        allPages.push(
          <Page key={i} pageNumber={i} onLoadSuccess={removeTextLayerOffset} />
        );
      }
      setPages(allPages);
    }
  }, []);

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "12.6%";
      style.left = "12.7%";
      style.transform = "scale(1.345)";
    });
  }

  function onDocumentLoadSuccess({ numPages }) {
    if (!start && !end) {
      const allPages = [];
      for (var i = 1; i <= numPages; i++) {
        allPages.push(
          <Page key={i} pageNumber={i} onLoadSuccess={removeTextLayerOffset} />
        );
      }
      setPages(allPages);
    }
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="md-inspection-modal"
      onHide={hideModal}
      show={true}
      size="md"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Document
          file={pdfFile}
          loading={<LoadingSpinner />}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {pages}
        </Document>
      </Modal.Body>
    </Modal>
  );
}
export default PdfModal;
