import "./index.scss";
import React, { Component } from "react";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import LoadingSpinner from "../Loading-Spinner";

@withRouter
export default class Image extends Component {
  state = {
    showRemove: false,
    img: null,
  };

  componentDidMount() {
    //Download file from specified URL (passed in as sourceURL prop)
    if (this.props.loadedPhotoData == null) {
      Axios.get(`${this.props.sourceURL}`, {
        headers: { Authorization: this.props.token },
        responseType: "arraybuffer",
      })
        .then((res) => {
          let data = new Uint8Array(res.data);
          let raw = ""; //String.fromCharCode.apply(null, data);
          for (let i = 0; i < data.length; i++) {
            raw = raw + String.fromCharCode(data[i]);
          }
          let base64 = btoa(raw);
          let src = "data:image;base64," + base64;
          if ("onLoaded" in this.props) {
            this.props.onLoaded(this.props.docId, src);
          }
          this.setState({
            img: src,
            source: this.props.sourceURL,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ img: this.props.loadedPhotoData });
    }
  }

  componentDidUpdate() {
    if (
      this.props.pageSource == "nav" &&
      this.props.sourceURL != this.state.source
    ) {
      Axios.get(`${this.props.sourceURL}`, {
        headers: { Authorization: this.props.token },
        responseType: "arraybuffer",
      })
        .then((res) => {
          let data = new Uint8Array(res.data);
          let raw = ""; //String.fromCharCode.apply(null, data);
          for (let i = 0; i < data.length; i++) {
            raw = raw + String.fromCharCode(data[i]);
          }
          let base64 = btoa(raw);
          let src = "data:image;base64," + base64;
          if ("onLoaded" in this.props) {
            this.props.onLoaded(this.props.docId, src);
          }

          this.setState({
            img: src,
            source: this.props.sourceURL,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  showRemove = () => {
    this.setState({ showRemove: true });
  };

  hideRemove = () => {
    this.setState({ showRemove: false });
  };

  render() {
    //If image is on settings page, add overlay, otherwise display image as is.
    return this.props.pageSource &&
      this.props.pageSource !== "/repair-estimate/settings" ? (
      <img
        src={this.state.img}
        alt={""}
        height="150px"
        className={
          this.props.customClassName + (this.state.img ? "" : " d-none")
        }
      />
    ) : this.state.img ? (
      <div
        className={
          this.props.primary
            ? "md-property-settings-photo primary"
            : "md-property-settings-photo secondary"
        }
        style={{ background: `url(${this.state.img}) center no-repeat` }}
        onMouseOver={() => {
          this.showRemove();
        }}
        onMouseLeave={() => this.hideRemove()}
      >
        <div
          className={
            this.state.showRemove
              ? "md-remove-image-overlay"
              : "md-remove-image"
          }
          onClick={(e) =>
            this.props.toggleImageModal(
              e,
              this.state.img,
              this.props.reportID,
              this.props.docID
            )
          }
        >
          <i
            class="fas fa-trash-alt fa-lg"
            onClick={(e) =>
              this.props.toggleConfirmModal(
                this.props.reportID,
                this.props.docID
              )
            }
          />
        </div>
      </div>
    ) : (
      <div className="md-loading-img-container">
        <LoadingSpinner />
      </div>
    );
  }
}
