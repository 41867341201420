import "./index.scss";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import RequestListSharingModal from "../../ui/modals/RequestListSharingModal";
import DisplayOptionsInfoModal from "../../ui/modals/DisplayOptionsInfoModal";
import RequestListPrintout from "../RequestListPrintout";
import RequestListSidebar from "../RequestListSidebar";
import LoadingSpinner from "../Loading-Spinner";
import { v1, v2 } from "../../services/axios";

export default function RequestListPrintOptions() {
  const location = useLocation();
  const [user, setUser] = useState({});
  const [domoreport, setDomoreport] = useState({});
  const [showSharingModal, setShowSharingModal] = useState(false);
  const [showDisplayOptionsModal, setShowDisplayOptionsModal] = useState(false);
  const [anchor, setAnchor] = useState();
  const [show, setShow] = useState({});
  const [contractorSort, setContractorSort] = useState();
  const [repairSort, setRepairSort] = useState();
  const [preparedFor, setPreparedFor] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    getDomoreport();
    getUser();
  }, []);

  useEffect(() => {
    if (Object.keys(domoreport).length && !Object.keys(show).length) {
      const { request, show } = domoreport;
      setShow(show);
      setContractorSort(request.sortContractorsBy || "cost_estimate_high");
      setRepairSort(request.sortRepairsBy || "cost_estimate_high");

      if (
        request.preparedFor &&
        request.preparedFor !== "undefined undefined"
      ) {
        setPreparedFor(request.preparedFor);
      } else if (user.firstName) {
        const fullName = `${user.firstName} ${
          user.lastName ? user.lastName : ""
        }`;
        updateRequestList("preparedFor", fullName);
        setPreparedFor(fullName);
      }
    }
  }, [domoreport]);

  async function updateRequestList(key, value) {
    const data = { [key]: value };
    try {
      await v2.patch(
        `/domoreports/${domoreport.sharingId}/request-list/`,
        data
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function getUser() {
    try {
      const { data, status } = await v2.get(`/users/me`);
      if (status === 200) {
        setUser(data);
      }
    } catch (error) {
      if (error.response.status === 401 && !!domoreport.permissions) {
        window.location = "/login";
      }
      console.error(error);
    }
  }

  async function getDomoreport(id) {
    if (id) {
      await getV2Report(id);
    } else {
      const params = new URLSearchParams(location.search);
      const id = params.get("report");
      const { data } = await v1.get(`/domoreport/${id}`);
      if (data.sharingId) {
        await getV2Report(data.sharingId);
      }
    }
  }

  async function getV2Report(id) {
    try {
      const { data, status } = await v2.get(`/domoreports/${id}`);
      if (status === 200) {
        setDomoreport(data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        window.location = "/login";
      }
      console.error(error);
    }
  }

  function scrollToAnchor() {
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
      setAnchor(undefined);
    }
  }

  if (!Object.keys(domoreport).length) {
    return <LoadingSpinner />;
  }

  return (
    <div className="request-list-print-options row">
      {showDisplayOptionsModal && (
        <DisplayOptionsInfoModal
          hideModal={() => setShowDisplayOptionsModal(false)}
        />
      )}

      {showSharingModal && (
        <RequestListSharingModal
          report={domoreport}
          hideModal={() => setShowSharingModal(false)}
          reportID={domoreport.sharingId}
        />
      )}
      <div className="request-list-header d-flex d-lg-none">
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setShowSidebar(!showSidebar);
          }}
        >
          <i className="fa fa-bars" />
        </a>

        <div className="md-logo-wrapper">
          <img src="/assets/logo/logo.svg" width="100%" alt="" />
        </div>
      </div>

      <RequestListSidebar
        report={domoreport}
        showSidebar={showSidebar}
        preparedFor={preparedFor}
        contractorSort={contractorSort}
        show={show}
        setShow={setShow}
        repairSort={repairSort}
        setRepairSort={setRepairSort}
        setShowSharingModal={setShowSharingModal}
        setShowDisplayOptionsModal={setShowDisplayOptionsModal}
        setPreparedFor={setPreparedFor}
        setContractorSort={setContractorSort}
      />

      <div
        className="printout-wrapper col-12 col-lg-8"
        id="printoutWrapper"
        onClick={() => {
          setShowSidebar(false);
        }}
      >
        <RequestListPrintout
          report={domoreport}
          contractorSort={contractorSort}
          repairSort={repairSort}
          preparedFor={preparedFor}
          setScrollAnchor={setAnchor}
          show={show}
        />

        {!!anchor ? (
          <div className="scroll-back-button" onClick={scrollToAnchor}>
            <i className="fa fa-caret-up" />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}
