import "./index.scss";
import React, { Component } from "react";
import withFetch from "../../hocs/withFetch";
import { parseQueryString } from "../../services/parseQueryString";
import { withRouter } from "react-router-dom";

@withRouter
@withFetch((props) => ({
  getRepair: (reportID, repairID) => ({
    repairData: {
      force: true,
      refresh: true,
      url: `/domoreport/${reportID}/repair/${repairID}`,
    },
  }),
}))
export default class RepairTable extends Component {
  componentDidMount() {
    const reportID = parseQueryString(window.location.search).report;
    const repairID = parseQueryString(window.location.search).repair;

    if ((reportID && repairID) || !localStorage.getItem("majordomo-token")) {
      this.props.getRepair(reportID, repairID);
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    const { repairData } = this.props;
    let repair = {};
    let costs = {};
    let label = "";

    if (
      !repairData ||
      (repairData && (repairData.pending || !repairData.value))
    ) {
      return <div></div>;
    }

    if (repairData && !repairData.pending && repairData.value) {
      repair = repairData.value;
      costs = repair.costs;

      label = repairData.value.priority;

      // for (
      //   var i = repair.inspection_page_num_start;
      //   i <= repair.inspection_page_num_end;
      //   i++
      // ) {
      //   pages.push(<Page key={i} pageNumber={i} />);
      // }
    }

    return (
      repairData && (
        <div className="md-printable-repair">
          <div className="print-break">
            <div>
              <h4 className="md-printable-title">
                {repair.project_name} |{" "}
                <a href="/">
                  <img
                    src="/assets/img/thumbtack/wordmark_dark.png"
                    width="150px"
                    alt="thumbtack logo"
                  />
                </a>
              </h4>
            </div>

            <div className="md-printable-details">
              <div className="md-printable-subheader-wrapper">
                <h4
                  className="md-printable-subheader"
                  dangerouslySetInnerHTML={{
                    __html: "Problem: " + repair.feature_problem,
                  }}
                ></h4>
                <span className={`md-priority-tag md-${repair.priority}`}>
                  {label}
                </span>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: repair.inspection_problem_comment,
                }}
              ></p>

              <hr />

              <h4 className="md-printable-subheader">Project Details</h4>
              {repair.job_details && (
                <p dangerouslySetInnerHTML={{ __html: repair.job_details }}></p>
              )}

              <hr />
            </div>

            <table className="md-printable-table">
              <tbody>
                <tr className="table-heading">
                  <td colSpan="3">Estimated Project Cost</td>
                  {costs.total.low ? (
                    <td colSpan="3" className="text-right">
                      ${costs.total.low} - {costs.total.high}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="md-table-light">
                  <td colSpan="3">Materials</td>
                  {costs.material.low ? (
                    <td colSpan="3" className="text-right">
                      ${costs.material.low} - {costs.material.high}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="md-table-dark">
                  <td colSpan="3">Labor ({repair.contractor_type})</td>
                  {costs.labor.low ? (
                    <td colSpan="3" className="text-right">
                      ${costs.labor.low} - {costs.labor.high}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="md-table-light">
                  <td colSpan="3">Equipment</td>
                  {costs.equiptment.low ? (
                    <td colSpan="3" className="text-right">
                      ${costs.equiptment.low} - {costs.equiptment.high}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="md-table-dark">
                  <td colSpan="3">Minimum Trip Charge</td>
                  {costs.contactor_fees.low ? (
                    <td colSpan="3" className="text-right">
                      ${costs.contactor_fees.low} - {costs.contactor_fees.high}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr>
                  <td colSpan="3">
                    <b>Quantity</b>
                  </td>
                  <td colSpan="3" className="text-right">
                    {costs.quantityString ? costs.quantityString : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <b>Material Quality Level</b>
                  </td>
                  <td colSpan="3" className="text-right">
                    {costs.material_quality_level
                      ? costs.material_quality_level
                      : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="md-disclaimer col-12">
              <p>
                Disclaimer: Thumbtack is not a home inspection company and is
                not responsible for the contents of your home inspection report.
                Prices listed in your Home Repair Estimate are estimates and
                actual prices will vary depending on many factors.
                Prioritizations in your Home Repair Estimate are based upon
                either the original inspection report results, or Thumbtack’s
                judgment made without expertise and without an opportunity to
                view the property. Prioritizations are solely an opinion that
                should not be relied upon without consulting a service
                professional with a specialization in the issue at hand.
                Inspector Notes are based solely on a review of your home
                inspection report and should not be relied upon without
                consulting a service professional. Always consult a professional
                to determine next steps. Your use of the Home Repair Estimate
                constitutes your agreement with Thumbtack’s{" "}
                <a
                  href="https://www.thumbtack.com/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://www.thumbtack.com/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>

          {/* <Document
            file={{
              url: `${baseUrl}/api/1/domoreport/inspectionFile/${reportID}`,
              httpHeaders: {
                Authorization:
                  localStorage.getItem("majordomo-token") ||
                  localStorage.getItem("majordomo-temp-token"),
              },
            }}
            className={
              showInspection === "1" ? "inspection-page show" : "d-none"
            }
            onLoadSuccess={this.onDocumentLoadSuccess}
            loading={<LoadingSpinner />}
          >
            {pages}
          </Document> */}
        </div>
      )
    );
  }
}
