import React from "react";
import {
  ActionIcon,
  Group,
  Modal,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";

export default function ThemeModal({
  showModal,
  onClose,
  size,
  children,
  title,
}) {
  const theme = useMantineTheme();

  return (
    <Modal
      centered
      size={size || "md"}
      opened={showModal}
      onClose={onClose}
      withCloseButton={false}
      withinPortal
      padding={0}
      zIndex={1000}
      overlayOpacity={0.2}
      overlayBlur={1}
    >
      <Stack spacing={0} style={{ position: "relative" }}>
        {title ? (
          <Group
            style={{
              justifyContent: "space-between",
              background: theme.colors.gray[0],
            }}
            p="sm"
          >
            <Title order={2}>{title}</Title>
            <ActionIcon variant="transparent" onClick={onClose}>
              <Text color="black">
                <i
                  className="fas fa-times"
                  style={{ color: "#000000 !important" }}
                />
              </Text>
            </ActionIcon>
          </Group>
        ) : (
          <ActionIcon
            size="md"
            variant="transparent"
            onClick={onClose}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 10,
            }}
          >
            <i className="fas fa-times fa-lg" />
          </ActionIcon>
        )}

        {children}
      </Stack>
    </Modal>
  );
}
