import "./index.scss";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import AppNavigation from "../../components/App-Navigation";
import Domoreport from "../../components/Domoreport/domoreport";
import { parseQueryString } from "../../services/parseQueryString";
import HomeInspection from "../../components/Home-Inspection";
import Documents from "../../components/Documents";
import Settings from "../../components/Settings";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import withFetch from "../../hocs/withFetch";
import { Modal, Alert } from "react-bootstrap";
import LoadingSpinner from "../../components/Loading-Spinner";
import Explore from "../../components/Explore";
import PendingOrder from "../../components/Pending-Order";
import decode from "jwt-decode";
import SEO from "../../components/SEO";
import analytics, { identifyUser } from "../../services/analytics";
import Axios from "axios";
import ThemeModal from "../../ui/Modal";
import {
  Anchor,
  Button,
  Group,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";

@withRouter
@withFetch((props) => ({
  userData: {
    url: `/users`,
    then: ({ user_unique_id: id, ...info }) => {
      identifyUser(id, info);
    },
  },
  getUserReportsData: () => ({
    userReportsData: {
      url: `/domoreport/userReports`,
      then: (res) => {
        const query = parseQueryString(window.location.search);

        let is_public = query.public || 0;
        if (
          res === false ||
          (!is_public && !localStorage.getItem("majordomo-token"))
        ) {
          let privToken = query.newUserWithViewPrivToken;
          if (privToken) {
            window.location = `/signup?newUserWithViewPrivToken=${privToken}`;
          } else {
            window.location = `/login`;
          }
        } else {
          let filter = query.filter || null;
          let priority = query.priority || null;
          let reportID =
            query.report || localStorage.getItem("current-report") || "";

          /* 
            Get all published and unpublished 
            domoreports associated with the current user.
          */
          let userReports = [];
          if (is_public) {
            res.public.map((report) => {
              userReports.push(report);
            });
          } else {
            res.reports.map((report) => {
              userReports.push(report);
            });
            res.unpublished.map((report) => {
              userReports.push(report);
            });
          }

          //Sort Domoreports by domoreport_log_id
          const compare = (a, b) => {
            let sortA = a.domoreport_log_id;
            let sortB = b.domoreport_log_id;
            let comparison = 0;

            if (sortA < sortB) {
              comparison = 1;
            } else if (sortA > sortB) {
              comparison = -1;
            }
            return comparison;
          };
          userReports.sort(compare);

          /* 
            Determine the current report based on 
            query param or localStorage. If none,
            leave as empty array. 
          */
          let report = userReports.filter(
            (domoreport) => domoreport.domoreport_log_id === parseInt(reportID)
          );

          // if (report.length <= 0 && propertyID) {
          //   report = userReports.filter((domoreport) => {
          //     return domoreport.property_log_id == propertyID;
          //   });
          // }

          /* 
            Set all reports to show notes
            the first time they are visited. 
          */
          let reportsNotes =
            JSON.parse(localStorage.getItem("reports-notes")) || [];
          if (reportsNotes.length <= 0) {
            userReports.map((report) => {
              reportsNotes.push({
                report_id: report.domoreport_log_id,
                show_notes: 1,
              });
            });
          }
          localStorage.setItem("reports-notes", JSON.stringify(reportsNotes));

          /* 
            Manage which report shows based on user privileges, 
            query param, and current-report in localStorage. 
          */
          if (props.user == null || props.user.user_account_type !== "ADMIN") {
            if (userReports.length) {
              //Non-Admin User has reports.
              if (report.length) {
                //ID specified in either query param or localStorage and it matches ID of a user report.
                if (report[0].permissions !== "PUBLIC") {
                  localStorage.setItem(
                    "current-report",
                    report[0].domoreport_log_id
                  );
                  localStorage.setItem(
                    "address",
                    report[0].address +
                      ", " +
                      report[0].city +
                      ", " +
                      report[0].state
                  );
                }

                props.history.push(
                  `?${is_public ? "public=1&" : ""}report=${report[0]
                    .domoreport_log_id +
                    (priority ? "&priority=" + priority : "") +
                    (filter ? "&filter=" + filter : "")}`
                );
              } else if (!report.length) {
                //ID not specified in either query param or localStorage
                if (userReports[0].permissions !== "PUBLIC") {
                  localStorage.setItem(
                    "current-report",
                    userReports[0].domoreport_log_id
                  );
                  localStorage.setItem(
                    "address",
                    userReports[0].address +
                      ", " +
                      userReports[0].city +
                      ", " +
                      userReports[0].state
                  );
                }

                props.history.push(
                  `?${is_public ? "public=1&" : ""}report=${
                    userReports[0].domoreport_log_id
                  }`
                );
              }
            } else {
              localStorage.setItem("current-report", null);
            }
          } else {
            if (report.length) {
              //Admin User has report. Either ID in query param or localStorarge matches ID of a report Admin User owns
              if (report[0].permissions !== "PUBLIC") {
                localStorage.setItem(
                  "current-report",
                  report[0].domoreport_log_id
                );
                localStorage.setItem(
                  "address",
                  report[0].address +
                    ", " +
                    report[0].city +
                    ", " +
                    report[0].state
                );
              }

              props.history.push(
                `?${is_public ? "public=1&" : ""}report=${report[0]
                  .domoreport_log_id +
                  (priority ? "&priority=" + priority : "") +
                  (filter ? "&filter=" + filter : "")}`
              );
            } else if (!report.length) {
              //Neither ID in query param nor localStorage match a report Admin User owns, but admins can view reports they don't own.
              if (reportID && isFinite(String(reportID)) && !is_public) {
                localStorage.setItem("current-report", reportID);
                localStorage.setItem(
                  "address",
                  "This is a preview of domoreport " + reportID
                );

                props.history.push(
                  `?report=${reportID +
                    (priority ? "&priority=" + priority : "")}`
                );
              } else if (userReports.length) {
                if (userReports[0].permissions !== "PUBLIC") {
                  localStorage.setItem(
                    "current-report",
                    userReports[0].domoreport_log_id
                  );
                  localStorage.setItem(
                    "address",
                    userReports[0].address +
                      ", " +
                      userReports[0].city +
                      ", " +
                      userReports[0].state
                  );

                  props.history.push(
                    `?report=${userReports[0].domoreport_log_id +
                      (priority ? "&priority=" + priority : "")}`
                  );
                }
              }
            }
          }
        }
      },
    },
  }),
  sendContactInformation: (data) => ({
    contactSent: {
      method: "POST",
      url: "/users/sendContact",
      body: JSON.stringify(data),
      force: true,
      refresh: true,
    },
  }),
  adminGetReport: (reportID) => ({
    adminReportData: {
      url: `/domoreport/${reportID}`,
      then: (res) => {
        if (res.property_log_id) {
          localStorage.setItem(
            "address",
            res.address + ", " + res.city + ", " + res.state
          );
          localStorage.setItem("current-property", res.property_log_id);
        }
      },
      catch: (error) => {
        props.history.goBack();
      },
    },
  }),
  archiveDomoreport: (reportID, body) => ({
    archivedDomoreport: {
      body: JSON.stringify(body),
      force: true,
      refresh: true,
      method: "PUT",
      url: `/domoreport/${reportID}/archive`,
    },
  }),
}))
export default class MainLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allProperties: null,
      center: { lat: null, lng: null },
      domoreports: null,
      globalMessage: null,
      message: "",
      myRepairs: [],
      service_url: "",
      showBanner: true,
      showMessage: false,
      showModal: false,
      showNotes: false,
      showSidebar: false,
      // showSuggestModal: false,
      user: {},
      user_name: "",
      user_email: "",
      v2Report: undefined,
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  componentDidMount() {
    this.props.getUserReportsData();
    //Check if user is logged in. If not kick them out to login page.
    if (localStorage.getItem("majordomo-user-data")) {
      const userData = JSON.parse(localStorage.getItem("majordomo-user-data"));
      const user = {
        user_id: userData.user_unique_id,
        email: userData.email,
        name: `${userData.user_first_name} ${userData.user_last_name}`,
      };

      // Identify user across all analytics destinations
      analytics.identify(user.user_unique_id, {
        userId: user.user_id,
        traits: {
          name: user.name,
          email: user.email,
        },
      });

      this.setState({ user });
    }
  }

  componentWillUpdate() {
    let expDate = localStorage.getItem("majordomo-token")
      ? new Date(decode(localStorage.getItem("majordomo-token")).exp * 1000)
      : null;

    if (
      expDate != null &&
      expDate < new Date() &&
      window.location.pathname !== "/login"
    ) {
      localStorage.removeItem("majordomo-token");
      localStorage.removeItem("majordomo-temp-token");
      localStorage.removeItem("majordomo-user");
      window.location = "/login";
    }
  }

  componentDidUpdate() {
    const { allProperties, domoreports } = this.state;
    const { userReportsData } = this.props;

    if (!localStorage.getItem("cookies-accepted")) {
      localStorage.setItem("cookies-accepted", true);
    }

    // Sort all domoreports by domoreport_log_id
    const compare = (a, b) => {
      let sortA = a.domoreport_log_id;
      let sortB = b.domoreport_log_id;
      let comparison = 0;

      if (a.domoreport_log_id) {
        sortA = a.domoreport_log_id;
        sortB = b.domoreport_log_id;
      } else if (a.property_log_id) {
        sortA = a.property_log_id;
        sortB = b.property_log_id;
      }

      if (sortA < sortB) {
        comparison = 1;
      } else if (sortA > sortB) {
        comparison = -1;
      }
      return comparison;
    };

    if (
      userReportsData &&
      !userReportsData.pending &&
      userReportsData.value &&
      !allProperties &&
      !domoreports
    ) {
      const { archived, reports, unpublished } = userReportsData.value;
      const is_public = parseQueryString(window.location.search).public || 0;

      let domoreports = [...reports, ...unpublished];
      let allProperties = [...reports, ...unpublished, ...archived];

      if (is_public) {
        domoreports = [];
        allProperties = [...userReportsData.value.public];
      }

      allProperties.sort(compare);
      domoreports.sort(compare);

      this.setState({
        allProperties: allProperties,
        domoreports: domoreports,
      });
    }
  }

  handleArchive = (reportID, archive) => {
    const { allProperties, domoreports } = this.state;
    const id = parseInt(reportID);
    const reports = [...domoreports];
    const all = [...allProperties];

    const compare = (a, b) => {
      let sortA = a.domoreport_log_id;
      let sortB = b.domoreport_log_id;
      let comparison = 0;
      if (sortA < sortB) {
        comparison = 1;
      } else if (sortA > sortB) {
        comparison = -1;
      }
      return comparison;
    };

    if (archive) {
      let removeIndex = null;
      const reportData = reports.filter((report, index) => {
        if (report.domoreport_log_id === id) {
          removeIndex = index;
          return report;
        }
      });

      if (reportData[0]) {
        reportData[0].is_archived = true;
        all.push(reportData[0]);
        reports.splice(removeIndex, 1);
      }
    } else {
      let removeIndex = null;
      const reportData = all.filter((report, index) => {
        if (report.domoreport_log_id === id) {
          removeIndex = index;
          return report;
        }
      });

      if (reportData[0]) {
        reportData[0].is_archived = false;
        reports.push(reportData[0]);
        all.splice(removeIndex, 1);
      }
    }

    reports.sort(compare);
    all.sort(compare);

    this.setState({ domoreports: reports });
    this.props.archiveDomoreport(id, { archive });
  };

  setCenter = (lat, lng) => {
    this.setState({ center: { lat: lat, lng: lng } });
  };

  updateRepairs = (key, value) => {
    this.setState({ [key]: value });
  };

  saveContactInfo = (key, value) => {
    this.setState({ [key]: value });
  };

  toggleModal = (e) => {
    this.setState({
      showModal: !this.state.showModal,
      user_name: "",
      user_email: "",
      message: "",
      showMessage: false,
      globalMessage: null,
    });
  };

  // toggleSuggestModal = (e) => {
  //   this.setState({
  //     showSuggestModal: !this.state.showSuggestModal,
  //     user_name: "",
  //     user_email: "",
  //     message: "",
  //     showMessage: false,
  //     globalMessage: null,
  //   });
  // };

  toggleSidebar = (e) => {
    e.preventDefault();
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  hideSidebar = (e) => {
    if (this.state.showSidebar) {
      this.setState({
        showSidebar: false,
      });
      window.removeEventListener("resize", this.hideSidebar);
      document
        .getElementById("main")
        .removeEventListener("mousedown", this.hideSidebar);
    }
  };

  showSidebar = (e) => {
    this.setState({
      showSidbar: true,
    });
  };

  toggleNotes = (e) => {
    e.preventDefault();
    if (localStorage.getItem("show-default") == 1) {
      localStorage.setItem("show-default", 0);
      this.setState({ showNotes: false });
    } else {
      this.setState({ showNotes: !this.state.showNotes });
    }
  };

  showNotes = (e) => {
    if (!this.state.showNotes) {
      this.setState({ showNotes: true });
    }
  };

  hideNotes = (e) => {
    if (this.state.showNotes) {
      this.setState({ showNotes: false });
    }
  };

  // Handle Contact Us form.
  onSubmit = (e) => {
    e.preventDefault();
    const { sendContactInformation, user } = this.props;
    const { user_name, user_email, message, service_url } = this.state;

    if ((user_email || user.user_email) && message && !service_url) {
      let leadData = {
        email: user_email || user.user_email,
        name: user_name || user.user_first_name + " " + user.user_last_name,
        message: message,
      };
      sendContactInformation(leadData);

      this.setState({
        showMessage: true,
      });
    } else if (user.user_email && message && service_url) {
      let leadData = {
        email: user.user_email,
        name: user.user_first_name + " " + user.user_last_name,
        message: "Service URL " + service_url + " " + message,
      };
      sendContactInformation(leadData);

      this.setState({
        showMessage: true,
      });
    } else {
      this.setState({
        globalMessage: "Please fill out all required fields",
        showMessage: true,
      });
    }
  };

  logout = async (e) => {
    e.preventDefault();

    await Axios.get("/api/users/logout");
    localStorage.removeItem("majordomo-temp-token");
    localStorage.removeItem("majordomo-token");
    localStorage.removeItem("majordomo-user");
    localStorage.removeItem("current-property");
    localStorage.removeItem("wizard-property");
    window.location = "/";
  };

  closeMessage = () => {
    this.setState({ showMessage: false, globalMessage: null });
  };

  hideCookiesBanner = (e) => {
    e.preventDefault();
    localStorage.setItem("cookies-accepted", true);
    this.setState({ showBanner: false });
  };

  render() {
    const { allProperties } = this.state;
    const { adminReportData, userData, userReportsData } = this.props;

    const query = parseQueryString(window.location.search);
    const reportID = query.report || localStorage.getItem("current-report");
    const propertyID = localStorage.getItem("current-property");

    const token = localStorage.getItem("majordomo-token");
    const tempToken = localStorage.getItem("majordomo-temp-token");

    const reportsLoaded =
      userReportsData && !userReportsData.pending && userReportsData.value;
    const userLoaded =
      (userData && !userData.pending && userData.value) ||
      localStorage.getItem("majordomo-temp-token");

    let user =
      userData && !userData.pending && userData.value != null
        ? userData.value
        : {};

    // Get the domoreport with the report ID from URL.
    let domoreport = [];
    if (reportID && allProperties && allProperties.length) {
      domoreport = allProperties.filter((report) => {
        return report.domoreport_log_id === parseInt(reportID);
      });
    }

    /*  Generate "dummy" data for admins to view  before the report is published.*/
    if (
      domoreport.length <= 0 &&
      userData &&
      !userData.pending &&
      userData.value &&
      userData.value.user_account_type === "ADMIN"
    ) {
      this.props.adminGetReport(reportID);

      if (
        adminReportData &&
        !adminReportData.pending &&
        adminReportData.value
      ) {
        domoreport[0] = {
          address: adminReportData.value.address || "This is a preview",
          city:
            adminReportData.value.city ||
            (reportID !== "null" ? "of Home Repair Estimate " + reportID : ""),
          domo_address_id: adminReportData.value.domo_address_id,
          domoreport_log_id: reportID,
          domoreport_published: 1,
          permissions: "OWNER",
          property_log_id: adminReportData.value.property_log_id,
          state: adminReportData.value.state || "",
          zip: adminReportData.value.zip || "",
        };
      }
    } else if (domoreport.length <= 0 && tempToken && !token) {
      domoreport[0] = {
        domoreport_log_id: reportID,
        domoreport_published: 1,
      };
    }

    if (
      allProperties &&
      allProperties.length &&
      domoreport.length <= 0 &&
      propertyID &&
      propertyID !== "null"
    ) {
      domoreport = allProperties.filter((report) => {
        return report.property_log_id === parseInt(propertyID);
      });
    }

    return reportsLoaded &&
      userLoaded &&
      (adminReportData ? !adminReportData.pending : true) ? (
      this._renderAvailable(domoreport[0], user)
    ) : (
      <LoadingSpinner />
    );
  }

  _renderAvailable = (domoreport, user) => {
    const {
      allProperties,
      domoreports,
      showBanner,
      showSidebar,
      // showSuggestModal,
    } = this.state;
    const { contactSent } = this.props;

    const { pathname, search } = window.location;
    const query = parseQueryString(search);
    const report = query.report;
    const is_public = query.public || 0;

    const token = localStorage.getItem("majordomo-token");
    let expDate = token ? new Date(decode(token).exp * 1000) : null;

    let address = "";
    if (domoreport) {
      address =
        domoreport.address +
        ", " +
        domoreport.city +
        ", " +
        domoreport.state +
        " " +
        domoreport.zip;
    }

    return (
      <div id="md-main-layout bg-uiblue100">
        <div className="main-layout-container container">
          <div className="d-block d-lg-none menu-link-container">
            {pathname !== "/home-inspection" && (
              <Link
                to="#"
                className="d-inline d-lg-none menu-link"
                onClick={this.toggleSidebar}
              >
                <i className="fa fa-bars fa-2x" />
              </Link>
            )}
          </div>

          <ThemeModal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            showModal={this.state.showModal}
            onClose={() => this.toggleModal()}
            title="Contact Us"
          >
            {/* Render Success/Error Messages */}
            {/* {contactSent &&
              !contactSent.pending &&
              contactSent.value.success &&
              this.state.showMessage &&
              this._renderAlert(
                "success",
                "Thank you for contacting Majordomo. We will respond within 24 hours"
              )}
            {contactSent &&
              !contactSent.pending &&
              contactSent.reason &&
              this.state.showMessage &&
              this._renderAlert("danger", contactSent.reason.message)}
            {!contactSent &&
              this.state.showMessage &&
              this._renderAlert("danger", this.state.globalMessage)}

            <form onSubmit={this.handleSubmit}>
              <Stack p="lg">
                <TextInput
                  label="Name"
                  name="user_name"
                  id="user_name"
                  onChange={(evt) => {
                    this.saveContactInfo("user_name", evt.target.value);
                  }}
                  defaultValue={
                    user.user_first_name
                      ? user.user_first_name + " " + user.user_last_name
                      : ""
                  }
                  required
                />

                <TextInput
                  label="Email"
                  name="user_email"
                  id="user_email"
                  onChange={(evt) => {
                    this.saveContactInfo("user_email", evt.target.value);
                  }}
                  defaultValue={user.user_email || ""}
                  required
                />

                <Textarea
                  label="Message"
                  id="message"
                  name="message"
                  rows="4"
                  onChange={(evt) => {
                    this.saveContactInfo("message", evt.target.value);
                  }}
                  value={this.state.message}
                  required
                /> */}

            <Stack p="lg">
              <Text>
                Have questions? Reach out to{" "}
                <Anchor href="mailto:support@thumbtack.com">
                  support@thumbtack.com
                </Anchor>
                .
              </Text>

              <Text>
                Want to book time with a home specialist to answer questions
                live?{" "}
                <Anchor
                  href="https://aceja.youcanbook.me/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </Anchor>
                .
              </Text>
            </Stack>

            {/* <Group style={{ justifyContent: "flex-end" }}>
                  <Button color="blue.4" onClick={this.onSubmit}>
                    Send Message
                  </Button>
                </Group>
              </Stack>
            </form> */}
          </ThemeModal>

          {/* {showSuggestModal && this._openSuggestModal()} */}

          <AppNavigation
            allProperties={allProperties}
            domoreport={domoreport ? domoreport : null}
            domoreports={domoreports}
            hideNotes={this.hideNotes}
            logout={this.logout}
            reportID={domoreport ? domoreport.domoreport_log_id : null}
            setCenter={this.setCenter}
            showSidebar={showSidebar}
            toggleModal={this.toggleModal}
          />

          <div
            className="ml-sm-auto col-12 col-lg-9 bg-uiblue100"
            id="main"
            style={{ padding: 0 }}
            onClick={this.hideSidebar}
          >
            {/* <div
              className={
                showBanner && !localStorage.getItem("cookies-accepted")
                  ? "row md-cookies-banner"
                  : "d-none"
              }
            >
              <Link
                to="#"
                className="md-close-button"
                onClick={(e) => {
                  this.hideCookiesBanner(e);
                }}
              >
                X
              </Link>
              <p>
                This site uses cookies to provide you with a great user
                experience. By using Majordomo, you accept our{" "}
                <Link to="/terms" target="_blank" rel="noopener noreferrer">
                  Terms of Use
                </Link>
                and{" "}
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
                .
              </p>
            </div> */}

            <Switch>
              <Route
                exact
                path="/repair-estimate"
                render={() => (
                  <>
                    <SEO
                      title="Home Repair Estimate"
                      url="https://thumbtack.com/repair-estimate"
                      description="Need home inspection repair costs? Upload any home inspection report. Get accurate local estimates for repairs in 24 hours. Negotiate like a pro!"
                    />
                    {domoreport &&
                    domoreport.domoreport_log_id != "null" &&
                    (domoreport.domoreport_published ||
                      (Object.keys(user).length > 0 &&
                        user.user_account_type === "ADMIN")) ? (
                      <Domoreport
                        showNotes={this.state.showNotes}
                        accountType={
                          Object.keys(user).length > 0
                            ? user.user_account_type
                            : null
                        }
                        center={this.state.center}
                        domoreport={domoreport}
                        reportID={domoreport.domoreport_log_id}
                        permission={domoreport.permissions}
                        expDate={expDate}
                        setCenter={this.setCenter}
                        toggleModal={this.toggleModal}
                        toggleNotes={this.toggleNotes}
                      />
                    ) : (
                      <PendingOrder
                        domoreport={domoreport ? domoreport : null}
                        permission={domoreport ? domoreport.permission : null}
                        reportID={
                          domoreport ? domoreport.domoreport_log_id : report
                        }
                        title="Home Repair Estimate"
                      />
                    )}
                  </>
                )}
              />

              <Route
                exact
                path="/repair-estimate/home-inspection"
                render={() => (
                  <>
                    <SEO
                      title="Home Inspection"
                      url="https://thumbtack.com/repair-estimate/home-inspection"
                      description="Secure and lifelong storage for your home inspection is included with the Home Repair Estimate."
                    />
                    {allProperties &&
                    allProperties.length &&
                    domoreport &&
                    domoreport.domoreport_log_id ? (
                      <HomeInspection
                        reportID={domoreport.domoreport_log_id}
                        toggleSidebar={this.toggleSidebar}
                      />
                    ) : (
                      <PendingOrder title="Home Inspection" />
                    )}
                  </>
                )}
              />

              <Route
                exact
                path="/repair-estimate/documents"
                render={() => (
                  <>
                    <SEO
                      title="Documents"
                      url="https://thumbtack.com/repair-estimate/documents"
                      description="Secure and lifelong storage is included for your home inspection, repair and maintenance documents with your Home Repair Estimate. One time charge & access for life."
                    />
                    {allProperties &&
                    allProperties.length &&
                    domoreport &&
                    domoreport.domoreport_log_id ? (
                      <Documents
                        reportID={domoreport.domoreport_log_id}
                        isPublic={is_public}
                        toggleSidebar={this.toggleSidebar}
                        permission={domoreport.permissions}
                      />
                    ) : (
                      <PendingOrder title="Documents" />
                    )}
                  </>
                )}
              />

              {/* <Route
                exact
                path="/explore"
                render={() => (
                  <>
                    <SEO
                      title="Local Resources"
                      url="https://thumbtack.com/explore"
                      description="When buying a home there’s a lot to investigate beyond the condition. Order a Home Repair Estimate & we include many national, state, county, and city resources."
                    />
                    {domoreport ? (
                      <Explore
                        reportId={domoreport.domoreport_log_id}
                        // toggleSidebar={this.toggleSidebar}
                        // toggleSuggestModal={this.toggleSuggestModal}
                      />
                    ) : (
                      <PendingOrder title="Local Resources" />
                    )}
                  </>
                )}
              /> */}

              <Route
                exact
                path="/repair-estimate/settings"
                render={() => (
                  <>
                    <SEO
                      title="Settings"
                      url="https://thumbtack.com/repair-estimate/settings"
                    />
                    {domoreport ? (
                      <Settings
                        user={user}
                        address={address}
                        expDate={expDate}
                        handleArchive={this.handleArchive}
                        permission={domoreport.permissions}
                        reportID={domoreport.domoreport_log_id}
                        toggleSidebar={this.toggleSidebar}
                      />
                    ) : (
                      <Settings
                        user={user}
                        toggleSidebar={this.toggleSidebar}
                      />
                    )}
                  </>
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  };

  // _openContactModal = () => {
  //   const { contactSent } = this.props;
  //   const { user } = this.props;

  //   return (
  //     <ThemeModal
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       opened={this.state.showModal}
  //       onClose={() => this.toggleModal()}
  //       // className="md-contact-modal"
  //       title="Contact Us"
  //     >
  //       <div className="contact-form-container col-12">
  //         {/* Render Success/Error Messages */}
  //         {contactSent &&
  //           !contactSent.pending &&
  //           contactSent.value.success &&
  //           this.state.showMessage &&
  //           this._renderAlert(
  //             "success",
  //             "Thank you for contacting Majordomo. We will respond within 24 hours"
  //           )}
  //         {contactSent &&
  //           !contactSent.pending &&
  //           contactSent.reason &&
  //           this.state.showMessage &&
  //           this._renderAlert("danger", contactSent.reason.message)}
  //         {!contactSent &&
  //           this.state.showMessage &&
  //           this._renderAlert("danger", this.state.globalMessage)}

  //         <form onSubmit={this.handleSubmit}>
  //           <label htmlFor="name">NAME</label>
  //           <input
  //             id="user_name"
  //             name="user_name"
  //             type="text"
  //             onChange={(evt) => {
  //               this.saveContactInfo("user_name", evt.target.value);
  //             }}
  //             defaultValue={
  //               user.user_first_name
  //                 ? user.user_first_name + " " + user.user_last_name
  //                 : ""
  //             }
  //           />

  //           <label htmlFor="email">EMAIL</label>
  //           <input
  //             id="user_email"
  //             name="user_email"
  //             type="text"
  //             onChange={(evt) => {
  //               this.saveContactInfo("user_email", evt.target.value);
  //             }}
  //             defaultValue={user.user_email || ""}
  //           />

  //           <label htmlFor="message">Message</label>
  //           <textarea
  //             id="message"
  //             name="message"
  //             rows="4"
  //             onChange={(evt) => {
  //               this.saveContactInfo("message", evt.target.value);
  //             }}
  //             value={this.state.message}
  //           />

  //           <button
  //             className="btn btn-primary btn-top-right-cut"
  //             onClick={this.onSubmit}
  //           >
  //             Send Message
  //           </button>
  //         </form>
  //       </div>
  //     </ThemeModal>
  //   );
  // };

  // _openSuggestModal = () => {
  //   const { contactSent } = this.props;
  //   const { user } = this.props;

  //   return (
  //     <Modal
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //       show={true}
  //       onHide={this.toggleSuggestModal}
  //       className="md-contact-modal"
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter">
  //           <b>Suggest A New Service</b>
  //         </Modal.Title>
  //       </Modal.Header>

  //       <Modal.Body>
  //         <div className="contact-form-container col-12">
  //           {/* Render Success/Error Messages */}
  //           {contactSent &&
  //             !contactSent.pending &&
  //             contactSent.value.success &&
  //             this.state.showMessage &&
  //             this._renderAlert(
  //               "success",
  //               "Thank you for contacting Majordomo."
  //             )}
  //           {contactSent &&
  //             !contactSent.pending &&
  //             contactSent.reason &&
  //             this.state.showMessage &&
  //             this._renderAlert("danger", contactSent.reason.message)}
  //           {!contactSent &&
  //             this.state.showMessage &&
  //             this._renderAlert("danger", this.state.globalMessage)}

  //           <form onSubmit={this.handleSubmit}>
  //             <input
  //               id="user_name"
  //               name="user_name"
  //               type="hidden"
  //               onChange={(evt) => {
  //                 this.saveContactInfo("user_name", evt.target.value);
  //               }}
  //               value={
  //                 user.user_first_name
  //                   ? user.user_first_name + " " + user.user_last_name
  //                   : this.state.user_name
  //               }
  //             />

  //             <input
  //               id="user_email"
  //               name="user_email"
  //               type="hidden"
  //               onChange={(evt) => {
  //                 this.saveContactInfo("user_email", evt.target.value);
  //               }}
  //               value={user.user_email || this.state.user_email}
  //             />

  //             <label htmlFor="name">Service URL</label>
  //             <input
  //               id="service_url"
  //               name="service_url"
  //               type="text"
  //               onChange={(evt) => {
  //                 this.saveContactInfo("service_url", evt.target.value);
  //               }}
  //               vale={this.state.service_url}
  //             />

  //             <label htmlFor="message">
  //               Why do you like this service (optional)
  //             </label>
  //             <textarea
  //               id="message"
  //               name="message"
  //               rows="4"
  //               onChange={(evt) => {
  //                 this.saveContactInfo("message", evt.target.value);
  //               }}
  //               value={this.state.message}
  //             />

  //             <button
  //               className="btn btn-primary btn-top-right-cut"
  //               onClick={this.onSubmit}
  //             >
  //               Send Message
  //             </button>
  //           </form>
  //         </div>
  //       </Modal.Body>
  //     </Modal>
  //   );
  // };

  _renderAlert(variant, message) {
    return (
      <Alert variant={variant} onClose={this.closeMessage} dismissible>
        <p>{message}</p>
      </Alert>
    );
  }
}
