import "./index.scss";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import _ from "lodash";
import {
  formatCost,
  getPdfPages,
  getRequestCost,
  getRequestMemo,
} from "../../services/helpers";
import moment from "moment";
import striptags from "striptags";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function RequestListPrintout({
  contractorSort,
  report,
  preparedFor,
  setScrollAnchor,
  show,
  repairSort,
}) {
  const [inspectionPages, setInspectionPages] = useState([]);
  const [quoteFiles, setQuoteFiles] = useState([]);
  const [quotePages, setQuotePages] = useState({});
  const [requestListMemo, setRequestListMemo] = useState(null);
  const [url, setUrl] = useState(null);
  const requestListKeys = ["Repair", "Replace", "Credit", "As-Is"];
  const { address, city, state, zip } = report.property;
  const fullAddress = `${address}, ${city}, ${state} ${zip}`;

  const displayOptions = {
    Repair: show.repairRequests,
    Replace: show.replaceRequests,
    Credit: show.creditRequests,
    "As-Is": show.asIsRequests,
  };
  const noRequests =
    !show.asIsRequests &&
    !show.creditRequests &&
    !show.repairRequests &&
    !show.replaceRequests;
  let summaryIndex = 0;
  let detailsIndex = 0;

  useEffect(() => {
    hydrate();

    /* SCALING CALCULATION */
    let printoutWrapper = document.getElementById("printoutWrapper");
    let wrap = document.getElementById("wrap");
    let outer = document.getElementById("outer");

    const wrapperWidth = printoutWrapper.offsetWidth;
    const maxWidth = outer.offsetWidth;
    const maxHeight = outer.offsetHeight;

    // This calculates the initial scale/dimensions when the page loads.
    let scale = wrapperWidth / 1100;
    outer.style.webkitTransform = "scale(" + scale + ")";
    wrap.style.width = maxWidth * scale + "px";
    wrap.style.height = maxHeight * scale + "px";

    window.addEventListener("resize", () => {
      // This calculates the scale/dimensions as the page is resized.
      const width = printoutWrapper.offsetWidth;
      scale = width / 1100;
      outer.style.webkitTransform = "scale(" + scale + ")";
      wrap.style.width = maxWidth * scale + "px";
      wrap.style.height = maxHeight * scale + "px";
    });

    const urlAddress = fullAddress.replace(/\s+/g, "+");
    const streetURL = `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${urlAddress}&pitch=-0.76&key=AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU`;
    setUrl(streetURL);
  }, []);

  useEffect(() => {
    report.userRepairs.map((repair) => {
      repair.repairType = "user";
    });
    const allRepairs = [...report.repairs, ...report.userRepairs];
    const requestMemo = getRequestMemo(allRepairs);
    const { allPages, allQuotes } = getPdfPages(requestMemo, displayOptions);

    setQuoteFiles(allQuotes);
    setInspectionPages(allPages);
    setRequestListMemo(requestMemo);
  }, [
    show.asIsRequests,
    show.creditRequests,
    show.repairRequests,
    show.replaceRequests,
  ]);

  useEffect(() => {
    if (requestListMemo) {
      sortRequests(requestListMemo);
    }
  }, [contractorSort, repairSort]);

  async function hydrate() {
    if (report) {
      const allRepairs = [...report.repairs, ...report.userRepairs];
      const memo = await getRequestMemo(allRepairs);
      sortRequests(memo);
    }
  }

  function sortRequests(requestMemo) {
    const memo = _.cloneDeep(requestMemo);
    const compareContractorCost = (a, b) => {
      const typeA = a.contractorType || "Specialist";
      const typeB = b.contractorType || "Specialist";
      let comparison = 0;
      let contractorA = typeA
        ? memo[a.request.type || ""].headers[typeA].low
        : 0;
      let contractorB = typeB
        ? memo[b.request.type || ""].headers[typeB].low
        : 0;

      if (contractorSort === "alphabetical") {
        contractorA = typeA;
        contractorB = typeB;
      }

      if (contractorSort === "cost_estimate_high") {
        if (contractorA > contractorB) {
          comparison = -1;
        } else if (contractorA < contractorB) {
          comparison = 1;
        }
      } else {
        if (contractorA < contractorB) {
          comparison = -1;
        } else if (contractorA > contractorB) {
          comparison = 1;
        }
      }

      if (contractorA === contractorB) {
        let repairA = getRequestCost(a).low || 0;
        let repairB = getRequestCost(b).low || 0;

        if (repairSort === "alphabetical") {
          repairA = a.title || a.details.info;
          repairB = b.title || a.details.info;
        }

        if (repairSort === "cost_estimate_high") {
          if (repairA > repairB) {
            comparison = -1;
          } else if (repairA < repairB) {
            comparison = 1;
          }
        } else {
          if (repairA < repairB) {
            comparison = -1;
          } else if (repairA > repairB) {
            comparison = 1;
          }
        }
      }

      return comparison;
    };

    requestListKeys.map((key) => {
      if (memo[key] && memo[key].repairs) {
        memo[key].repairs.sort(compareContractorCost);
      }
    });

    setRequestListMemo(memo);
  }

  function scrollToPdfPage(e, pageNumber) {
    e.preventDefault();

    let pages = document.getElementsByClassName("react-pdf__Page");
    pages = Array.from(pages);
    const element = pages.filter((page) => {
      if (page.getAttribute("data-page-number") === pageNumber.toString()) {
        return page;
      }
    });

    if (element[0]) {
      element[0].scrollIntoView();
      setScrollAnchor(e.target.closest(".details-info"));
    }
  }

  function scrollToQuote(e, repair) {
    e.preventDefault();

    let quotes = document.getElementsByClassName("quote-wrapper");
    quotes = Array.from(quotes);
    const element = quotes.filter((quote) => {
      if (quote.getAttribute("data-url") === repair.quote.url) {
        return quote;
      }
    });

    if (element[0]) {
      element[0].scrollIntoView();
      setScrollAnchor(e.target.closest(".details-info"));
    }
  }

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "18.7%";
      style.left = "18.7%";
      style.transform = "scale(1.58)";
    });
  }

  function getDisplayValue(requestListKey) {
    switch (requestListKey) {
      case "Repair":
        return show.repairRequests;
      case "Replace":
        return show.replaceRequests;
      case "Credit":
        return show.creditRequests;
      case "As-Is":
        return show.asIsRequests;
      default:
        return "";
    }
  }

  function onDocumentLoadSuccess(e, file) {
    const allQuotePages = { ...quotePages };
    const { numPages } = e._pdfInfo;

    if (!quotePages[file]) {
      for (var i = 1; i <= numPages; i++) {
        if (allQuotePages[file]) {
          allQuotePages[file].push(i);
        } else {
          allQuotePages[file] = [i];
        }
      }
    }

    setQuotePages(allQuotePages);
  }

  return (
    <div id="wrap">
      <div id="outer">
        <div className="request-list-printout-wrapper">
          <div className="request-list-printout" id="requestListPrintout">
            <div className="row m-0">
              <div className="property-details-wrapper col-12">
                <div className="row">
                  <div
                    className="property-image-wrapper col-5"
                    style={{
                      backgroundImage: `url(${report.property.photo || url})`,
                    }}
                  ></div>

                  <div className="property-details col-7">
                    <div className="md-header">
                      <h4>Home Repair Estimate</h4>
                      <p className="md-subheader mt-2 mb-4">
                        powered by{" "}
                        <a href="/">
                          <img
                            src="/assets/img/thumbtack/wordmark_dark.png"
                            width="150px"
                            alt="thumbtack logo"
                          />
                        </a>
                      </p>
                    </div>

                    <div className="property-info row">
                      <div className="info-wrapper col-12 col-md-6">
                        <p className="info-label">Property Address</p>
                        <p className="info-content">{fullAddress}</p>
                      </div>

                      <div className="info-wrapper col-12 col-md-6">
                        <p className="info-label">
                          {preparedFor ? `Prepared For` : `Prepared On`}
                        </p>
                        {preparedFor ? (
                          <p className="info-content">
                            {preparedFor}
                            <br />
                            on{" "}
                            {!!report.request &&
                              moment(report.request.updatedAt).format("L")}
                          </p>
                        ) : (
                          <p className="info-content">
                            {!!report.request &&
                              moment(report.request.updatedAt).format("L")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {requestListMemo &&
                ((requestListMemo["Exclude"] &&
                  requestListMemo["Exclude"].total !== report.repairs.length) ||
                  !requestListMemo["Exclude"]) && (
                  <div
                    className={`request-list-summary col-12 ${
                      show.repairRequests ||
                      show.replaceRequests ||
                      show.creditRequests ||
                      show.asIsRequests
                        ? ""
                        : "d-none"
                    }`}
                  >
                    <div className="row">
                      {requestListKeys.map((key) => {
                        if (
                          !requestListMemo[key] ||
                          getDisplayValue(key) === false
                        ) {
                          return null;
                        }
                        if (
                          requestListMemo["Repair"] &&
                          requestListMemo["Repair"].total &&
                          show.repairRequests &&
                          show.replaceRequests &&
                          key === "Replace"
                        ) {
                          return null;
                        }

                        const { high, low, total } = requestListMemo[key];
                        let totalRequests = total;
                        let lowEstimate = low;
                        let highEstimate = high;
                        let label = key;
                        if (
                          show.repairRequests &&
                          show.replaceRequests &&
                          key === "Repair" &&
                          requestListMemo["Replace"] &&
                          requestListMemo["Replace"].total
                        ) {
                          totalRequests += requestListMemo["Replace"].total;
                          lowEstimate += requestListMemo["Replace"].low;
                          highEstimate += requestListMemo["Replace"].high;
                          label = key + "/Replace";
                        }

                        return (
                          <div
                            className={`col-4 ${
                              getDisplayValue(key) ? "" : "d-none"
                            }`}
                            key={key}
                          >
                            <div className="list-item">
                              <div className="list-img-wrapper">
                                <img
                                  src={`/assets/icons/Request-List/${key}.svg`}
                                />
                              </div>
                              <p>
                                <span>{totalRequests}</span>{" "}
                                {label === "As-Is"
                                  ? `Left As-Is`
                                  : `${label} Requests`}
                              </p>
                            </div>

                            <div className="list-item">
                              <div className="list-img-wrapper">
                                <img src="/assets/icons/Request-List/costs.svg" />
                              </div>
                              <p>
                                <span>
                                  {formatCost(lowEstimate, highEstimate)}
                                </span>{" "}
                                {label} {key === "Credit" ? "Amount" : "Costs"}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

              {report.request.notes && (
                <div
                  className={`request-list-notes-wrapper col-12 ${
                    show.requestListNotes ? "" : "d-none"
                  }`}
                >
                  <h4>Summary</h4>
                  <div className="request-list-notes">
                    <div className="request-list-notes-header">
                      <h4>Overall Comments</h4>
                    </div>

                    <div className="request-list-notes-content">
                      <p>{report.request.notes}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {requestListMemo &&
              requestListKeys.map((key) => {
                if (getDisplayValue(key) === false || !requestListMemo[key]) {
                  return null;
                }

                const { headers, high, low, repairs } = requestListMemo[key];
                let contractorHeaders = [];

                return (
                  <div
                    className={`request-list-breakdown col-12 ${
                      getDisplayValue(key) ? "" : "d-none"
                    }`}
                    key={key}
                  >
                    <div className="breakdown-header row">
                      <div className="header-title col-6">
                        <h4>
                          {key === "As-Is" ? `Left As-Is` : `${key} Requests`}
                        </h4>
                      </div>

                      <div className="header-cost col-6">
                        <p>
                          Total: <span>{formatCost(low, high)}</span>
                        </p>
                      </div>
                    </div>

                    <div className="breakdown-section">
                      {repairs.map((repair, index) => {
                        const { contractorType, request } = repair;
                        const { estimate, label, mdEstimate } = getRequestCost(
                          repair
                        );
                        let header = headers[contractorType] ||
                          headers["Specialist"] || { low: 0, high: 0 };

                        summaryIndex++;

                        return (
                          <div key={index}>
                            {!contractorHeaders.includes(
                              contractorType || "Specialist"
                            ) && (
                              <div className="breakdown-subheader row">
                                <div className="subheader-title col-6">
                                  <h4>{contractorType || "Specialist"}</h4>
                                </div>

                                <div className="subheader-cost col-6">
                                  <p>
                                    Sub-Total:{" "}
                                    <span>
                                      {formatCost(header.low, header.high)}
                                    </span>
                                  </p>
                                </div>
                                <div className="d-none">
                                  {contractorHeaders.push(
                                    contractorType || "Specialist"
                                  )}
                                </div>
                              </div>
                            )}

                            <div className="breakdown-request row">
                              <div className="request-header-wrapper col-12">
                                <div
                                  className={`request-header row ${
                                    show.requestNotes ? "" : "no-border"
                                  }`}
                                >
                                  <div className="request-title col-6">
                                    <p>
                                      <span>
                                        {summaryIndex}.{" "}
                                        {repair.title || repair.project_name}
                                      </span>
                                      &nbsp;
                                      {show.creditEstimatedCosts &&
                                        request.type === "Credit" &&
                                        `(Estimated Cost: ${mdEstimate})`}
                                    </p>
                                  </div>

                                  <div className="request-cost col-6">
                                    <p>
                                      {label}
                                      <b>{estimate}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {show.requestNotes &&
                              request.notes &&
                              request.notes !== "null" ? (
                                <div className="request-content col-12">
                                  <p>
                                    <span>Note</span>: {request.notes}
                                  </p>
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}

            <div
              className={`request-list-details col-12 ${
                noRequests || !show.requestDetails ? "d-none" : ""
              }`}
            >
              <div className="details-section-header row">
                <h4>Request Details</h4>
              </div>

              {requestListMemo &&
                requestListKeys.map((key) => {
                  if (getDisplayValue(key) === false || !requestListMemo[key]) {
                    return null;
                  }
                  const { high, low, repairs } = requestListMemo[key];

                  return (
                    <div
                      className={`request-list-breakdown page-break col-12 ${
                        getDisplayValue(key) ? "" : "d-none"
                      }`}
                      key={key}
                    >
                      <div className="breakdown-header row">
                        <div className="header-title col-6">
                          <h4>
                            {key === "As-Is" ? `Left As-Is` : `${key} Requests`}
                          </h4>
                        </div>

                        <div className="header-cost col-6">
                          <p>
                            Total Estimated Cost:{" "}
                            <span>{formatCost(low, high)}</span>
                          </p>
                        </div>
                      </div>

                      {repairs.map((repair, index) => {
                        const { request } = repair;
                        const { estimate } = getRequestCost(repair);

                        let pages = null;
                        if (
                          repair.inspectionPages &&
                          repair.inspectionPages.start !==
                            repair.inspectionPages.end
                        ) {
                          pages =
                            repair.inspectionPages.start +
                            " - " +
                            repair.inspectionPages.end;
                        } else {
                          pages =
                            repair.inspectionPages &&
                            repair.inspectionPages.start;
                        }

                        detailsIndex++;

                        return (
                          <div className="details-card row" key={index}>
                            <div className="details-info col-12">
                              <div className="details-header row">
                                <div className="details-title col-8">
                                  {repair.title ? (
                                    <h4>
                                      {detailsIndex}. {repair.title}
                                    </h4>
                                  ) : (
                                    <h4>
                                      {detailsIndex}. {repair.project_name}
                                    </h4>
                                  )}
                                </div>

                                <div className="details-cost col-4">
                                  <p>{estimate}</p>
                                </div>
                              </div>

                              <div>
                                {repair.repairType !== "user" && (
                                  <div className="d-flex">
                                    <p className="details-quantity">
                                      <span>Quantity:</span>{" "}
                                      {repair.details.quantity}
                                    </p>
                                    <p className="details-page-number">
                                      <span>
                                        Inspection Page(s) Referenced:{" "}
                                      </span>
                                      {show.inspectionFile ? (
                                        <a
                                          href="#"
                                          onClick={(e) =>
                                            scrollToPdfPage(
                                              e,
                                              repair.inspectionPages.start
                                            )
                                          }
                                        >
                                          {pages}
                                        </a>
                                      ) : (
                                        pages
                                      )}
                                    </p>
                                  </div>
                                )}

                                {repair.repairType !== "user" && (
                                  <div>
                                    <p className="details-subheader">
                                      Problem Summary
                                    </p>
                                    <p>
                                      {striptags(
                                        repair.details.inspectionComment
                                      )}
                                    </p>

                                    <p className="details-subheader">
                                      Proposed Repair Process
                                    </p>
                                    <p>{striptags(repair.details.info)}</p>
                                  </div>
                                )}

                                {repair.repairType === "user" &&
                                  show.requestQuotes && (
                                    <div className="d-flex">
                                      <p className="details-page-number">
                                        <span>Request Quote: </span>
                                        <a
                                          href="#"
                                          onClick={(e) =>
                                            scrollToQuote(e, repair)
                                          }
                                        >
                                          See Attached
                                        </a>
                                      </p>
                                    </div>
                                  )}

                                {repair.repairType === "user" && (
                                  <div>
                                    <p className="details-subheader">
                                      Problem Summary
                                    </p>
                                    <p>{repair.details}</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            {!!show.requestNotes &&
                              request.notes &&
                              request.notes !== "null" && (
                                <div className="details-notes col-12">
                                  <p>
                                    <span>Note</span>: {request.notes}
                                  </p>
                                </div>
                              )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>

            <div className="request-list-disclaimer col-12">
              <div className="disclaimer">
                <p>
                  <b>WHERE DO THESE ESTIMATES COME FROM?</b>
                </p>
                <p>
                  Estimates are data-backed pricing assessments of current
                  material and labor costs in your local area. All data comes
                  from market-specific transactions and bids. Data sources
                  include contractors, subcontractors, service providers, labor
                  providers, equipment rentals, and material suppliers. Data is
                  acquired daily using a combination of surveys, direct data
                  feeds from suppliers, and completed estimate transactions or
                  bids from construction professionals. This unique approach
                  provides a robust price estimate localized across the US for
                  all home-related repairs. Repair estimates were pulled on{" "}
                  {moment(report.publish_date).format("L")}.<br />
                </p>

                <p className="mt-4">
                  <b>WHY IS THERE A PRICE RANGE?</b>
                </p>
                <p>
                  The price estimate and range are based on similar repairs
                  completed in your local area and take into account the
                  variances of historical costs. While we strive to be as
                  accurate as possible - information about the defect comes from
                  the home inspection, and details about the home come from
                  public assessment information and GIS images - until a repair
                  starts, it's impossible to know all the requirements of the
                  repair. The range takes that uncertainty into account.
                </p>
              </div>
            </div>

            <div className="request-list-appendix">
              <Document
                file={report.inspection.url}
                loading={<div>Loading...</div>}
                className={show.inspectionFile ? "" : "d-none"}
              >
                {inspectionPages.map((page, index) => {
                  if (index === 0) {
                    return (
                      <div className="page-break-before" key={page}>
                        <h4 className="mx-2">Inspection Pages Referenced</h4>
                        <Page
                          className="md-pdf-page"
                          key={page}
                          onLoadSuccess={removeTextLayerOffset}
                          pageNumber={page}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <Page
                        className="md-pdf-page page-break"
                        key={page}
                        onLoadSuccess={removeTextLayerOffset}
                        pageNumber={page}
                      />
                    );
                  }
                })}
              </Document>

              {quoteFiles &&
                !!quoteFiles.length &&
                quoteFiles.map((file) => {
                  return (
                    <div className="quote-wrapper" data-url={file} key={file}>
                      <Document
                        className={show.requestQuotes ? "" : "d-none"}
                        file={file}
                        loading={<div>Loading...</div>}
                        onLoadSuccess={(e) => onDocumentLoadSuccess(e, file)}
                      >
                        {quotePages[file] &&
                          quotePages[file].map((page) => {
                            return (
                              <Page
                                className="md-pdf-page page-break"
                                key={page}
                                onLoadSuccess={removeTextLayerOffset}
                                pageNumber={page}
                              />
                            );
                          })}
                      </Document>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RequestListPrintout;
