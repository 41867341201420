import "./index.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Geocode from "react-geocode";
import Image from "../Image";
import withFetch from "../../hocs/withFetch";
import { baseUrl } from "../../hocs/withFetch";

@withRouter
@withFetch((props) => ({
  getPropertyData: () => ({
    propertyData: `/property/${props.property.property_log_id}`,
  }),
}))
export default class PropertyCard extends Component {
  state = {
    center: {},
    propertiesPhotos: [],
    // showArchiveInfo: false,
  };

  componentDidMount() {
    //Get lat, lng values from street address for Google Map (map view)
    Geocode.setApiKey("AIzaSyDq3s89D1S_E4d28YjgmeiXwlLz1LlFAaU");
    Geocode.fromAddress(this.props.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setCenter(lat, lng);
      },
      (error) => {
        console.log(error);
      }
    );
    if (this.props.property.property_log_id) {
      this.props.getPropertyData();
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  setCenter = (lat, lng) => {
    this.setState({ center: { lat: lat, lng: lng } });
  };

  render() {
    // const { showArchiveInfo } = this.state;
    const {
      // archived,
      property,
      propertyPhotos,
      updatedProperty,
      propertyData,
    } = this.props;
    let propertyName =
      propertyData && !propertyData.pending
        ? propertyData.value.property_name
        : null;
    let address =
      property.address + ", " + property.city + ", " + property.state;
    let urlAddress = address.replace(/\s+/g, "+");
    let showCharge = [];

    //Set Google Map URLs for street view and map view
    let streetURL = `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${urlAddress}&pitch=-0.76&key=AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU`;
    let mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${urlAddress}&zoom=18&size=600x300&maptype=roadmap&markers=size:mid%7Ccolor:0xffbf00%7C${this.state.center.lat},${this.state.center.lng}&key=AIzaSyDq3s89D1S_E4d28YjgmeiXwlLz1LlFAaU`;

    if (this.props.chargeData) {
      //If the current user has ordered the current domoreport, store charge data in showCharge
      showCharge = this.props.chargeData.filter((charge) => {
        return charge.domoreport_log_id == property.domoreport_log_id;
      });
    } else {
      showCharge = [];
    }

    return (
      <div className="PropertyCard-body" key={property.domoreport_log_id}>
        {/* Display success message if property nickname is updated*/}
        {updatedProperty &&
          !updatedProperty.pending &&
          updatedProperty.value.success &&
          updatedProperty.value.property_log_id == property.property_log_id &&
          this._renderAlert("success", "Property Name updated")}

        <div className="col-12 col-sm-4">
          <h4>
            {property.address}, {property.city}, {property.state}{" "}
          </h4>
          <div className="form-group">
            <label>Nickname</label>
            <input
              type="text"
              className="form-control"
              accept=".jpg,.jpeg,.png"
              onBlur={(evt) =>
                this.props.updateProperty(
                  property.property_log_id,
                  evt.target.value
                )
              }
              defaultValue={propertyName}
            />
          </div>
          <div className="row">
            <div
              className={
                showCharge.length <= 0
                  ? "property-actions-wrapper col mb-0 mb-sm-5"
                  : "property-actions-wrapper col"
              }
            >
              {/* Only Published Domoreports can be viewed */}
              {property.domoreport_published === 1 && (
                <Link
                  to={`/domoreport?report=${property.domoreport_log_id}`}
                  className="btn btn-uiblue400"
                >
                  {" "}
                  View Report
                </Link>
              )}

              {/* {archived ? (
                <div className="archive-tooltip-wrapper">
                  <Link
                    className="btn btn-mdgreen"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleArchive(
                        property.domoreport_log_id,
                        false
                      );
                    }}
                    onMouseEnter={() =>
                      this.handleChange("showArchiveInfo", true)
                    }
                    onMouseLeave={() =>
                      this.handleChange("showArchiveInfo", false)
                    }
                    to="#"
                  >
                    <i className="fa fa-eye" />
                    &nbsp;Unarchive
                  </Link>

                  <div
                    className={`archive-info ${
                      showArchiveInfo ? "" : "d-none"
                    }`}
                  >
                    <p>This only affects your view and can easily be undone.</p>
                  </div>
                </div>
              ) : (
                <div className="archive-tooltip-wrapper">
                  <Link
                    className="btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleArchive(
                        property.domoreport_log_id,
                        true
                      );
                    }}
                    onMouseEnter={() =>
                      this.handleChange("showArchiveInfo", true)
                    }
                    onMouseLeave={() =>
                      this.handleChange("showArchiveInfo", false)
                    }
                    to="#"
                  >
                    <i className="fa fa-eye-slash" />
                    &nbsp;Archive
                  </Link>

                  <div
                    className={`archive-info ${
                      showArchiveInfo ? "" : "d-none"
                    }`}
                  >
                    <p>This only affects your view and can easily be undone.</p>
                  </div>
                </div>
              )} */}

              {/* Only Owners can manage sharing preferences */}
              {property.address && property.permissions === "OWNER" && (
                <Link
                  to="#"
                  onClick={(e) => {
                    this.props.toggleSharing(
                      e,
                      property.domoreport_log_id,
                      address
                    );
                  }}
                  className="btn btn-uiblue400"
                >
                  {" "}
                  Manage Sharing
                </Link>
              )}

              {/* Only the user who ordered the current domoreport can view order details */}
              {showCharge.length ? (
                <Link
                  to="#"
                  onClick={(e) => {
                    this.props.toggleOrderDetails(
                      e,
                      property.domoreport_log_id
                    );
                  }}
                  className="btn btn-uiblue400"
                >
                  {" "}
                  View Order Details
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-8">
          <p className="d-block d-sm-none d-xl-block mt-4 mt-sm-0">
            Property Photos
          </p>
          <div className="flex-row md-property-photos-container">
            {/* Render all photos associated with current property */}
            {propertyPhotos &&
            propertyPhotos.photos &&
            propertyPhotos.photos.length ? (
              propertyPhotos.photos.map((photo, index) => {
                //Make the first image primary
                if (index === 0) {
                  return (
                    <Image
                      key={photo.doc_log_id}
                      docID={photo.doc_log_id}
                      reportID={propertyPhotos.domoreport_log_id}
                      sourceURL={`${baseUrl}/api/1/domoreport/${propertyPhotos.domoreport_log_id}/domoreportDocument/${photo.doc_log_id}?download=true`}
                      token={
                        localStorage.getItem("majordomo-token") ||
                        localStorage.getItem("majordomo-temp-token")
                      }
                      primary={true}
                      pageSource={"/repair-estimate/settings"}
                      toggleConfirmModal={this.props.toggleConfirmModal}
                      toggleImageModal={this.props.toggleImageModal}
                      deleteFile={this.props.deleteFile}
                    />
                  );
                } else {
                  return (
                    <Image
                      key={photo.doc_log_id}
                      docID={photo.doc_log_id}
                      reportID={propertyPhotos.domoreport_log_id}
                      sourceURL={`${baseUrl}/api/1/domoreport/${propertyPhotos.domoreport_log_id}/domoreportDocument/${photo.doc_log_id}?download=true`}
                      token={
                        localStorage.getItem("majordomo-token") ||
                        localStorage.getItem("majordomo-temp-token")
                      }
                      pageSource={"/repair-estimate/settings"}
                      toggleConfirmModal={this.props.toggleConfirmModal}
                      toggleImageModal={this.props.toggleImageModal}
                      deleteFile={this.props.deleteFile}
                    />
                  );
                }
              })
            ) : (
              // If the current property has no photos show Google Map (street view)
              <div
                className="md-grid-streetview primary"
                style={{ background: `url(${streetURL}) center no-repeat` }}
                onClick={(e) =>
                  this.props.toggleImageModal(e, null, null, null, streetURL)
                }
              />
            )}
            {/* Render Google Map (map view). NOTE: Might not need conditional rendering */}
            {propertyPhotos &&
              propertyPhotos.photos &&
              propertyPhotos.photos.length <= 6 && (
                <div
                  className="md-grid-map"
                  style={{ background: `url(${mapURL}) center no-repeat` }}
                  onClick={(e) =>
                    this.props.toggleImageModal(e, null, null, null, mapURL)
                  }
                />
              )}

            {/* Only allow photos to be added if there are fewer than six currently uploaded*/}
            {propertyPhotos &&
              propertyPhotos.photos &&
              propertyPhotos.photos.length < 6 && (
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: "none" }}
                  onChange={(e) =>
                    this.props.addPhoto(e, property.domoreport_log_id)
                  }
                  data-report-id={property.domoreport_log_id}
                />
              )}
            {propertyPhotos &&
              propertyPhotos.photos &&
              propertyPhotos.photos.length < 6 && (
                <button
                  className="btn btn-thumbtack-outline md-thumbtack-text"
                  onClick={() => {
                    this.upload.click();
                  }}
                >
                  +
                </button>
              )}
          </div>
        </div>
      </div>
    );
  }

  _renderAlert(variant, message) {
    return (
      <Alert
        variant={variant}
        onClose={this.closeMessage}
        className="col-12"
        dismissible
      >
        <p>{message}</p>
      </Alert>
    );
  }
}
