import {
  Anchor,
  Button,
  Grid,
  Group,
  Image,
  MediaQuery,
  Spoiler,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export default function ProCard({ pro, reportId, numericId }) {
  const [stars, setStars] = useState([]);
  const theme = useMantineTheme();

  useEffect(() => {
    let allStars = [];
    for (var i = 0; i < Math.floor(pro.rating); i++) {
      allStars.push(<i key={i} className="fa fa-star" />);

      if (
        i === Math.floor(pro.rating) - 1 &&
        pro.rating > Math.floor(pro.rating)
      ) {
        allStars.push(<i key={i + 0.5} className="fa fa-star-half-o" />);
      }
    }

    for (var j = allStars.length; j < 5; j++) {
      allStars.push(<i key={i} className="fa fa-star-o" />);
    }

    setStars(allStars);
  }, []);

  return (
    <Grid p="sm" mb="md" mx={0}>
      <Grid.Col span={3} sm={2}>
        <Image radius="md" src={pro.image_url} alt="pro" />
      </Grid.Col>

      <Grid.Col span={9} sm={7}>
        <Stack spacing={0}>
          <Group style={{ justifyContent: "space-between" }}>
            <Text weight="bold" lineClamp={1}>
              {pro.business_name}
            </Text>
          </Group>

          {pro.quote && !!pro.quote.starting_cost && (
            <Text
              weight="bold"
              color="dimmed"
              sx={() => ({
                [theme.fn.largerThan("sm")]: { display: "none" },
              })}
            >
              {pro.quote.starting_cost > 0
                ? `$${pro.quote.starting_cost / 100}`
                : ``}
              {pro.quote.cost_unit ? `/${pro.quote.cost_unit}` : ""}
            </Text>
          )}

          <Group spacing={5}>
            <Text color="#2DB783">
              {pro.rating.toFixed(1)}{" "}
              {stars.map((star) => {
                return star;
              })}
            </Text>{" "}
            <Text size="sm" color="dimmed">
              ({pro.num_reviews})
            </Text>
          </Group>

          <Group spacing={5}>
            <i className="fa fa-clock-o mr-1" />
            <Text size="sm" color="dimmed">
              {pro.years_in_business} Year(s) in Business
            </Text>
          </Group>

          <Group spacing={5}>
            <i className="fa fa-trophy mr-1" />
            <Text size="sm" color="dimmed">
              {pro.num_hires} Hires on Thumbtack
            </Text>
          </Group>

          {pro.featured_review && (
            <Stack p="xs" style={{ backgroundColor: theme.colors.gray[0] }}>
              <Spoiler
                maxHeight={44}
                showLabel={
                  <Anchor
                    size="sm"
                    sx={() => ({
                      color: `${theme.colors.blue[4]} !important`,
                      ":hover": {
                        color: `${theme.colors.blue[4]} !important`,
                      },
                    })}
                  >
                    See More
                  </Anchor>
                }
                hideLabel={
                  <Anchor
                    size="sm"
                    sx={() => ({
                      color: `${theme.colors.blue[4]} !important`,
                      ":hover": {
                        color: `${theme.colors.blue[4]} !important`,
                      },
                    })}
                  >
                    Hide
                  </Anchor>
                }
              >
                <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                  <Text color="dimmed" size="sm">
                    {pro.featured_review ? `"${pro.featured_review}"` : ""}
                  </Text>
                </MediaQuery>
              </Spoiler>
            </Stack>
          )}

          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Group mt="sm">
              <Button
                size="xs"
                type="button"
                component="a"
                href={
                  pro.thumbtack_url +
                  "&utm_campaign=pro-profile-click&utm_content=" +
                  numericId
                }
                onClick={() =>
                  ReactGA.event({
                    category: "User Actions",
                    action: "Thumbtack Pro Click",
                    label: `${pro.business_name}`,
                  })
                }
                target="_blank"
                rel="noopener noreferrer"
                radius="sm"
                styles={() => ({
                  root: {
                    color: `#ffffff !important`,
                    textDecoration: "none",
                    backgroundColor: theme.colors.blue[4],
                    "&:hover": {
                      color: `#ffffff !important`,
                      textDecoration: "none",
                      backgroundColor: theme.colors.blue[4],
                    },
                  },
                })}
              >
                View Profile
              </Button>
            </Group>
          </MediaQuery>
        </Stack>
      </Grid.Col>

      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Grid.Col span={3}>
          <Stack
            align="flex-end"
            style={{ position: "relative", height: "100%" }}
          >
            {pro.quote && !!pro.quote.starting_cost && (
              <Text weight="bold" align="right">
                {pro.quote.starting_cost > 0
                  ? `$${pro.quote.starting_cost / 100}`
                  : ``}
                {pro.quote.cost_unit ? `/${pro.quote.cost_unit}` : ""}
              </Text>
            )}

            <Button
              size="sm"
              type="button"
              component="a"
              href={
                pro.thumbtack_url +
                "&utm_campaign=pro-profile-click&utm_content=" +
                numericId
              }
              onClick={() =>
                ReactGA.event({
                  category: "User Actions",
                  action: "Thumbtack Pro Click",
                  label: `${pro.business_name}`,
                })
              }
              target="_blank"
              rel="noopener noreferrer"
              radius="sm"
              styles={() => ({
                root: {
                  position: "absolute",
                  bottom: 0,
                  backgroundColor: theme.colors.blue[4],
                  color: `#ffffff !important`,
                  textDecoration: "none",
                  "&:hover": {
                    color: `#ffffff !important`,
                    textDecoration: "none",
                    backgroundColor: theme.colors.blue[4],
                  },
                },
              })}
            >
              View Profile
            </Button>
          </Stack>
        </Grid.Col>
      </MediaQuery>
    </Grid>
  );
}
