import { connect } from "react-refetch";
import urlJoin from "url-join";

/* 
  The server is based on the current URL. If the user 
  is on majordomo.com, the production user will be used.
  In all other cases (dev and local) the staging server will be
  used. If for some reason you want to use the production server 
  on dev or local, simply remove the current baseUrl and replace 
  it with baseUrl = 'https://majordomoapiserver.herokuapp.com/api/1'
*/
export const baseUrl = process.env.REACT_APP_BASE_URL;

export default connect.defaults({
  buildRequest: function(mapping) {
    const options = {
      method: mapping.method,
      headers: {
        authorization:
          localStorage.getItem("majordomo-token") ||
          localStorage.getItem("majordomo-temp-token") ||
          null,
        ...mapping.headers,
      },
      credentials: mapping.credentials,
      redirect: mapping.redirect,
      mode: mapping.mode,
      body: mapping.body,
    };

    return new Request(urlJoin(`${baseUrl}/api/1`, mapping.url), options);
  },
  handleResponse: function(response) {
    let paths = [
      "/login",
      "/demo",
      "/longbeach-demo",
      "/clarkshouse",
      "/animalhouse",
      "/explore-demo",
      "/home-alone",
      "/christmas-story",
      "/partner",
    ];

    if (response.status === 401 && !paths.includes(window.location.pathname)) {
      return false;
    } else {
      return response.json();
    }
  },
});
