import React from "react";

export default function FeatureHeader({ title, variant, showCredit }) {
  return (
    <div
      className={`md-header-wrapper ${
        variant === "start" ? "start" : "center"
      }`}
    >
      <h2 className="md-header md-uiblue400-text mt-3">{title}</h2>
      {showCredit && (
        <p className="md-subheader mt-2 mb-4">
          powered by{" "}
          <img
            src="/assets/img/thumbtack/wordmark_dark.png"
            width="150px"
            alt="thumbtack logo"
          />
        </p>
      )}
    </div>
  );
}
