import "./index.scss";
import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import CostInput from "../../CostInput";

function UploadQuoteModal({
  fileName,
  hideModal,
  quoteAmount,
  quoteFile,
  quotePending,
  repair,
  setFileName,
  setQuoteAmount,
  setQuoteFile,
  updateRepairRequest,
}) {
  const action =
    repair && repair.quote && repair.quote.amount ? "Update" : "Upload";

  function handleSubmit(e) {
    e.preventDefault();
    updateRepairRequest();
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={hideModal}
      className="upload-quote-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="md-modal-title">
          {repair.quote && repair.quote.amount ? "Edit" : "Upload"} Repair Quote
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="upload-quote-form">
          <label htmlFor="quote">Upload Quote Document (.pdf)</label>
          {fileName ? (
            <div className="quote-details">
              <p>
                <i className="fa fa-paperclip" />{" "}
                {fileName.length > 57
                  ? fileName.substr(57, fileName.length)
                  : fileName}
              </p>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setFileName(null);
                  setQuoteFile(null);
                }}
              >
                Change File
              </a>
            </div>
          ) : (
            <input
              accept="application/pdf"
              className="quote-file-input"
              id="quote"
              name="quote"
              onChange={(e) => setQuoteFile(e.target.files[0])}
              type="file"
            />
          )}

          <label htmlFor="cost">Contractor Quote</label>
          <CostInput
            cost={quoteAmount || ""}
            errors={{ cost: false }}
            placeholder="0"
            setCost={setQuoteAmount}
          />
        </div>

        <div className="md-modal-actions">
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              hideModal();
            }}
            className="btn cancel-btn"
          >
            Cancel
          </Link>
          <Link
            to="#"
            onClick={handleSubmit}
            className={`btn submit-btn ${
              (!quoteFile && !fileName) || !quoteAmount ? "disabled" : ""
            }`}
          >
            {quotePending ? "Processing..." : `${action} Quote`}
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default UploadQuoteModal;
