import "./index.scss";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import RepairTableCard from "./RepairTableCard";
import { formatCost } from "../../../services/helpers";

function RepairTable({
  reportData,
  priority,
  reportID,
  showRequestList,
  getDomoreport,
  userCanEdit,
}) {
  const [showEstimateInfo, setShowEstimateInfo] = useState(false);
  const headerLabelsMap = {
    totalEstimates: "Cost Estimate",
    contractorType: "Contractor Type",
    inspectionPages: "Page Number",
    priority: "Priority",
    section_name: "Section",
  };
  let allHeaders = [];
  const { repairs } = reportData;
  const [headersMemo, setHeadersMemo] = useState({});
  const [sortedRepairs, setSortedRepairs] = useState([]);
  const [sort, setSort] = useState("contractorType");
  const [invert, setInvert] = useState(false);

  useEffect(() => {
    getHeadersMemo(repairs.sort(compare));
  }, [repairs]);

  useEffect(() => {
    const filteredRepairs = [];
    for (let i = 0; i < repairs.length; i++) {
      const repair = repairs[i];
      if (
        priority &&
        priority !== "All" &&
        priority !== "Requests" &&
        repair.priority === priority
      ) {
        filteredRepairs.push(repair);
      } else if (!priority || priority === "All" || priority === "Requests") {
        filteredRepairs.push(repair);
      }
    }

    filteredRepairs.sort(compare);
    getHeadersMemo(filteredRepairs);
    setSortedRepairs(filteredRepairs);
  }, [repairs, priority, sort, invert]);

  function getHeadersMemo(repairs, newSort) {
    const memo = {};
    const memoSort = newSort || sort;

    if (repairs.length) {
      for (let i = 0; i < repairs.length; i++) {
        const repair = repairs[i];
        const { quote, request } = repair;
        const tripCharge =
          repair.totalEstimates.combined !== repair.totalEstimates.separate;
        let label = repair[memoSort];
        if (memoSort === "totalEstimates") {
          label = "Price";
        } else if (memoSort === "inspectionPages") {
          label = "Page Number";
        }

        if (memo[label]) {
          ++memo[label].total;

          if (
            (request.type === "Repair" || request.type === "Replace") &&
            quote.amount
          ) {
            memo[label].combinedLow =
              Number(memo[label].combinedLow) + quote.amount;
            memo[label].combinedHigh =
              Number(memo[label].combinedHigh) + quote.amount;
            memo[label].low =
              Number(memo[label].low) + repair.totalEstimates.separate.low;
            memo[label].high =
              Number(memo[label].high) + repair.totalEstimates.separate.high;
          } else {
            memo[label].combinedLow =
              Number(memo[label].combinedLow) +
              (tripCharge
                ? repair.totalEstimates.combined.low
                : repair.totalEstimates.separate.low);
            memo[label].combinedHigh =
              Number(memo[label].combinedHigh) +
              (tripCharge
                ? repair.totalEstimates.combined.high
                : repair.totalEstimates.separate.high);

            memo[label].low =
              Number(memo[label].low) + repair.totalEstimates.separate.low;
            memo[label].high =
              Number(memo[label].high) + repair.totalEstimates.separate.high;
          }
        } else {
          if (
            (request.type === "Repair" || request.type === "Replace") &&
            quote.amount
          ) {
            memo[label] = {
              total: 1,
              combinedLow: quote.amount,
              combinedHigh: quote.amount,
              low: repair.totalEstimates.separate.low,
              high: repair.totalEstimates.separate.high,
            };
          } else {
            memo[label] = {
              total: 1,
              combinedLow: tripCharge
                ? repair.totalEstimates.combined.low
                : repair.totalEstimates.separate.low,
              combinedHigh: tripCharge
                ? repair.totalEstimates.combined.high
                : repair.totalEstimates.separate.high,
              low: repair.totalEstimates.separate.low,
              high: repair.totalEstimates.separate.high,
            };
          }
        }
      }
    }

    setHeadersMemo(memo);
    setSortedRepairs(repairs);
  }

  const compare = (a, b) => {
    let sortA = "";
    let sortB = "";

    if (sort === "totalEstimates") {
      sortA = b.totalEstimates.separate.low;
      sortB = a.totalEstimates.separate.low;
    } else if (sort === "priority") {
      sortA = b[sort];
      sortB = a[sort];
    } else if (sort === "inspectionPages") {
      sortA = a[sort].start;
      sortB = b[sort].start;
    } else {
      sortA = a[sort];
      sortB = b[sort];
    }

    let comparison = 0;
    if (sortA > sortB) {
      comparison = invert ? -1 : 1;
    } else if (sortA < sortB) {
      comparison = invert ? 1 : -1;
    } else if (sortA === sortB) {
      const priceA = a.totalEstimates.separate.low;
      const priceB = b.totalEstimates.separate.low;

      if (priceA < priceB) {
        comparison = invert ? -1 : 1;
      } else if (priceA > priceB) {
        comparison = invert ? 1 : -1;
      }
    }

    return comparison;
  };

  function handleSort(key) {
    if (sort === key) {
      setInvert(!invert);
    } else {
      setInvert(false);
      setSort(key);
    }
    getHeadersMemo(sortedRepairs, key);
  }

  if (!repairs.length) {
    return null;
  }

  return (
    <div className="md-repair-table" id="repairTable">
      <div className="table-header-wrapper col-12">
        <div className="table-header row my-3">
          <div className="table-actions col-12 col-md-5">
            <h4 className="report-section-header mb-0">Repair Details</h4>
          </div>

          <div className="table-filters col-12 col-md-7 d-none d-lg-flex">
            <div className="row">
              <div className="table-filter col-3">
                <p onClick={() => handleSort("totalEstimates")}>
                  Cost Estimate{" "}
                  {sort === "totalEstimates" && invert ? (
                    <i className="fa fa-caret-up" />
                  ) : (
                    <i className="fa fa-caret-down" />
                  )}
                </p>
              </div>
              <div className="table-filter col-4">
                <p onClick={() => handleSort("contractorType")}>
                  Contractor Type{" "}
                  {sort === "contractorType" && invert ? (
                    <i className="fa fa-caret-up" />
                  ) : (
                    <i className="fa fa-caret-down" />
                  )}
                </p>
              </div>
              <div className="table-filter col-2">
                <p onClick={() => handleSort("inspectionPages")}>
                  Page #{" "}
                  {sort === "inspectionPages" && invert ? (
                    <i className="fa fa-caret-up" />
                  ) : (
                    <i className="fa fa-caret-down" />
                  )}
                </p>
              </div>
              <div className="table-filter col-3 text-right">
                <p onClick={() => handleSort("priority")}>
                  Priority{" "}
                  {sort === "priority" && invert ? (
                    <i className="fa fa-caret-up" />
                  ) : (
                    <i className="fa fa-caret-down" />
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {sortedRepairs &&
        !!sortedRepairs.length &&
        !!Object.keys(headersMemo).length &&
        sortedRepairs.map((repair) => {
          let label = repair[sort];
          if (sort === "totalEstimates") {
            label = "Price";
          } else if (sort === "inspectionPages") {
            label = "Page Number";
          }

          if (!headersMemo[label]) {
            return null;
          }

          const combined =
            headersMemo[label].combinedLow !== headersMemo[label].low &&
            headersMemo[label].combinedHigh !== headersMemo[label].high;

          return (
            <div className="table-body col-12" key={repair.id}>
              {sort !== "totalEstimates" &&
                sort !== "inspectionPages" &&
                !allHeaders.includes(label) && (
                  <div
                    className={`section-header row ${
                      sort === "priority" || priority === repair.priority
                        ? `md-${repair.priority}`
                        : ""
                    }`}
                  >
                    <div className="section-title col-6 col-lg-5">
                      <h4>
                        {label}{" "}
                        {/*<span className="subtitle">{headersMemo[label].total} repairs needed</span>*/}
                      </h4>
                    </div>
                    <div className="section-details-wrapper col-6 col-lg-7">
                      <div className="row">
                        <div className="section-details col-12 col-sm-3">
                          <h4 className={combined ? "md-discounted-price" : ""}>
                            {formatCost(
                              headersMemo[label].low,
                              headersMemo[label].high
                            )}
                          </h4>
                          {combined && (
                            <div
                              className="combined-cost-estimate"
                              onMouseEnter={() => setShowEstimateInfo(true)}
                              onMouseLeave={() => setShowEstimateInfo(false)}
                            >
                              <h4>
                                {formatCost(
                                  headersMemo[label].combinedLow,
                                  headersMemo[label].combinedHigh
                                )}
                                &nbsp;
                                <i className="fa fa-info-circle" />
                              </h4>

                              <div
                                className={`combined-estimate-info ${
                                  showEstimateInfo ? "" : "d-none"
                                }`}
                              >
                                <p>
                                  The totals are updated because of two reasons:
                                  <br />
                                  <br />
                                  1: Cost savings by requesting multiple repairs
                                  and replacements from the same contactor type.
                                  Instead of charging two separate trip charges
                                  for a small job, they can be combined into a
                                  single trip and save you money.{" "}
                                  <a
                                    href="https://intercom.help/majordomo/en/articles/4626563-why-are-my-total-costs-updated"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Learn more
                                  </a>
                                  <br />
                                  <br />
                                  2: You uploaded a quote from a specific
                                  contractor that we prioritized over the range
                                  estimate.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="section-details col-4 d-none d-sm-block" />
                        <div className="section-details col-2 d-none d-sm-block" />
                        <div className="section-details col-3 d-none d-sm-block" />
                        <div className="d-none">
                          {allHeaders.push(repair[sort])}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {(sort === "totalEstimates" || sort === "inspectionPages") &&
                !allHeaders.includes(sort) && (
                  <div
                    className={`section-header row ${
                      sort === "priority" || priority === repair.priority
                        ? `md-${repair.priority}`
                        : ""
                    }`}
                  >
                    <div className="section-title col-6 col-lg-5">
                      <h4>{headerLabelsMap[sort]}</h4>
                    </div>
                    <div className="section-details-wrapper col-6 col-lg-7">
                      <div className="row">
                        <div className="section-details col-12 col-sm-3">
                          <h4 className={combined ? "md-discounted-price" : ""}>
                            {formatCost(
                              headersMemo[label].low,
                              headersMemo[label].high
                            )}
                          </h4>
                          {combined && (
                            <div
                              className="combined-cost-estimate"
                              onMouseEnter={() => setShowEstimateInfo(true)}
                              onMouseLeave={() => setShowEstimateInfo(false)}
                            >
                              <h4>
                                {formatCost(
                                  headersMemo[label].combinedLow,
                                  headersMemo[label].combinedHigh
                                )}
                                &nbsp;
                                <i className="fa fa-info-circle" />
                              </h4>

                              <div
                                className={`combined-estimate-info ${
                                  showEstimateInfo ? "" : "d-none"
                                }`}
                              >
                                <p>
                                  The reduced amount is from cost savings by
                                  requesting multiple repairs and replacements
                                  from the same contactor type. Instead of
                                  charging two separate trip charges for a small
                                  job, they can be combined into a single trip
                                  and save you money.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="section-details col-4 d-none d-sm-block" />
                        <div className="section-details col-2 d-none d-sm-block" />
                        <div className="section-details col-3 d-none d-sm-block" />
                        <div className="d-none">{allHeaders.push(sort)}</div>
                      </div>
                    </div>
                  </div>
                )}

              <RepairTableCard
                reportData={reportData}
                getDomoreport={getDomoreport}
                repair={repair}
                reportID={reportID}
                showRequestList={showRequestList}
                userCanEdit={userCanEdit}
              />
            </div>
          );
        })}
    </div>
  );
}
export default withRouter(RepairTable);
