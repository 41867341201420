import "./index.scss";
import React from "react";
import { Modal } from "react-bootstrap";

function DisplayOptionsInfoModal({ hideModal }) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={hideModal}
      className="display-options-info-modal"
    >
      <Modal.Header closeButton>
        <h4>Display Options</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="display-options-info">
          <p className="display-options-title">Request Type</p>
          <hr />

          <div className="display-option">
            <h4>Repair Requests</h4>
            <p>
              Show repair requests. If you have none, it will automatically be
              hidden.
            </p>
            <img src="/assets/img/display-options/repair.png" width="100%" />
          </div>

          <div className="display-option">
            <h4>Replace Requests</h4>
            <p>
              Show replacement requests. If you have none, it will automatically
              be hidden.
            </p>
            <img src="/assets/img/display-options/replace.png" width="100%" />
          </div>

          <div className="display-option">
            <h4>Credit Requests</h4>
            <p>
              Show credit requests. If you have none, it will automatically be
              hidden.
            </p>
            <img src="/assets/img/display-options/credit.png" width="100%" />
          </div>

          <div className="display-option">
            <h4>Left As-Is</h4>
            <p>
              Show as-is items. If you have none, it will automatically be
              hidden.
            </p>
            <img src="/assets/img/display-options/as-is.png" width="100%" />
          </div>
        </div>

        <div className="display-options-info">
          <p className="display-options-title">Report Details</p>
          <hr />

          <div className="display-option">
            <h4>Overall Comments</h4>
            <p>
              Show the overall comments you added at the top of the Home Repair
              Estimate.
            </p>
            <img
              src="/assets/img/display-options/overall-comments.png"
              width="100%"
            />
          </div>

          <div className="display-option">
            <h4>Request Notes</h4>
            <p>
              Show notes you created for each individual item. If you didn’t
              make a note, it will automatically hide it.
            </p>
            <img
              src="/assets/img/display-options/request-notes.png"
              width="100%"
            />
          </div>

          <div className="display-option">
            <h4>Request Details</h4>
            <p>
              The first part of the request list is a summary. This will show
              the second half the report which includes additional details such
              as the problem and proposed repairs.
            </p>
            <img
              src="/assets/img/display-options/request-details.png"
              width="100%"
            />
          </div>

          <div className="display-option">
            <h4>Inspection Pages Referenced</h4>
            <p>
              We never show the entire inspection, but this will show select
              pages of the inspection that are referenced in the request list.
            </p>
            <img
              src="/assets/img/display-options/inspection-pages.png"
              width="100%"
            />
          </div>

          <div className="display-option">
            <h4>Uploaded Quotes</h4>
            <p>
              Include the contract quotes files you uploaded at the end of the
              document.
            </p>
            <img
              src="/assets/img/display-options/uploaded-quotes.png"
              width="100%"
            />
          </div>

          <div className="display-option">
            <h4>Credit Estimated Cost</h4>
            <p>
              Display the estimated cost range for the item you’re requesting a
              credit for.
            </p>
            <img
              src="/assets/img/display-options/credt-costs.png"
              width="100%"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default DisplayOptionsInfoModal;
