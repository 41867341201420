import "./index.scss";
import React from "react";
import { Image } from "@mantine/core";

export default function RootFooter() {
  return (
    <div className="md-root-footer-container">
      <div className="col-12 md-root-footer">
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div className="col-12 col-xl-3 md-logo-container order-4 order-xl-1">
            <div className="logo">
              <a href="/">
                <Image
                  src="/assets/img/thumbtack/wordmark_dark.png"
                  width={200}
                />
              </a>
            </div>
            <span>© Copyright 2023 | All rights reserved</span>
          </div>

          {/* <div className="col-12 col-sm-4 col-xl-3 order-1 order-xl-2">
              <h4 className="heading">Solutions</h4>
              <ul className="link-list">
                <li>
                  <a href="/solutions/for-home-buyers">Home Buyers</a>
                </li>
                <li>
                  <a href="/solutions/for-home-sellers">Home Sellers</a>
                </li>
                <li>
                  <a href="/solutions/for-real-estate-agents">
                    Real Estate Agents
                  </a>
                </li>
                <li>
                  <a href="/solutions/for-home-inspectors">Home Inspectors</a>
                </li>
                <li>
                  <a href="/solutions/for-property-investors">
                    Property Investors
                  </a>
                </li>
              </ul>

              <h4 className="heading">Features</h4>
              <ul className="link-list">
                <li>
                  <a href="/features/domoreport">Home Repair Estimate</a>
                </li>
                <li>
                  <a href="/features/documents">Documents</a>
                </li>
                <li>
                  <a href="/features/explore">Explore</a>
                </li>
              </ul>
            </div>

            <div className="col-12 col-sm-4 col-xl-3 order-2 order-xl-3">
              <h4 className="heading">Discover</h4>
              <ul className="link-list">
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a
                    href="https://blog.majordomo.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/agent-toolkit">Agent Toolkit</a>
                </li>
              </ul>
            </div> */}

          <div className="col-12 col-sm-4 col-xl-3 order-3 order-xl-4">
            <h4 className="heading">
              <b>Support</b>
            </h4>
            <ul className="link-list">
              {/* <li>
                  <a
                    href="https://intercom.help/majordomo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li> */}
              <li>
                <a href="https://help.thumbtack.com/">Help</a>
              </li>
              <li>
                <a href="https://www.thumbtack.com/terms/">Terms of Use</a>
              </li>
              <li>
                <a href="https://www.thumbtack.com/privacy/">Privacy Policy</a>
              </li>
              <li>
                <a href="https://www.thumbtack.com/privacy/#supplemental-privacy-notice-for-california-residents">
                  CA Privacy Notice
                </a>
              </li>
              <li>
                <a href="https://www.thumbtack.com/syndication-opt-out">
                  Do not Sell or Share My Personal Information
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
