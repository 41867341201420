import "./index.scss";
import React from "react";
import { parseQueryString } from "../../services/parseQueryString";
import { useLocation } from "react-router";
import Axios from "axios";

export default function RequestListSidebar({
  report,
  showSidebar,
  preparedFor,
  contractorSort,
  show,
  setShow,
  repairSort,
  setRepairSort,
  setShowSharingModal,
  setShowDisplayOptionsModal,
  setPreparedFor,
  setContractorSort,
}) {
  const location = useLocation();
  const is_public = parseQueryString(window.location.search).public;
  const noRequests =
    !show.asIsRequests &&
    !show.creditRequests &&
    !show.repairRequests &&
    !show.replaceRequests;
  const userCanEdit = report
    ? report.permissions === "OWNER" ||
      report.permissions === "CONTRIBUTOR" ||
      report.permissions === "ANALYST" ||
      is_public
    : false;

  async function updateDisplaySettings(key, value) {
    try {
      const data = { [key]: value };
      await Axios.patch(
        `/api/domoreports/${report.sharingId}/request-list/`,
        data
      );
    } catch (error) {
      console.error(error.message);
    }
  }

  function handleCheckboxChange(key, value) {
    setShow({ ...show, [key]: value });
    if (!is_public) {
      updateDisplaySettings("show", { ...show, [key]: value });
    }
  }

  function handleBack(e) {
    e.preventDefault();
    const params = new URLSearchParams(location.search);
    const id = params.get("report");

    window.location = `/domoreport?report=${id}`;
  }

  function handlePrint() {
    try {
      // Print for Safari browser
      document.execCommand("print", false, undefined);
    } catch {
      window.print();
    }
  }

  return (
    <div
      className={`print-sidebar col-lg-4 d-lg-block ${
        showSidebar ? "show" : "hide"
      }`}
    >
      <div className="sidebar-content">
        <div className="sidebar-body">
          <div className="sidebar-header">
            <a href="/" onClick={handleBack} className="back-button">
              <img
                src="/assets/icons/arrow-black.svg"
                className="rotate-180"
                alt=""
              />{" "}
              Back to Home Repair Estimate
            </a>
            <h4>My Request List</h4>
            <h2>Print Settings</h2>

            <p>
              Customize the look and feel of your Repair Request List to feature
              the items you'd like to show first.
            </p>
          </div>

          <div className="sidebar-input-wrapper">
            <label htmlFor="preparedFor">Prepared For</label>
            <input
              defaultValue={preparedFor}
              disabled={!userCanEdit}
              id="preparedFor"
              onBlur={(e) =>
                updateDisplaySettings("preparedFor", e.target.value)
              }
              onChange={(e) => setPreparedFor(e.target.value)}
              placeholder="Who is this Request List prepared for?"
              type="text"
            />
          </div>

          <div className="sidebar-input-wrapper">
            <label>Sort Contractors By</label>
            <select
              onChange={(e) => {
                setContractorSort(e.target.value);
                updateDisplaySettings("sortContractorsBy", e.target.value);
              }}
              value={contractorSort}
              disabled={!userCanEdit}
            >
              <option value="cost_estimate_high">
                Estimated Cost (High to Low)
              </option>
              <option value="cost_estimate_low">
                Estimated Cost (Low to High)
              </option>
              <option value="alphabetical">Alphabetical (A-Z)</option>
            </select>
            <i className="fa fa-caret-down" />
          </div>

          <div className="sidebar-input-wrapper">
            <label>Sort Repairs By</label>
            <select
              onChange={(e) => {
                setRepairSort(e.target.value);
                updateDisplaySettings("sortRepairsBy", e.target.value);
              }}
              value={repairSort}
              disabled={!userCanEdit}
            >
              <option value="cost_estimate_high">
                Estimated Cost (High to Low)
              </option>
              <option value="cost_estimate_low">
                Estimated Cost (Low to High)
              </option>
              <option value="alphabetical">Alphabetical (A-Z)</option>
            </select>
            <i className="fa fa-caret-down" />
          </div>

          <label className="options-label">
            Display Options{" "}
            <i
              className="fa fa-info-circle"
              onClick={() => setShowDisplayOptionsModal(true)}
            />
          </label>
          <div className="inspection-page-options">
            <div className="options-wrapper include-options">
              <h4>Request Type</h4>
              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={show.repairRequests}
                  disabled={!userCanEdit}
                  onChange={(e) =>
                    handleCheckboxChange("repairRequests", e.target.checked)
                  }
                  id="repair"
                />
                <label htmlFor="repair">Repair Requests</label>
              </div>

              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={show.replaceRequests}
                  disabled={!userCanEdit}
                  onChange={(e) =>
                    handleCheckboxChange("replaceRequests", e.target.checked)
                  }
                  id="replace"
                />
                <label htmlFor="replace">Replace Requests</label>
              </div>

              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={show.creditRequests}
                  disabled={!userCanEdit}
                  onChange={(e) =>
                    handleCheckboxChange("creditRequests", e.target.checked)
                  }
                  id="credit"
                />
                <label htmlFor="credit">Credit Requests</label>
              </div>

              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={show.asIsRequests}
                  disabled={!userCanEdit}
                  onChange={(e) =>
                    handleCheckboxChange("asIsRequests", e.target.checked)
                  }
                  id="asIs"
                />
                <label htmlFor="asIs">Left As-Is</label>
              </div>
            </div>

            <div className="options-wrapper show-options">
              <h4>Report Details</h4>
              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handleCheckboxChange("requestListNotes", e.target.checked)
                  }
                  checked={show.requestListNotes}
                  disabled={!report.request.notes || !userCanEdit}
                  id="overallNotes"
                />
                <label htmlFor="overallNotes">Overall Comments</label>
              </div>

              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handleCheckboxChange("requestNotes", e.target.checked)
                  }
                  checked={show.requestNotes}
                  disabled={noRequests || !userCanEdit}
                  id="reportNotes"
                />
                <label htmlFor="reportNotes">Request Notes</label>
              </div>

              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handleCheckboxChange("requestDetails", e.target.checked)
                  }
                  checked={show.requestDetails}
                  disabled={noRequests || !userCanEdit}
                  id="details"
                />
                <label htmlFor="details">Request Details</label>
              </div>

              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handleCheckboxChange("inspectionFile", e.target.checked)
                  }
                  checked={show.inspectionFile}
                  disabled={noRequests || !userCanEdit}
                  id="pages"
                />
                <label htmlFor="pages">Inspection Pages Referenced</label>
              </div>

              {/* Still Need to set this up */}
              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handleCheckboxChange("requestQuotes", e.target.checked)
                  }
                  checked={show.requestQuotes}
                  disabled={noRequests || !userCanEdit}
                  id="quotes"
                />
                <label htmlFor="quotes">Uploaded Quotes</label>
              </div>

              <div className="sidebar-checkbox-wrapper">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handleCheckboxChange(
                      "creditEstimatedCosts",
                      e.target.checked
                    )
                  }
                  checked={show.creditEstimatedCosts}
                  disabled={noRequests || !userCanEdit}
                  id="creditCost"
                />
                <label htmlFor="creditCost">Credit Estimated Cost</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-footer d-none d-sm-flex">
        <button
          className="btn btn-copy"
          onClick={() => setShowSharingModal(true)}
        >
          <i className="fa fa-share" /> Share
        </button>
        <button className="btn btn-print" onClick={handlePrint}>
          <i className="fa fa-print" /> Print Repair List
        </button>
      </div>

      <div className="sidebar-footer d-flex d-sm-none">
        <button
          className="btn btn-copy"
          onClick={() => setShowSharingModal(true)}
        >
          <i className="fa fa-share" />
        </button>
        <button className="btn btn-print" onClick={handlePrint}>
          <i className="fa fa-print" />
        </button>
      </div>
    </div>
  );
}
