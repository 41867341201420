import React from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { Alert } from "react-bootstrap";

function PersonalDetails(props) {
  const [user, setUser] = React.useState(props.user);
  const [agent, setAgent] = React.useState(null);
  const [showMessage, setShowMessage] = React.useState(false);
  const upload = React.useRef(null);

  React.useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  function handleChange(evt, key, value) {
    if (value === "") {
      value = null;
    }

    if (user.user_account_type) {
      delete user.user_account_type;
    }

    setUser((prevState) => ({ ...prevState, [key]: value }));

    if (evt.target.id == "phoneNumber") {
      if (value && value.replace(/[-()+ ]/g, "").length >= 10) {
        user.phone_number = value;
        props.validatePhone(user);
        setShowMessage(true);
      }
    }

    if (key == "user_type") {
      user.user_type = value;

      //If phone number is null, backend throw an error
      if (user.phone_number == null) {
        delete user.phone_number;
      }

      props.updateUser(user);
      setShowMessage(true);
    }
  }

  function onChangeProfileImg(event) {
    event.stopPropagation();
    event.preventDefault();

    var formData = new FormData();
    formData.append("user_photo", event.target.files[0]);

    let uid = localStorage.getItem("majordomo-user") || null;

    props.setPhoto(event.target.files[0]);
    props.postProfileImg(formData, uid);
  }

  function updateUser() {
    //If phone number is null, backend throw an error
    if (user.phone_number == null) {
      delete user.phone_number;
    }

    props.updateUser(user);
    setShowMessage(true);
  }

  function renderAlert(variant, message) {
    return (
      <div className="col-12 md-alert-container">
        <Alert
          variant={variant}
          onClose={() => {
            setShowMessage(false);
          }}
          dismissible
        >
          <p>{message}</p>
        </Alert>
      </div>
    );
  }

  function handleExpiredToken() {
    if (
      props.expDate != null &&
      props.expDate < new Date() &&
      window.location.pathname != "/login"
    ) {
      localStorage.removeItem("majordomo-token");
      localStorage.removeItem("majordomo-temp-token");
      localStorage.removeItem("majordomo-user");
    }
  }

  let message = null;
  let variant = null;

  //Set error messages
  if (
    props.postedUserData &&
    !props.postedUserData.pending &&
    props.postedUserData.value &&
    props.postedUserData.value.error
  ) {
    message = "There was an error updating your personal details";
    variant = "danger";
  } else if (
    props.postedProfileImg &&
    !props.postedProfileImg.pending &&
    props.postedProfileImg.value &&
    !props.postedProfileImg.value.success
  ) {
    message =
      props.postedProfileImg.value.message ||
      "There was an error updating your profile photo";
    variant = "danger";
  } else if (
    props.validatedPhone &&
    !props.validatedPhone.pending &&
    !props.validatedPhone.value.validNumber
  ) {
    message = "Please provide a valid phone number";
    variant = "danger";
  }

  // Set success messages
  if (
    props.postedUserData &&
    !props.postedUserData.pending &&
    props.postedUserData.value &&
    !props.postedUserData.value.error
  ) {
    message = "Your personal details have been updated";
    variant = "success";
  } else if (
    props.postedProfileImg &&
    !props.postedProfileImg.pending &&
    props.postedProfileImg.value &&
    props.postedProfileImg.value.success
  ) {
    message =
      props.postedProfileImg.value.message ||
      "Your profile photo has been updated";
    variant = "success";
  } else if (
    props.validatedPhone &&
    !props.validatedPhone.pending &&
    props.validatedPhone.value.validNumber
  ) {
    message = "Your personal details have been updated";
    variant = "success";
  }

  return (
    <div className="card md-details col">
      <div className="card-title">Personal Details</div>
      <div className="details-card-body">
        {showMessage && message && renderAlert(variant, message)}

        <div className="col-12">
          <p style={{ margin: 0, marginBottom: "5px", fontWeight: 700 }}>
            Profile Photo
          </p>
          <input
            type="file"
            ref={upload}
            accept=".jpg,.jpeg,.png"
            style={{ display: "none" }}
            onChange={onChangeProfileImg}
          />
          {user.user_profile_pic || props.photo ? (
            <div
              className="profile-img"
              style={{
                background: `url(${props.photo ||
                  user.user_profile_pic}) center no-repeat`,
              }}
              alt="profile"
            >
              <div className="img-overlay">
                <i
                  className="fa fa-edit fa-lg"
                  onClick={() => {
                    upload.current.click();
                  }}
                />
              </div>
            </div>
          ) : (
            <button
              className="btn btn-mdblue-outline"
              onClick={() => {
                upload.current.click();
              }}
            >
              +
            </button>
          )}

          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                onBlur={updateUser}
                onChange={(evt) => {
                  handleChange(evt, "user_first_name", evt.target.value);
                }}
                defaultValue={user.user_first_name ? user.user_first_name : ""}
              />
            </div>

            <div className="form-group col-12 col-md-6">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                onBlur={updateUser}
                onChange={(evt) =>
                  handleChange(evt, "user_last_name", evt.target.value)
                }
                defaultValue={user.user_last_name ? user.user_last_name : ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                onBlur={updateUser}
                onChange={(evt) =>
                  handleChange(evt, "user_email", evt.target.value)
                }
                defaultValue={user.user_email ? user.user_email : ""}
              />
            </div>

            <div className="form-group col-12 col-md-6">
              <label>Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                className={
                  (user.phone_number &&
                    user.phone_number.replace(/[-()+ ]/g, "").length < 10) ||
                  (props.validatedPhone &&
                    !props.validatedPhone.pending &&
                    !props.validatedPhone.value.validNumber)
                    ? "form-control md-invalid"
                    : "form-control"
                }
                onChange={(evt) =>
                  handleChange(evt, "phone_number", evt.target.value)
                }
                defaultValue={user.phone_number ? user.phone_number : ""}
                placeholder="(541) 123-4567"
              />
              {user.phone_number &&
                user.phone_number.replace(/[-()+ ]/g, "").length < 10 && (
                  <p className="md-input-warning">
                    Please enter a valid phone number
                  </p>
                )}
              {props.validatedPhone &&
                !props.validatedPhone.pending &&
                !props.validatedPhone.value.validNumber && (
                  <p className="md-input-warning">
                    Please enter a valid phone number
                  </p>
                )}
            </div>
          </div>

          <div className="form-group">
            <div className="user-type-wrapper">
              <input
                id="agent"
                name="agent"
                type="radio"
                value="agent"
                onChange={(evt) => {
                  handleChange(evt, "user_type", "BROKER");
                }}
                checked={
                  agent === "BROKER" || (user.user_type === "BROKER" && !agent)
                }
              />
              <label className="user-type-label" htmlFor="agent">
                Agent
              </label>

              <input
                id="inspector"
                name="agent"
                type="radio"
                value="inspector"
                onChange={(evt) => {
                  handleChange(evt, "user_type", "INSPECTOR");
                }}
                checked={
                  agent === "INSPECTOR" ||
                  (user.user_type === "INSPECTOR" && !agent)
                }
              />
              <label className="user-type-label" htmlFor="inspector">
                Inspector
              </label>

              <input
                id="client"
                name="agent"
                type="radio"
                value="client"
                onChange={(evt) => {
                  handleChange(evt, "user_type", "CLIENT");
                }}
                checked={
                  agent === "CLIENT" || (user.user_type === "CLIENT" && !agent)
                }
              />
              <label className="user-type-label" htmlFor="client">
                Homeowner/Client
              </label>
            </div>
          </div>

          <div className="form-group">
            <a
              href={
                props.expDate != null &&
                props.expDate < new Date() &&
                window.location.pathname != "/login"
                  ? "/login"
                  : "/reset-password"
              }
              className="btn btn-danger"
              onClick={() => handleExpiredToken()}
            >
              Reset Password
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
