import "./index.scss";
import React, { useEffect } from "react";
import LoadingSpinner from "../Loading-Spinner";
import { withRouter } from "react-router-dom";
import { v1 } from "../../services/axios";

function Demo({ history }) {
  const env = "master";

  useEffect(() => {
    getToken();
  }, []);

  async function getToken() {
    const { data } = await v1.post("/users/generateTemporaryToken");
    if (data.token) {
      await localStorage.setItem("majordomo-temp-token", data.token);
      handleRedirect(window.location.pathname);
    }
  }

  function handleRedirect(path) {
    if (path === "/clarkshouse") {
      if (env === "master") {
        history.push(`/repair-estimate?public=1&report=50`);
      } else {
        history.push("/repair-estimate?public=1");
      }
    } else if (path === "/demo") {
      if (env === "master") {
        history.push(`/repair-estimate?public=1&report=2952`);
      } else {
        history.push("/repair-estimate?public=1");
      }
    } else if (path === "/longbeach-demo") {
      if (env === "master") {
        history.push(`/repair-estimate?public=1&report=5`);
      } else {
        history.push("/repair-estimate?public=1");
      }
    } else if (path === "/animalhouse") {
      if (env === "master") {
        history.push(`/repair-estimate?public=1&report=244`);
      } else {
        history.push("/repair-estimate?public=1");
      }
    } else if (path === "/home-alone") {
      if (env === "master") {
        history.push(`/repair-estimate?public=1&report=584`);
      } else {
        history.push("/repair-estimate?public=1");
      }
    } else if (path === "/christmas-story") {
      if (env === "master") {
        history.push(`/repair-estimate?public=1&report=612`);
      } else {
        history.push("/repair-estimate?public=1");
      }
    }
    // else if (path === "/explore-demo") {
    //   if (env === "master") {
    //     history.push(`/explore?public=1&report=7`);
    //   } else {
    //     history.push("/explore?public=1");
    //   }
    // }

    if (path === "/partner") {
      history.push("/for-home-inspectors");
    }
  }

  return <LoadingSpinner />;
}
export default withRouter(Demo);
