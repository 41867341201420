export const theme = {
  colors: {
    blue: [
      "#E7F5FF",
      "#EAF6FA",
      "#B2EBFF",
      "#79D2F2",
      "#009FD9",
      "#007FAD",
      "#005979",
      "#1C7ED6",
      "#1971C2",
      "#1864AB",
    ],
  },
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 20,
    xl: 24,
  },
};
