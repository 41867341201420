import "./index.scss";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import UploadQuoteModal from "../../../../ui/modals/UploadQuoteModal";
import CostInput from "../../../../ui/CostInput";
import { v1 } from "../../../../services/axios";
import PdfModal from "../../../../ui/modals/PdfModal";
import { formatCost } from "../../../../services/helpers";
import { parseQueryString } from "../../../../services/parseQueryString";
import DemoModal from "../../../../ui/modals/DemoModal";

function UserRepairCard({
  getDomoreport,
  setShowCreateRequestModal,
  repair,
  reportID,
  setRepair,
  showRequestList,
  userCanEdit,
}) {
  const [active, setActive] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quoteFile, setQuoteFile] = useState(null);
  const [quoteAmount, setQuoteAmount] = useState(null);
  const [requestNotes, setRequestNotes] = useState(null);
  const [requestType, setRequestType] = useState("");
  const [requestCredit, setRequestCredit] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showUploadQuoteModal, setShowUploadQuoteModal] = useState(false);
  const noRequestLabel = userCanEdit ? "Make A Request" : "No Request";
  const requestListKeys = ["Repair", "Replace", "Credit", "As-Is", "Exclude"];
  const is_public = parseQueryString(window.location.search).public;

  useEffect(() => {
    if (repair.request) {
      const { amount, url } = repair.quote;
      const { credit, notes, type } = repair.request;

      setRequestCredit(credit);
      setRequestNotes(notes);
      setRequestType(type);
      setQuoteAmount(amount);
      setFileName(url);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (requestType !== (repair.request && repair.request.type) && !loading) {
      setRequestType(repair.request.type);
    }

    if (fileName !== repair.quote.url && !loading) {
      setFileName(repair.quote.url);
    }

    if (quoteAmount !== repair.quote.amount && !loading) {
      setQuoteAmount(repair.quote.amount);
    }

    if (requestNotes !== (repair.request && repair.request.notes) && !loading) {
      setRequestNotes(repair.request.notes);
    }
    if (
      requestCredit !== (repair.request && repair.request.credit) &&
      !loading
    ) {
      setRequestCredit(repair.request.credit);
    }
  }, [repair]);

  useEffect(() => {
    if (quoteFile) {
      setFileName(quoteFile.name);
      updateRepairRequest();
    }
  }, [quoteFile]);

  async function updateRepairRequest(type) {
    const { details, id, title } = repair;
    let request = requestListKeys.includes(type) ? type : requestType;

    let formData = new FormData();
    formData.append("title", title);
    formData.append("details", details);

    if (request) {
      formData.append("requestType", request);
    }

    if (requestNotes) {
      formData.append("requestNotes", requestNotes);
    }

    if (request && (request === "Repair" || request === "Replace")) {
      if (quoteFile && quoteFile.name) {
        formData.append("quote", quoteFile);
      }
    }

    if (quoteAmount) {
      formData.append("quoteAmount", parseInt(quoteAmount));
    }

    if (request && request === "Credit" && requestCredit) {
      formData.append("requestCredit", requestCredit);
    } else if (
      request &&
      request === "Credit" &&
      !requestCredit &&
      quoteAmount
    ) {
      formData.append("requestCredit", parseInt(quoteAmount));
    }

    try {
      const updatedRequestData = await v1.put(
        `/domoreport/${reportID}/user-repairs/${id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (updatedRequestData.data) {
        getDomoreport();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleCardClick(e) {
    if (e.target.classList[0] !== "page-number") {
      if (is_public) {
        setShowDemoModal(true);
      } else if (userCanEdit) {
        setShowCreateRequestModal(true);
        setRepair(repair);
      } else {
        setShowPdfModal(true);
      }
    }
  }

  return (
    <div className="user-repair-card row">
      {showPdfModal && (
        <PdfModal
          hideModal={() => setShowPdfModal(false)}
          pdfFile={repair.quote.url}
          showPdfModal={showPdfModal}
        />
      )}

      {showUploadQuoteModal && (
        <UploadQuoteModal
          fileName={fileName}
          hideModal={() => setShowUploadQuoteModal(false)}
          quoteAmount={quoteAmount}
          repair={repair}
          setFileName={setFileName}
          setQuoteAmount={setQuoteAmount}
          setQuoteFile={setQuoteFile}
          updateRepairRequest={updateRepairRequest}
          userCanEdit={userCanEdit}
        />
      )}

      {showDemoModal && (
        <DemoModal
          hideModal={() => setShowDemoModal(false)}
          img="/assets/img/demos/edit-request-demo.png"
          text="preview and update your request details"
        />
      )}

      <div
        className="repair-details col-12"
        onClick={(e) => {
          handleCardClick(e);
        }}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        <div className="row">
          <div className="repair-title col-12 col-md-4">
            <div className="title-wrapper">
              <p>
                {repair.title}{" "}
                {active && <i class="fas fa-external-link-alt" />}
              </p>
            </div>

            <div className="info-item priority-tag-wrapper d-flex d-md-none">
              <div
                className={`priority-tag md-${
                  showRequestList
                    ? requestType || "No-Request"
                    : repair.priority
                }`}
              >
                <p>
                  {showRequestList
                    ? requestType || noRequestLabel
                    : repair.priority}
                </p>
              </div>

              {requestType === "Exclude" && (
                <div className="priority-tag tag-border" />
              )}
            </div>
          </div>

          <div className="repair-info col-12 col-md-8 d-none d-md-flex">
            <div className="row">
              <div className="info-item col-5 col-md-3">
                <p
                  className={
                    requestType === "Credit" &&
                    requestCredit !== repair.quote.amount
                      ? "md-total-cost"
                      : ""
                  }
                >
                  {formatCost(repair.quote.amount)}
                </p>
                {requestType === "Credit" &&
                  requestCredit !== repair.quote.amount && (
                    <p className="md-user-cost">{formatCost(requestCredit)}</p>
                  )}
              </div>

              <div className="info-item col-3 col-md-6">
                <a
                  href="/"
                  className="page-number"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPdfModal(true);
                  }}
                >
                  View File
                </a>
              </div>

              <div className="info-item priority-tag-wrapper d-none d-md-flex col-md-3">
                <div
                  className={`priority-tag md-${
                    showRequestList
                      ? requestType || "No-Request"
                      : repair.priority
                  }`}
                >
                  <p>
                    {showRequestList
                      ? requestType || noRequestLabel
                      : repair.priority}
                  </p>
                </div>

                {requestType === "Exclude" && (
                  <div className="priority-tag tag-border" />
                )}
              </div>
            </div>
          </div>

          <div className="repair-problem col-12">
            <p>{repair.details}</p>
          </div>
        </div>
      </div>

      {userCanEdit ? (
        <div
          className={`repair-actions col-12 ${
            !showRequestList ? "d-none" : "d-flex"
          }`}
        >
          <div className="row">
            <div className="col-12 col-md-9">
              <textarea
                defaultValue={requestNotes}
                disabled={is_public}
                onBlur={updateRepairRequest}
                onChange={(e) => setRequestNotes(e.target.value)}
                placeholder="Add a note..."
              />
            </div>

            <div className="repair-options col-12 col-md-3">
              <select
                className={!requestType ? "default" : ""}
                disabled={is_public}
                onChange={(e) => updateRepairRequest(e.target.value)}
                value={requestType}
              >
                <option value="Repair">Request Repair</option>
                <option value="Replace">Request Replacement</option>
                <option value="Credit">Request Credit</option>
                <option value="As-Is">Accept As-Is</option>
                <option value="Exclude">Exclude From List</option>
              </select>

              {(requestType === "Repair" || requestType === "Replace") &&
                !fileName && (
                  <button
                    className="btn upload-quote-button"
                    onClick={() => {
                      is_public
                        ? setShowDemoModal(true)
                        : setShowUploadQuoteModal(true);
                    }}
                    disabled={is_public}
                  >
                    Upload Quote
                  </button>
                )}

              {(requestType === "Repair" || requestType === "Replace") &&
                repair.quote.url &&
                quoteAmount && (
                  <div className="quote-details">
                    <div className="quote-actions">
                      <button onClick={() => setShowPdfModal(true)}>
                        <i className="fa fa-paperclip" /> View
                      </button>

                      <button
                        onClick={() => {
                          is_public
                            ? setShowDemoModal(true)
                            : setShowUploadQuoteModal(true);
                        }}
                      >
                        <i className="fa fa-pencil" /> Edit
                      </button>
                    </div>
                  </div>
                )}

              {requestType === "Credit" && (
                <div className="request-value-wrapper">
                  <CostInput
                    cost={requestCredit}
                    disabled={is_public}
                    errors={{ cost: false }}
                    onBlur={updateRepairRequest}
                    placeholder="0"
                    setCost={setRequestCredit}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`repair-actions col-12 ${
            !showRequestList || !requestNotes ? "d-none" : "d-flex"
          }`}
        >
          <p>
            <b>Notes</b>: {requestNotes}
          </p>
        </div>
      )}
    </div>
  );
}
export default withRouter(UserRepairCard);
