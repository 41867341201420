import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;
const v1 = axios.create({
  baseURL: `${baseURL}/api/1`,
  withCredentials: process.browser,
});

v1.interceptors.request.use(
  async function(config) {
    if (process.browser) {
      const token =
        localStorage.getItem("majordomo-token") ||
        localStorage.getItem("majordomo-temp-token");

      if (token) {
        config.headers.Authorization = token;
      }
    }

    return config;
  },
  async (error) => await Promise.reject(error)
);

export { v1 };

const v2 = axios.create({
  baseURL: `/api/`,
  withCredentials: true,
});

v2.interceptors.request.use(
  async function(config) {
    return config;
  },
  async (error) => await Promise.reject(error)
);

v2.defaults.withCredentials = true;

export { v2 };
