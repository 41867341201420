export function formatAddress(domoreport) {
  return domoreport
    ? domoreport.property.address +
        ", " +
        domoreport.property.city +
        ", " +
        domoreport.property.state
    : "";
}

export function getRepairColor(label) {
  let background = "bg-gray-500";
  let text = "text-gray-500";

  switch (label) {
    case "All":
      background = "bg-black";
      text = "text-black";
      break;
    case "Urgent":
      background = "bg-red-700";
      text = "text-red-700";
      break;
    case "Pressing":
      background = "bg-gold-400";
      text = "text-gold-400";
      break;
    case "Cosmetic":
      background = "bg-brand";
      text = "text-brand";
      break;
    case "As-Is":
      background = "bg-accentBlue";
      text = "text-accentBlue";
      break;
    case "Credit":
      background = "bg-green-600";
      text = "text-green-600";
      break;
    case "Repair":
      background = "bg-orange-400";
      text = "text-orange-400";
      break;
    case "Replace":
      background = "bg-orange-400";
      text = "text-orange-400";
      break;
    case "No-Request":
      background = "bg-gray-500";
      text = "text-gray-500";
      break;
    case "Exclude":
      background = "bg-white";
      text = "text-white";
      break;
    default:
      background = "bg-gray-500";
      text = "text-gray-500";
  }

  return { background, text };
}
