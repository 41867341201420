import "./index.scss";
import React, { Component } from "react";
import PdfViewer from "../PdfViewer";
import analytics from "../../services/analytics";

export default class HomeInspection extends Component {
  componentDidMount() {
    analytics.track("Inspection Viewed Again", { report: this.props.reportID });
  }

  render() {
    return (
      <div className="md-inspection-page">
        <PdfViewer reportID={this.props.reportID} />
      </div>
    );
  }
}
