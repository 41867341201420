import "./index.scss";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { parseQueryString } from "../../services/parseQueryString";
import decode from "jwt-decode";
import { v1, v2 } from "../../services/axios";
import { formatAddress } from "../../helpers/utils";

export default function AppNavigation({
  hideNotes,
  setCenter,
  allProperties,
  domoreport,
  domoreports,
  reportID,
  toggleModal,
  showSidebar,
  logout,
}) {
  const [showProperties, setShowProperties] = useState(false);
  const [reportData, setReportData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const address =
    formatAddress(reportData) ||
    (domoreport && domoreport.address
      ? domoreport.address + ", " + domoreport.city + ", " + domoreport.state
      : undefined);
  const { location, history } = window;

  const token = localStorage.getItem("majordomo-token");
  const expDate = token ? new Date(decode(token).exp * 1000) : null;

  const query = parseQueryString(window.location.search);
  const isPublic = query.public;
  const urlQueryString = `${isPublic ? "public=1&" : ""}report=${domoreport &&
    domoreport.domoreport_log_id}`;

  let reportNumber = null;

  useEffect(() => {
    if (
      expDate != null &&
      expDate < new Date() &&
      location.pathname !== "/login"
    ) {
      localStorage.removeItem("majordomo-token");
      localStorage.removeItem("majordomo-temp-token");
      localStorage.removeItem("majordomo-user");
      history.pushState(null, "", "/login");
    }
  }, []);

  useEffect(() => {
    setReportData(undefined);
    getDomoreport();
  }, [reportID]);

  // Redirect to specified domoreport and update current report in localStorage.
  function switchReport(e, domoreport) {
    e.preventDefault();

    const isPublic = parseQueryString(window.location.search).public || 0;
    let url = `?${isPublic ? "public=1&" : ""}report=${
      domoreport.domoreport_log_id
    }`;

    if (!isPublic) {
      localStorage.setItem("current-report", domoreport.domoreport_log_id);
    }

    localStorage.setItem("current-property", domoreport.property_log_id);
    localStorage.setItem("address", address);

    history.pushState(null, "", url);
    toggleProperties();
    hideNotes();
    setCenter(null, null);
  }

  function toggleProperties(e) {
    if (e) {
      e.preventDefault();
    }
    setShowProperties(!showProperties);
  }

  async function getDomoreport(id) {
    setLoading(true);
    if (id) {
      await getV2Report(id);
    } else {
      const { data } = await v1.get(`/domoreport/${reportID}`);
      if (data.sharingId) {
        await getV2Report(data.sharingId);
      }
    }
    setLoading(false);
  }

  async function getV2Report(id) {
    const v2ReportRes = await v2.get(`/domoreports/${id}`);
    if (v2ReportRes.data) {
      setReportData(v2ReportRes.data);
    }
  }

  return (
    <div
      className={showSidebar ? "sidebar show" : "sidebar"}
      id="appNavigation"
    >
      <div className="sticky-top">
        <div className="img-wrapper">
          {/* 
            If a property has a photo uploaded, display it, 
            otherwise show the street view 
          */}
          {reportData && reportData.property.photo ? (
            <img src={reportData.property.photo} alt="property" width="100%" />
          ) : !loading && address ? (
            <div
              className="img"
              style={{
                backgroundImage: `url(https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${encodeURI(
                  address
                )}&pitch=-0.76&key=AIzaSyC1Amys50tbxZpXR80eg3TtvH-f_jZ01CU)`,
              }}
            />
          ) : (
            <img
              className="img"
              src="/assets/img/thumbtack/logo_blue.png"
              alt="thumbtack logo"
              style={{ objectFit: "contain", padding: 40 }}
            />
          )}
        </div>

        {(isPublic || (domoreports && !!domoreports.length)) && (
          <div className="bottom-bar bg-uiblue500" onClick={toggleProperties}>
            <p>VIEW PROPERTIES</p>
            {showProperties ? (
              <i className="fa fa-angle-up fa-lg" name="view-properties" />
            ) : (
              <i className="fa fa-angle-down fa-lg" name="view-properties" />
            )}
          </div>
        )}

        <div
          className={`property-dropdown bg-uiblue500 ${
            showProperties === true ? "active" : ""
          }`}
        >
          {/* {domoreports && !!domoreports.length && (
              <p className="property-dropdown-subheader bg-uiblue400">
                Domoreports
              </p>
            )} */}

          {domoreports &&
            !!domoreports.length &&
            domoreports.map((report, index) => {
              const reportAddress =
                report.address + ", " + report.city + ", " + report.state;
              if (report.domoreport_log_id === parseInt(reportID)) {
                reportNumber = domoreports.length - index;
              }

              return (
                <Link
                  className="property-dropdown-link"
                  key={index}
                  onClick={(e) => switchReport(e, report)}
                  to="/"
                >
                  {report.address
                    ? reportAddress
                    : `Home Repair Estimate #${domoreports.length - index}`}
                </Link>
              );
            })}

          {/* Display only the public domoreports */}
          {isPublic &&
            allProperties &&
            !!allProperties.length &&
            allProperties.map((property, index) => {
              const propertyAddress =
                property.address + ", " + property.city + ", " + property.state;

              if (property.domoreport_log_id === parseInt(reportID)) {
                reportNumber = allProperties.length - index;
              }

              return (
                <Link
                  className="property-dropdown-link"
                  key={index}
                  onClick={(e) => switchReport(e, property)}
                  to="/"
                >
                  {property.address
                    ? propertyAddress
                    : `Repair Estimate #${allProperties.length - index}`}
                </Link>
              );
            })}

          {isPublic && token && (
            <a href="/repair-estimate" className="md-reports-toggle">
              View my properties
            </a>
          )}
        </div>

        {domoreport && (
          <div className="address-wrapper">
            <div className="address">
              {domoreport && domoreport.address ? (
                <p>
                  {domoreport.address}, <br />
                  {domoreport.city}, {domoreport.state}
                </p>
              ) : (
                <p>Home Repair Estimate #{reportNumber}</p>
              )}
            </div>
          </div>
        )}

        {/* Sidebar Links */}
        <ul className="nav flex-column link-wrapper">
          <Link to={`/repair-estimate?${urlQueryString}`}>
            <li
              className={`nav-item nav-domoreport ${
                location.pathname === "/repair-estimate" ? "active" : ""
              }`}
            >
              <span className="icon">
                <img src="/assets/icons/Report.svg" width="25" alt="" />
              </span>
              Repair Estimate
            </li>
          </Link>

          <Link
            to={`/repair-estimate/home-inspection?${urlQueryString}`}
            className="d-none d-sm-inline"
          >
            <li
              className={`nav-item nav-inspection ${
                location.pathname === "/repair-estimate/home-inspection"
                  ? "active"
                  : ""
              }`}
            >
              <span className="icon">
                <img src="/assets/icons/Inspection.svg" width="25" alt="" />
              </span>
              Home Inspection
            </li>
          </Link>

          <Link
            to={`/repair-estimate/home-inspection?${urlQueryString}`}
            className="d-inline d-sm-none"
            target="_blank"
          >
            <li
              className={`nav-item nav-inspection ${
                location.pathname === "/repair-estimate/home-inspection"
                  ? "active"
                  : ""
              }`}
            >
              <span className="icon">
                <img src="/assets/icons/Inspection.svg" width="25" alt="" />
              </span>
              Home Inspection
            </li>
          </Link>

          <Link to={`/repair-estimate/documents?${urlQueryString}`}>
            <li
              className={`nav-item nav-documents ${
                location.pathname === "/repair-estimate/documents"
                  ? "active"
                  : ""
              }`}
            >
              <span className="icon">
                <img
                  src="/assets/icons/Documents-black.svg"
                  width="25"
                  alt=""
                />
              </span>
              Documents
            </li>
          </Link>

          {/* <Link to={`/explore?${urlQueryString}`}>
            <li
              className={`nav-item nav-explore ${
                location.pathname === "/explore" ? "active" : ""
              }`}
            >
              <span className="icon">
                <img src="/assets/icons/Explore.svg" width="25" alt="" />
              </span>
              Local Resources
            </li>
          </Link> */}

          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              toggleModal();
            }}
          >
            <li className="nav-item">
              <span className="icon">
                <img src="/assets/icons/Contact.svg" width="25" alt="" />
              </span>
              Contact Us
            </li>
          </Link>

          {!isPublic && token && (
            <Link to={`/repair-estimate/settings?${urlQueryString}`}>
              <li
                className={
                  location.pathname === "/repair-estimate/settings"
                    ? "nav-item nav-settings active"
                    : "nav-item nav-settings"
                }
              >
                <span className="icon">
                  <img src="/assets/icons/Settings.svg" width="25" alt="" />
                </span>
                Account Settings
              </li>
            </Link>
          )}

          {!isPublic && token && (
            <Link to="/" onClick={logout}>
              <li className="nav-item">
                <span className="icon">
                  <i className="fas fa-sign-out-alt fa-lg" />
                </span>
                Logout
              </li>
            </Link>
          )}
        </ul>
      </div>
    </div>
  );
}
