import './index.scss';
import React from 'react';

function CostInput({ cost, disabled, errors, id, onBlur, placeholder, setCost }) {

    function handleChange(e) {
        if (e.target.value != null) {
            const wholeNumber = parseInt(e.target.value.replace(/[ ,.]/g, ""));
            setCost(wholeNumber)
        }
    }

    function handleBlur() {
        if (!!onBlur) {
            onBlur()
        }
    }

    return (
        <div className={`md-cost-input ${(errors.cost || errors.requestCredit) ? 'invalid' : ''}`}>
            <span id="dollarSign">$</span>
            <input
                disabled={disabled}
                id={id || "cost"}
                name={id || "cost"}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={placeholder}
                type="number"
                value={cost != null ? cost : ""}
            />
            <span id="decimal">.00</span>
        </div>
    );
}
export default CostInput;