import "./App.scss";
import React, { Component } from "react";
import Routes from "./routes/routes";
import { MantineProvider } from "@mantine/core";
import { theme } from "./helpers/theme";

class App extends Component {
  render() {
    return (
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <div className="App">
          <Routes />
        </div>
      </MantineProvider>
    );
  }
}

export default App;
