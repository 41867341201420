import "./index.scss";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatCost } from "../../../services/helpers";
import CostInput from "../../CostInput";
import { parseQueryString } from "../../../services/parseQueryString";
import { Document, Page } from "react-pdf";
import LoadingSpinner from "../../../components/Loading-Spinner";
import { Button, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import Axios from "axios";
import ThumbtackPros from "../ProsModal";
// import { formatAddress } from "../../../helpers/utils";
import ReactGA from "react-ga4";

function RepairModal({
  reportData,
  repair,
  hideModal,
  quoteAmount,
  quoteFile,
  requestCredit,
  requestNotes,
  requestType,
  reportID,
  setFileName,
  setQuoteAmount,
  setQuoteFile,
  setRequestCredit,
  setRequestNotes,
  setRequestType,
  showRequestList,
  userCanEdit,
}) {
  const [errors, setErrors] = useState(null);
  const [showEstimateInfo, setShowEstimateInfo] = useState(false);
  const [showInspection, setShowInspection] = useState(false);
  const [pages, setPages] = useState();
  const [pros, setPros] = useState();
  const [showPros, setShowPros] = useState(false);

  const is_public = parseQueryString(window.location.search).public;
  const theme = useMantineTheme();

  let label = repair.priority;

  useEffect(() => {
    hydrate();
    getPros();
  }, []);

  async function hydrate() {
    const { quote, request } = repair;
    const pdfPages = [];

    for (
      var i = repair.inspectionPages.start;
      i <= repair.inspectionPages.end;
      i++
    ) {
      pdfPages.push(<Page key={i} pageNumber={i} />);
    }
    setPages(pdfPages);

    if (quote) {
      setQuoteAmount(quote.amount);
    }

    if (request) {
      setRequestNotes(request.notes);
      setRequestType(request.type);
      setRequestCredit(request.credit);
    }
  }

  async function getPros() {
    const searchParams = new URLSearchParams({
      zip_code: reportData.property.zip,
      utm_source: "cma-majordomo",
      category: repair.contractorType,
    });

    const { data } = await Axios.get(`/thumbtack?${searchParams}`);
    setPros(data.results);
  }

  // function openIntercom(e) {
  //   e.preventDefault();

  //   const address = formatAddress(reportData);
  //   const message = `Hello! Can you re-evaluate the estimate for the repair "${repair.title}" at address ${address}? Specifically, my thinking is…`;
  //   window.Intercom("showNewMessage", message);
  // }

  function removeFile(e) {
    e.preventDefault();

    setFileName(null);
    setQuoteFile(null);
  }

  function handleThumbtackClick(e) {
    e.preventDefault();

    setShowPros(true);
    const modalBody = document.getElementsByClassName("modal")[0];
    modalBody.scrollTo(0, 0);

    ReactGA.event({
      category: "User Actions",
      action: "Find Pros CTA Click",
      label: `Report ${reportID} Repair ${repair.id}`,
    });
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={hideModal}
      className="md-domoreport-modal"
    >
      {!showPros && (
        <Modal.Header>
          <Modal.Title className="md-domoreport-modal-title">
            {repair.title}&nbsp;
            <Link
              to={`/print-repair?report=${reportID}&repair=${
                repair.id
              }&inspection=${showInspection ? "1" : "0"}`}
              target="_blank"
            >
              <span title={`Print ${repair.title} details`}>
                <i className="fa fa-print d-none d-sm-inline" />
              </span>
            </Link>
          </Modal.Title>
          <div>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                hideModal();
              }}
              className="m-0"
            >
              Save and Close
            </a>
          </div>
        </Modal.Header>
      )}

      <Modal.Body>
        {showPros && !!pros.length ? (
          <ThumbtackPros
            pros={pros}
            setShowPros={setShowPros}
            reportId={reportData.sharingId}
            numericId={reportID}
          />
        ) : (
          <>
            <div className="md-domoreport-modal-details">
              <span
                className={`md-priority-tag md-${
                  showRequestList
                    ? repair.request.type || "uigray500"
                    : repair.priority
                }`}
              >
                {showRequestList
                  ? repair.request.type || "Make A Request"
                  : label}
              </span>
              <h4
                className="md-domoreport-modal-subheader"
                dangerouslySetInnerHTML={{
                  __html: "Problem: " + repair.details.featureProblem,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: repair.details.inspectionComment,
                }}
              />

              <hr />

              <h4 className="md-domoreport-modal-subheader">Project Details</h4>
              {repair.details.info && (
                <p dangerouslySetInnerHTML={{ __html: repair.details.info }} />
              )}

              <hr />
            </div>

            {(!pros || !!pros.length) && (
              <Group m="lg" style={{ justifyContent: "center" }}>
                <Button
                  size="md"
                  type="button"
                  radius="sm"
                  onClick={handleThumbtackClick}
                  styles={() => ({
                    root: {
                      backgroundColor: theme.colors.blue[4],
                      "&:hover": {
                        backgroundColor: theme.colors.blue[4],
                      },
                    },
                  })}
                >
                  Find local professionals
                </Button>
              </Group>
            )}

            <table className="modal-table">
              <tbody>
                <tr className="table-heading">
                  <td
                    colSpan="3"
                    onMouseOver={() => setShowEstimateInfo(true)}
                    onMouseLeave={() => setShowEstimateInfo(false)}
                  >
                    <span>Estimated Project Cost</span>
                    {showEstimateInfo && (
                      <p className="md-estimate-info">
                        This is the apx. minimum charge for the repair. If done
                        with other similar jobs, the cost may be reduced. Repair
                        estimates were pulled on {repair.details.updatedAt}.
                        Repair estimates are approximate and not to be
                        considered quotes. Actual repair repair.details.costs
                        may be higher, or lower.
                      </p>
                    )}
                  </td>
                  {repair.totalEstimates.separate.low ? (
                    <td colSpan="3" className="text-right">
                      {formatCost(
                        repair.totalEstimates.separate.low,
                        repair.totalEstimates.separate.high
                      )}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="md-table-light">
                  <td colSpan="3">Materials</td>
                  {repair.details.costs.material.low ? (
                    <td colSpan="3" className="text-right">
                      {formatCost(
                        repair.details.costs.material.low,
                        repair.details.costs.material.high
                      )}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="md-table-dark">
                  <td colSpan="3">Labor ({repair.contractorType})</td>
                  {repair.details.costs.labor.low ? (
                    <td colSpan="3" className="text-right">
                      {formatCost(
                        repair.details.costs.labor.low,
                        repair.details.costs.labor.high
                      )}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="md-table-light">
                  <td colSpan="3">Equipment</td>
                  {repair.details.costs.equipment.low ? (
                    <td colSpan="3" className="text-right">
                      {formatCost(
                        repair.details.costs.equipment.low,
                        repair.details.costs.equipment.high
                      )}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="md-table-dark">
                  <td colSpan="3">Minimum Trip Charge</td>
                  {repair.details.costs.contractorFees.low ? (
                    <td colSpan="3" className="text-right">
                      {formatCost(
                        repair.details.costs.contractorFees.low,
                        repair.details.costs.contractorFees.high
                      )}
                    </td>
                  ) : (
                    <td colSpan="3" className="text-right">
                      $0
                    </td>
                  )}
                </tr>
                <tr className="table-footer">
                  <td colSpan="3">
                    <b>Quantity</b>
                  </td>
                  <td colSpan="3" className="text-right">
                    {repair.details.quantity ? repair.details.quantity : "N/A"}
                  </td>
                </tr>
                <tr className="table-footer">
                  <td colSpan="3">
                    <b>Material Quality Level</b>
                  </td>
                  <td colSpan="3" className="text-right">
                    {repair.details.quality ? repair.details.quality : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>

            {userCanEdit && !is_public ? (
              <div className="request-list-wrapper">
                <div className="repair-list-header">
                  <h4 className="md-domoreport-modal-subheader">
                    Request List
                  </h4>
                </div>

                <div className="request-list-actions">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="request_type">Request</label>
                      <select
                        id="request_type"
                        className={!requestType ? "default" : ""}
                        onChange={(e) => setRequestType(e.target.value)}
                        value={requestType || ""}
                        disabled={is_public}
                      >
                        <option value="">Make A Request</option>
                        <option value="Repair">Request Repair</option>
                        <option value="Replace">Request Replacement</option>
                        <option value="Credit">Request Credit</option>
                        <option value="As-Is">Accept As-Is</option>
                        <option value="Exclude">Exclude From List</option>
                      </select>
                    </div>

                    <div className="col-6">
                      {requestType === "Credit" && (
                        <div>
                          <label htmlFor="credit">Amount Requested</label>
                          <CostInput
                            cost={requestCredit || ""}
                            disabled={is_public}
                            errors={{ cost: false }}
                            placeholder="0"
                            setCost={setRequestCredit}
                          />
                        </div>
                      )}
                    </div>

                    {(requestType === "Repair" ||
                      requestType === "Replace") && (
                      <div className="col-12 px-0 px-sm-3">
                        <div className="row">
                          <div className="col-6">
                            <label htmlFor="quote">
                              Upload Quote Document (.pdf)
                            </label>
                            {repair.quote.url ? (
                              <Stack spacing={0}>
                                <Text size="xs" lineClamp={1}>
                                  <i className="fa fa-paperclip" />{" "}
                                  {repair.quote.url}
                                </Text>
                                <a href="/" onClick={removeFile}>
                                  Change File
                                </a>
                              </Stack>
                            ) : (
                              <input
                                accept="application/pdf"
                                className="quote-file-input"
                                id="quote"
                                name="quote"
                                onChange={(e) =>
                                  setQuoteFile(e.target.files[0])
                                }
                                type="file"
                                disabled={is_public}
                              />
                            )}
                          </div>

                          {quoteFile || repair.quote.url ? (
                            <div className="col-6">
                              <label
                                className={`${
                                  errors && errors.quoteAmount ? "invalid" : ""
                                }`}
                                htmlFor="credit"
                              >
                                Estimated Repair Cost (required)
                              </label>

                              <CostInput
                                cost={quoteAmount || ""}
                                disabled={is_public}
                                errors={{ cost: errors && errors.quoteAmount }}
                                placeholder="0"
                                setCost={setQuoteAmount}
                              />
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="request-notes-wrapper col-12">
                    <label htmlFor="notes">Additional Notes</label>
                    <textarea
                      id="notes"
                      onChange={(e) => setRequestNotes(e.target.value)}
                      placeholder="Add a note..."
                      value={requestNotes || ""}
                      disabled={is_public}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}

            <div className="md-domoreport-modal-actions">
              {/* This needs to be changed to open the Intercom modal */}
              {/* <Link
                to="#"
                onClick={openIntercom}
                className="btn request-change-btn"
              >
                Request Estimate Change
              </Link> */}

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowInspection(!showInspection);
                }}
                className="btn toggle-btn"
              >
                {showInspection
                  ? "Hide Inspection Details"
                  : "Show Inspection Details"}
              </button>
            </div>

            <Document
              file={reportData.inspection.url}
              className={
                showInspection
                  ? "inspection-page show no-print"
                  : "inspection-page no-print"
              }
              loading={<LoadingSpinner />}
            >
              {pages}
            </Document>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default RepairModal;
