import "./index.scss";
import React, { Component } from "react";
import { parseQueryString } from "../../services/parseQueryString";
import withFetch from "../../hocs/withFetch";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import FileDrop from "react-file-drop";
import { Modal, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LoadingSpinner from "../Loading-Spinner";
import RootFooter from "../Root-Footer";
import { v1 } from "../../services/axios";
import Image from "../Image";
import { identifyUser } from "../../services/analytics";
import FeatureHeader from "../FeatureHeader";

@withRouter
@withFetch((props) => ({
  //Returns all domoreports associated with the specified domoreport
  documentsData: {
    url: `domoreport/documentsForDomoreport/${props.reportID}`,
  },

  //Returns the logged in users information
  userData: {
    url: `/users`,
    then: ({ user_unique_id: id, ...info }) => {
      identifyUser(id, info);
    },
  },

  //Creates NEW document
  postFile: (form) => ({
    uploadedDocument: {
      url: `/domoreport/documentForDomoreport/${props.reportID}`,
      method: "POST",
      headers: {
        "Content-Type": null,
      },
      body: form,
      force: true,
      refresh: true,
      andThen: (res) => ({
        documentsData: {
          url: `domoreport/documentsForDomoreport/${props.reportID}`,
          force: true,
          refresh: true,
        },
      }),
    },
  }),

  //Updates existing document
  putFile: (title, description, documentID) => ({
    editedDocument: {
      url: `domoreport/${props.reportID}/domoreportDocument/${documentID}`,
      method: "PUT",
      body: JSON.stringify({ doc_title: title, doc_description: description }),
      andThen: (res) => ({
        documentsData: {
          url: `domoreport/documentsForDomoreport/${props.reportID}`,
          force: true,
          refresh: true,
          // then: (res) => {
          //   props.history.push(
          //     `/documents?${props.isPublic ? "public=1&" : ""}report=${
          //       props.reportID
          //     }`
          //   );
          // },
        },
      }),
    },
  }),

  //Removes existing document
  deleteFile: (documentID) => ({
    deletedDocument: {
      url: `domoreport/${props.reportID}/domoreportDocument/${documentID}`,
      method: "DELETE",
      andThen: (res) => ({
        documentsData: {
          url: `domoreport/documentsForDomoreport/${props.reportID}`,
          force: true,
          refresh: true,
        },
      }),
    },
  }),
}))
export default class Documents extends Component {
  state = {
    file: null,
    form_data: null,
    showModal: false,
    showMessage: false,
    message: null,
    doc_title: null,
    doc_description: null,
    active: null,
    document: [],
    documents: [],
    showConfirmModal: false,
  };

  setDocumentInfo = (key, value) => {
    this.setState({ [key]: value });
  };

  closeMessage = () => {
    this.setState({
      showMessage: false,
      message: null,
    });
  };

  // This only fires when a file is DROPPED (i.e. not uploaded via "browse")
  handleFileDrop = (files) => {
    this.setState({ file: files[0] });
  };

  // This only fires when a file is UPLOADED. Does not handle dropped files.
  handleFileUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];

    this.setState({ file: file });
  };

  toggleConfirmModal = (document) => {
    if (document) {
      this.setState({
        showConfirmModal: !this.state.showConfirmModal,
        document: document,
      });
    } else {
      this.setState({
        showConfirmModal: !this.state.showConfirmModal,
        document: null,
      });
    }
  };

  submitFile = (documents) => {
    const { file, doc_title, doc_description } = this.state;

    if (file && doc_title && doc_description) {
      var formData = new FormData();
      formData.append("doc_file", file);
      formData.append("doc_file_name", file.name);
      formData.append("doc_title", doc_title);
      formData.append("doc_description", doc_description);

      if (formData) {
        this.props.postFile(formData);
      }
    } else {
      this.setState({
        message: "Please provide a file, title, and description",
        showMessage: true,
      });
    }
  };

  //Returns the INDEX of a document in a given array (if it exists).
  getIndex = (document, documents) => {
    let editedDocument = documents.map((doc, index) => {
      if (doc.doc_log_id == document.doc_log_id) {
        return index;
      } else {
        return null;
      }
    });

    let editIndex = editedDocument.filter((index) => {
      return index != null;
    });

    return editIndex;
  };

  updateFile = (title, description, document, documents) => {
    let editIndex = this.getIndex(document, documents);

    if (title && description) {
      if (editIndex) {
        documents[editIndex] = {
          contentType: document.contentType,
          doc_description: description,
          doc_title: title,
          doc_log_id: document.doc_log_id,
          mimetype: document.mimetype,
          size: document.size,
        };
        this.setState({ documents: documents });
      }

      this.props.putFile(title, description, document.doc_log_id);
      this._hideModal();
    } else {
      console.log("error");
    }
  };

  deleteFile = (document, documents) => {
    let removeIndex = this.getIndex(document, documents);
    documents.splice(removeIndex, 1);
    this.setState({
      documents: documents,
      showConfirmModal: false,
    });

    this.props.deleteFile(document.doc_log_id);
  };

  //Download file with proper headers, convert to Blob, create link with blob url, and download.
  downloadFile = async (doc) => {
    const { reportID } = this.props;
    const documentData = await v1.get(
      `/domoreport/${reportID}/domoreportDocument/${doc.doc_log_id}`,
      { responseType: "blob" }
    );

    if (documentData.data) {
      const url = window.URL.createObjectURL(new Blob([documentData.data]));
      const link = document.createElement("a");
      link.href = url;

      let title = `${doc.doc_title}${doc.extension ? "." + doc.extension : ""}`;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    }
  };

  //Download home inspection file, convert to Blob, create link with blob url, and download.
  downloadHomeInspection = async () => {
    const { reportID, userData } = this.props;
    const inspectionData = await v1.get(
      `/domoreport/inspectionFile/${reportID}`,
      { responseType: "blob" }
    );

    if (inspectionData.data) {
      const url = window.URL.createObjectURL(new Blob([inspectionData.data]));
      const link = document.createElement("a");
      let firstName = userData.value.user_first_name;
      link.href = url;

      link.setAttribute("download", firstName + "s_home_inspection.pdf");
      document.body.appendChild(link);
      link.click();
    }
  };

  componentWillReceiveProps() {
    this.props.uploadedDocument &&
      !this.props.uploadedDocument.pending &&
      this.state.showModal &&
      this._hideModal();
  }

  render() {
    const { value: documentsData } = this.props.documentsData;
    const documentID =
      parseQueryString(this.props.location.search).document || null;

    return !this.props.documentsData.pending &&
      documentsData &&
      !this.props.userData.pending ? (
      this._renderAvailable(documentsData.documents, documentID)
    ) : (
      <LoadingSpinner />
    );
  }

  _renderAvailable = (documents, documentID) => {
    return (
      <div className="md-documents-page">
        <div className="md-documents-body">
          <FeatureHeader title="Documents" variant="start" />
          <hr style={{ maxWidth: 400, marginLeft: 0 }} />

          {/* Render All Modals */}
          {this.state.showConfirmModal && this._renderConfirmModal(documents)}
          {this.state.showModal && this._renderAddModal(documents)}
          {documentID && this._renderEditModal(documents, documentID)}

          {/* Display Home Inspection associated with report */}
          <div className="row md-docs-container">
            <div className="doc-container">
              <div className="md-doc">
                <div className="row">
                  <div className="col-11">
                    <h4>Home Inspection</h4>
                  </div>
                  <div className="col-1 icon" onClick={this.showModal}>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        color="black"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={this.downloadHomeInspection}>
                          Download
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p>
                  This is the home inspection used to generate your Home Repair
                  Estimates
                </p>
              </div>
            </div>

            {/* Display all documents associated with report */}
            {documents &&
              documents.map((document) => {
                return (
                  <div className="doc-container" key={document.doc_log_id}>
                    <div className="md-doc">
                      <div className="row">
                        <div className="col-11">
                          <h4>{document.doc_title}</h4>
                        </div>
                        <div className="col-1 icon" onClick={this.showModal}>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              color="black"
                            />

                            {this.props.permission === "OWNER" ||
                            this.props.permission === "ANALYST" ? (
                              <Dropdown.Menu>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.downloadFile(document);
                                  }}
                                  className="dropdown-item"
                                  download
                                >
                                  Download
                                </a>
                                <Link
                                  to={`?${
                                    this.props.isPublic ? "public=1&" : ""
                                  }report=${this.props.reportID}&document=${
                                    document.doc_log_id
                                  }`}
                                  className="dropdown-item"
                                >
                                  Edit Description
                                </Link>
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleConfirmModal(document);
                                  }}
                                  className="dropdown-item"
                                >
                                  Delete
                                </Link>
                              </Dropdown.Menu>
                            ) : (
                              <Dropdown.Menu>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.downloadFile(document);
                                  }}
                                  className="dropdown-item"
                                  download
                                >
                                  Download
                                </a>
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                        </div>
                      </div>
                      <p>{document.doc_description}</p>
                      {document.is_image == 1 && (
                        <Image
                          sourceURL={document.doc_url}
                          token={
                            localStorage.getItem("majordomo-token") ||
                            localStorage.getItem("majordomo-temp-token")
                          }
                          primary={true}
                          pageSource="/documents"
                          toggleConfirmModal={false}
                          toggleImageModal={false}
                          deleteFile={false}
                        />
                      )}
                    </div>
                  </div>
                );
              })}

            {/* Limit adding documents to CONTRIBUTORS AND ADMINS */}
            {this.props.permission != "PUBLIC" &&
              this.props.permission != "VIEWER" && (
                <div className="doc-container" onClick={this._showModal}>
                  <div className="md-doc md-doc-add">
                    <div className="md-doc-add-btn">+</div>
                  </div>
                </div>
              )}
          </div>
        </div>

        <RootFooter />
      </div>
    );
  };

  _showModal = () => {
    this.setState({
      showModal: true,
      file: null,
      form_data: null,
      doc_title: null,
      doc_description: null,
    });
  };

  _hideModal = () => {
    this.setState({
      showModal: false,
      file: null,
      form_data: null,
      doc_title: null,
      doc_description: null,
    });

    this.props.history.push(
      `/repair-estimate/documents?${
        this.props.isPublic ? "public=1&" : ""
      }report=${this.props.reportID}`
    );
  };

  _renderAddModal = (documents) => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={this._hideModal}
        className="md-documents-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>Add New Document</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.showMessage &&
            this._renderAlert("danger", this.state.message)}
          <div className="md-file-input">
            <FileDrop onDrop={this.handleFileDrop}>
              <input
                type="file"
                ref={(ref) => (this.upload = ref)}
                style={{ display: "none" }}
                onChange={this.handleFileUpload}
              />
              <i class="fas fa-cloud-upload-alt fa-2x" />
              <p>
                {this.state.file
                  ? this.state.file.name
                  : "Drop files to attach or "}
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.upload.click();
                  }}
                >
                  {this.state.file ? "" : " browse"}
                </Link>
              </p>
            </FileDrop>
          </div>

          <label className="d-block color-dark" htmlFor="title" name="title">
            Title
          </label>
          <input
            type="text"
            name="title"
            maxLength="50"
            onChange={(evt) => {
              this.setDocumentInfo("doc_title", evt.target.value);
            }}
          />
          <p className="md-character-count">
            {this.state.doc_title ? this.state.doc_title.length : 0}/50
          </p>

          <label
            className="d-block color-dark"
            htmlFor="description"
            name="description"
          >
            Description
          </label>
          <textarea
            rows="4"
            cols="50"
            maxLength="160"
            onChange={(evt) => {
              this.setDocumentInfo("doc_description", evt.target.value);
            }}
          />
          <p className="md-character-count">
            {this.state.doc_description ? this.state.doc_description.length : 0}
            /160
          </p>

          {this.props.uploadedDocument &&
          this.props.uploadedDocument.pending ? (
            <LoadingSpinner />
          ) : (
            <button
              className="btn btn-primary"
              onClick={(e) => {
                this.submitFile(documents);
              }}
            >
              Upload
            </button>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  _renderEditModal = (documents, documentID) => {
    let document = documents.filter((document) => {
      return document.doc_log_id == documentID;
    });

    let title = this.state.doc_title || document[0].doc_title;
    let description = this.state.doc_description || document[0].doc_description;

    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={this._hideModal}
        className="md-documents-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>Edit Document</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <label className="d-block color-dark" htmlFor="title" name="title">
            Title
          </label>
          <input
            type="text"
            name="title"
            maxLength="50"
            defaultValue={document[0].doc_title}
            onChange={(evt) => {
              this.setDocumentInfo("doc_title", evt.target.value);
            }}
          />
          <p className="md-character-count">
            {this.state.doc_title ? this.state.doc_title.length : 0}/50
          </p>

          <label
            className="d-block color-dark"
            htmlFor="description"
            name="description"
          >
            Description
          </label>
          <textarea
            rows="4"
            cols="50"
            maxLength="160"
            defaultValue={document[0].doc_description}
            onChange={(evt) => {
              this.setDocumentInfo("doc_description", evt.target.value);
            }}
          ></textarea>
          <p className="md-character-count">
            {this.state.doc_description ? this.state.doc_description.length : 0}
            /160
          </p>

          {this.props.editedDocument && this.props.editedDocument.pending ? (
            <LoadingSpinner />
          ) : (
            <button
              className="btn btn-primary"
              onClick={(e) => {
                this.updateFile(title, description, document[0], documents);
              }}
            >
              Update
            </button>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  _renderConfirmModal = (documents) => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={this.toggleConfirmModal}
        className="md-confirm-modal"
      >
        <Modal.Body>
          <p className="">
            <b>Are you sure you want to delete this document?</b>
          </p>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => this.deleteFile(this.state.document, documents)}
          >
            Yes
          </button>
          <button
            className="btn btn-uigray500"
            onClick={this.toggleConfirmModal}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  _renderAlert(variant, message) {
    return (
      <Alert
        variant={variant}
        onClose={this.closeMessage}
        className="col-12"
        dismissible
      >
        <p>{message}</p>
      </Alert>
    );
  }
}
