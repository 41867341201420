import "./index.scss";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import UploadQuoteModal from "../../../../ui/modals/UploadQuoteModal";
import { v1, v2 } from "../../../../services/axios";
import RepairModal from "../../../../ui/modals/RepairModal";
import PdfModal from "../../../../ui/modals/PdfModal";
import { formatCost } from "../../../../services/helpers";
import CostInput from "../../../../ui/CostInput";
import striptags from "striptags";
import ConfirmModal from "../../../../ui/modals/ConfirmModal";
import DemoModal from "../../../../ui/modals/DemoModal";
import { parseQueryString } from "../../../../services/parseQueryString";
import ProsModal from "../../../../ui/modals/ProsModal";
import ReactGA from "react-ga4";

function RepairTableCard({
  reportData,
  getDomoreport,
  pdfFile,
  repair,
  reportID,
  showRequestList,
  userCanEdit,
}) {
  const [active, setActive] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [pdfOverride, setPdfOverride] = useState(null);
  const [quoteAmount, setQuoteAmount] = useState(null);
  const [quoteFile, setQuoteFile] = useState(null);
  const [quotePending, setQuotePending] = useState(false);
  const [requestNotes, setRequestNotes] = useState(null);
  const [requestType, setRequestType] = useState("");
  const [requestCredit, setRequestCredit] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showRepairModal, setShowRepairModal] = useState(false);
  const [showProsModal, setShowProsModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showUploadQuoteModal, setShowUploadQuoteModal] = useState(false);
  const noRequestLabel = userCanEdit ? "Make A Request" : "No Request";
  const isPublic = parseQueryString(window.location.search).public;

  let label = repair.priority;

  const { request, quote, totalEstimates } = repair;
  const displayType = requestType || request.type || "No-Request";
  const defaultCredit = Math.ceil(
    (repair.totalEstimates.separate.low + repair.totalEstimates.separate.high) /
      2
  );
  const combined =
    (displayType === "Repair" || displayType === "Replace") &&
    totalEstimates.combined.low !== totalEstimates.separate.low &&
    totalEstimates.combined.high !== totalEstimates.separate.high;

  let cost = formatCost(
    totalEstimates.separate.low,
    totalEstimates.separate.high
  );

  useEffect(() => {
    if (repair) {
      let type = repair.request.type;
      if (!type) {
        type = "No-Request";
      }
      setRequestType(type);

      setRequestNotes(repair.request.notes);

      if (repair.request.credit) {
        setRequestCredit(repair.request.credit);
      }
    }
  }, [repair]);

  if (combined && !quote.amount) {
    cost = formatCost(
      totalEstimates.combined.low,
      totalEstimates.combined.high
    );
  } else if (displayType === "Credit") {
    cost = `Credit: ${formatCost(
      request.credit && request.credit !== 0 ? request.credit : defaultCredit
    )}`;
  } else if (
    (displayType === "Repair" || displayType === "Replace") &&
    quote &&
    quote.amount
  ) {
    cost = `Quote: ${formatCost(quote.amount)}`;
  }

  async function handleSubmit() {
    if (userCanEdit && !isPublic) {
      const formData = new FormData();
      if (displayType === "No-Request") {
        formData.append("requestType", "null");
      } else {
        formData.append("requestType", displayType);
      }

      if (displayType === "Credit") {
        const defaultCredit = Math.ceil(
          (totalEstimates.separate.low + totalEstimates.separate.high) / 2
        );
        formData.append(
          "requestCredit",
          `${
            requestCredit && requestCredit !== 0 ? requestCredit : defaultCredit
          }`
        );
      }

      formData.append("requestNotes", requestNotes);

      try {
        const { status } = await v2.put(
          `/domoreports/${reportData.sharingId}/domo-repairs/${repair.id}`,
          formData
        );
        if (status === 200) {
          await getDomoreport(reportData.sharingId);
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      // updatePublicRepairList({
      //   ...repair,
      //   request: {
      //     type: displayType,
      //     notes: requestNotes,
      //     credit:
      //       displayType === 'Credit'
      //         ? requestCredit ?? defaultCredit
      //         : undefined
      //   }
      // })
    }
  }

  async function deleteQuote(e) {
    e.preventDefault();
    setDeleting(true);

    try {
      const deletedQuote = await v1.delete(
        `/domoreport/${reportID}/domo-repairs/${repair.id}/quote`
      );
      if (deletedQuote.status === 204) {
        await getDomoreport(reportData.sharingId);
        setDeleting(false);
        setShowConfirmModal(false);
      }
    } catch (error) {
      setDeleting(false);
      console.error(error);
    }
  }

  function handleCardClick(e) {
    if (e.target.classList[0] !== "page-number") {
      setShowRepairModal(true);
      ReactGA.event({
        category: "User Actions",
        action: "Repair Card Click",
        label: `Report ${reportID} Repair ${repair.id}`,
      });
    }
  }

  return (
    <div className="repair-table-card row">
      {showRepairModal && (
        <RepairModal
          reportData={reportData}
          fileName={fileName}
          hideModal={() => {
            setShowRepairModal(false);
            handleSubmit();
          }}
          quoteAmount={quoteAmount}
          quoteFile={quoteFile}
          repair={repair}
          requestCredit={requestCredit}
          requestNotes={requestNotes}
          requestType={requestType}
          reportID={reportID}
          setFileName={setFileName}
          setQuoteAmount={setQuoteAmount}
          setQuoteFile={setQuoteFile}
          setRequestCredit={setRequestCredit}
          setRequestNotes={setRequestNotes}
          setRequestType={setRequestType}
          setShowProsModal={setShowProsModal}
          showRequestList={showRequestList}
          userCanEdit={userCanEdit}
        />
      )}

      {showProsModal && (
        <ProsModal
          hideModal={() => setShowProsModal(false)}
          category={repair.contractorType}
        />
      )}

      {showPdfModal && (
        <PdfModal
          end={pdfOverride ? null : repair.inspectionPages.end}
          hideModal={() => {
            setShowPdfModal(false);
            setPdfOverride(null);
          }}
          pdfFile={reportData.inspection.url}
          start={pdfOverride ? null : repair.inspectionPages.start}
        />
      )}

      {showUploadQuoteModal && (
        <UploadQuoteModal
          fileName={fileName}
          hideModal={() => setShowUploadQuoteModal(false)}
          quoteAmount={quoteAmount}
          quoteFile={quoteFile}
          quotePending={quotePending}
          repair={repair}
          setFileName={setFileName}
          setQuoteAmount={setQuoteAmount}
          setQuoteFile={setQuoteFile}
          updateRepairRequest={handleSubmit}
          userCanEdit={userCanEdit}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          confirmAction={deleteQuote}
          confirmText="Are you sure you want to delete this quote?"
          headerText="Delete Quote"
          hideModal={() => setShowConfirmModal(false)}
          pending={deleting}
          variant="delete"
        />
      )}

      {showDemoModal && (
        <DemoModal
          hideModal={() => setShowDemoModal(false)}
          img="/assets/img/demos/upload-quote-demo.png"
          text="upload a contractor quote for this project"
        />
      )}

      <div
        className={`repair-details col-12 ${
          showRequestList || !repair.request.type ? "show-request-options" : ""
        }`}
        onClick={handleCardClick}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        <div className="row">
          <div className="repair-title col-12 col-md-5">
            <div className="title-wrapper">
              <p className={`${active ? "active-project-name" : ""}`}>
                {repair.title}{" "}
                {active && <i className="fa fa-arrow-circle-right" />}
              </p>
            </div>

            <div className="info-item priority-tag-wrapper d-flex d-md-none">
              <div
                className={`priority-tag md-${
                  showRequestList
                    ? requestType || repair.request.type || "No-Request"
                    : repair.priority
                }`}
              >
                <p>
                  {showRequestList
                    ? requestType || repair.request.type || noRequestLabel
                    : label}
                </p>
              </div>

              {showRequestList && requestType === "Exclude" && (
                <div className="priority-tag tag-border" />
              )}
            </div>
          </div>

          <div className="repair-info col-12 col-md-7 d-none d-md-flex">
            <div className="row">
              <div className="info-item col-5 col-md-3">
                <p className="d-flex">
                  <span
                    className={
                      cost !==
                      formatCost(
                        totalEstimates.separate.low,
                        totalEstimates.separate.high
                      )
                        ? "md-total-cost"
                        : ""
                    }
                  >
                    {formatCost(
                      repair.totalEstimates.separate.low,
                      repair.totalEstimates.separate.high
                    )}
                  </span>

                  {cost !==
                    formatCost(
                      totalEstimates.separate.low,
                      totalEstimates.separate.high
                    ) && (
                    <span className={`md-combined-cost md-${requestType}-text`}>
                      {cost}
                    </span>
                  )}
                </p>
              </div>
              <div className="info-item col-4">
                <p>{repair.contractorType}</p>
              </div>
              <div className="info-item col-3 col-md-2">
                <p
                  onClick={() => setShowPdfModal(true)}
                  className="page-number"
                >
                  {repair.inspectionPages.start !== repair.inspectionPages.end
                    ? repair.inspectionPages.start +
                      " - " +
                      repair.inspectionPages.end
                    : repair.inspectionPages.start}
                </p>
              </div>

              <div className="info-item priority-tag-wrapper col-3 d-none d-md-flex">
                <div
                  className={`priority-tag md-${
                    showRequestList
                      ? requestType || repair.request.type || "No-Request"
                      : repair.priority
                  }`}
                >
                  <p>
                    {showRequestList
                      ? requestType || repair.request.type || noRequestLabel
                      : label}
                  </p>
                </div>

                {showRequestList && requestType === "Exclude" && (
                  <div className="info-item priority-tag tag-border">
                    <p>
                      {showRequestList
                        ? requestType || repair.request.type || noRequestLabel
                        : label}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={`repair-problem col-12 ${
              repair.details.inspectionComment ? "" : "d-none"
            }`}
          >
            <p>{striptags(repair.details.inspectionComment)}</p>
          </div>

          <div
            className={`repair-request-details col-4 col-md-1 ${
              requestType && !showRequestList ? "" : "d-none"
            } ${requestType}`}
          >
            <p className={`md-${requestType}-text`}>{repair.request.type}</p>
          </div>
        </div>
      </div>

      {userCanEdit ? (
        <div
          className={`repair-actions col-12 ${
            !showRequestList ? "d-none" : "d-flex"
          }`}
        >
          <div className="row">
            <div className="col-12 col-md-9">
              <textarea
                value={requestNotes || ""}
                onBlur={handleSubmit}
                onChange={(e) => setRequestNotes(e.target.value)}
                placeholder="Add a note..."
                disabled={isPublic}
              />
            </div>

            <div className="repair-options col-12 col-md-3">
              <select
                className={!requestType ? "default" : ""}
                onBlur={handleSubmit}
                onChange={(e) => setRequestType(e.target.value)}
                value={requestType || ""}
                disabled={isPublic}
              >
                <option value="">Make A Request</option>
                <option value="Repair">Request Repair</option>
                <option value="Replace">Request Replacement</option>
                <option value="Credit">Request Credit</option>
                <option value="As-Is">Accept As-Is</option>
                <option value="Exclude">Exclude From List</option>
              </select>

              {(requestType === "Repair" || requestType === "Replace") &&
                !repair.quote.url &&
                userCanEdit && (
                  <button
                    className="btn upload-quote-button"
                    onClick={() =>
                      isPublic
                        ? setShowDemoModal(true)
                        : setShowUploadQuoteModal(true)
                    }
                  >
                    Upload Quote
                  </button>
                )}

              {(requestType === "Repair" || requestType === "Replace") &&
                !!repair.quote.url &&
                !!(quoteAmount || repair.quote.amount) && (
                  <div
                    className={`quote-details ${deleting ? "deleting" : ""}`}
                  >
                    <div className="quote-actions">
                      <button
                        onClick={() => {
                          setShowPdfModal(true);
                          setPdfOverride(repair.quote.url);
                        }}
                      >
                        <i className="fa fa-paperclip" /> View
                      </button>

                      <button
                        onClick={() =>
                          isPublic
                            ? setShowDemoModal(true)
                            : setShowUploadQuoteModal(true)
                        }
                      >
                        <i className="fa fa-pencil" /> Edit
                      </button>

                      <button
                        className="md-danger-text"
                        onClick={() => setShowConfirmModal(true)}
                        disabled={isPublic}
                      >
                        <i className="fa fa-trash" />{" "}
                        {deleting ? "Deleting..." : "Delete"}
                      </button>
                    </div>
                  </div>
                )}

              {requestType === "Credit" && (
                <div className="request-value-wrapper">
                  <CostInput
                    cost={requestCredit}
                    disabled={isPublic}
                    errors={{ cost: false }}
                    onBlur={handleSubmit}
                    placeholder="0"
                    setCost={setRequestCredit}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`repair-actions col-12 ${
            !showRequestList || !requestNotes ? "d-none" : "d-flex"
          }`}
        >
          <p>
            <b>Notes</b>: {requestNotes}
          </p>
        </div>
      )}
    </div>
  );
}
export default withRouter(RepairTableCard);
