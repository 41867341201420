export function formatCost(a, b) {
  let low = 0;
  let high = null;

  if (a) {
    low = Math.round(a)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (b) {
    high = Math.round(b)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return high && high !== low ? "$" + low + " - " + "$" + high : "$" + low;
}

export function getRequestCost(repair) {
  const { quote, repairType, request, totalEstimates } = repair;
  let estimate = "";
  let low = 0;
  let high = 0;
  let label = "";
  let mdEstimate = "";
  const requestType = request.type || "Exclude";

  let costs = {};

  if (repairType === "user" && quote && quote.amount) {
    costs = quote.amount;
  } else if (totalEstimates.combined) {
    costs = totalEstimates.combined;
  } else if (totalEstimates.separate) {
    costs = totalEstimates.separate;
  }

  if (request.type === "Credit" && request.credit) {
    estimate = formatCost(request.credit);
    low = request.credit;
    high = request.credit;

    // Fix type
    if (repairType === "user") {
      if (costs) {
        mdEstimate = formatCost(costs);
      }
    } else {
      if (costs) {
        mdEstimate = formatCost(costs.low, costs.high);
      }
    }
  } else if (
    (request.type === "Repair" || request.type === "Replace") &&
    quote.amount
  ) {
    estimate = formatCost(quote.amount);
    low = quote.amount;
    high = quote.amount;
    label = "Quote: ";
  } else if (
    (requestType === "Repair" || requestType === "Replace") &&
    !quote.amount
  ) {
    estimate = formatCost(costs.low, costs.high);
    low = costs.low;
    high = costs.high;
    label = "Estimate: ";
  } else if (repair && repair.repairType === "user" && quote.amount) {
    estimate = formatCost(quote.amount);
    low = quote.amount;
    high = quote.amount;
    label = "Quote: ";
  } else if (costs) {
    estimate = formatCost(costs.low, costs.high);
    low = costs.low;
    high = costs.high;
    label = "Estimate: ";
  }

  return { estimate, low, high, label, mdEstimate };
}

export function getRequestMemo(repairs) {
  const requestMemo = {};
  repairs.map((repair) => {
    const { low, high } = getRequestCost(repair);

    const requestType = repair.request.type || "Exclude";
    if (requestType in requestMemo) {
      ++requestMemo[requestType].total;
      requestMemo[requestType].low = Number(requestMemo[requestType].low) + low;
      requestMemo[requestType].high =
        Number(requestMemo[requestType].high) + high;
      requestMemo[requestType].repairs.push(repair);
    } else {
      requestMemo[requestType] = {
        total: 1,
        low: low,
        high: high,
        repairs: [repair],
      };
    }

    if (!requestMemo.All && requestType !== "Exclude") {
      requestMemo.All = {
        total: 1,
        low: low,
        high: high,
        repairs: [repair],
      };
    } else if (requestType !== "Exclude") {
      ++requestMemo.All.total;
      requestMemo.All.low = Number(requestMemo.All.low) + low;
      requestMemo.All.high = Number(requestMemo.All.high) + high;
      requestMemo.All.repairs.push(repair);
    }
  });

  const requestListKeys = ["Repair", "Replace", "Credit", "As-Is"];
  requestListKeys.map((key) => {
    const headers = {};
    if (requestMemo[key] && requestMemo[key].repairs) {
      requestMemo[key].repairs.map((repair) => {
        const { contractorType } = repair;
        const { low, high } = getRequestCost(repair);

        const type = contractorType || "Specialist";

        if (headers[type]) {
          headers[type].low = Number(headers[type].low) + low;
          headers[type].high = Number(headers[type].high) + high;
        } else {
          headers[type] = {
            low: low,
            high: high,
          };
        }
      });
      requestMemo[key].headers = headers;
    }
  });

  return requestMemo;
}

export function getPdfPages(requestMemo, displayOptions) {
  const requestListKeys = ["Repair", "Replace", "Credit", "As-Is"];
  const allPages = [];
  const allQuotes = [];

  requestListKeys.map((key) => {
    if (requestMemo[key] && requestMemo[key].repairs) {
      requestMemo[key].repairs.map((repair) => {
        const { quote, repairType, request } = repair;

        if (displayOptions[request.type]) {
          if (
            repair.inspectionPages &&
            repair.inspectionPages.start &&
            allPages.indexOf(repair.inspectionPages.start) === -1
          ) {
            if (repair.inspectionPages.start !== repair.inspectionPages.end) {
              for (
                var i = repair.inspectionPages.start;
                i <= repair.inspectionPages.end;
                i++
              ) {
                if (allPages.indexOf(i) === -1) {
                  allPages.push(i);
                }
              }
            } else {
              allPages.push(repair.inspectionPages.start);
            }
          }

          if (
            (request.type === "Repair" || request.type === "Replace") &&
            quote.url
          ) {
            allQuotes.push(quote.url);
          } else if (repairType && repairType === "user" && quote.url) {
            allQuotes.push(quote.url);
          }
        }
      });
    }
  });
  allPages.sort();

  return { allPages, allQuotes };
}
