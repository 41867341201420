import './index.scss';
import React from 'react';
import { Modal } from 'react-bootstrap';

function ConfirmModal({ confirmAction, confirmText, headerText, hideModal, pending, variant }) {
    return (
        <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            onHide={hideModal}
            className="md-confirm-modal"
        >
            <Modal.Header closeButton><h4>{headerText}</h4></Modal.Header>
            <Modal.Body>
                <p>{confirmText}</p>
                <div className="md-confirm-actions">
                    <button className="btn btn-cancel" onClick={hideModal}>Cancel</button>
                    <button className={`btn btn-${variant}`} onClick={confirmAction}>{pending ? 'Processing...' : variant}</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default ConfirmModal;