import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import LoadingSpinner from "../components/Loading-Spinner";
import NotFound from "../components/Not-Found";
import RepairPrint from "../components/Printable-Repair/RepairPrint";
import RequestListPrintOptions from "../components/RequestListPrintOptions";
import SharedRequestList from "../components/SharedRequestList";
import Demo from "../components/Demo";
import ReactGA from "react-ga4";
import { v1 } from "../services/axios";

export default function Routes() {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    try {
      const { data } = await v1.get("/users");
      ReactGA.initialize("G-NQV9Q9G9BG");
      ReactGA.set({
        username: data.user_first_name + " " + data.user_last_name,
      });

      setUser(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  return !loading ? (
    <Router onEnter={() => window.scrollTo(0, 0)}>
      <div>
        <Switch>
          {/* Demos */}
          <Route exact path={"/demo"} render={() => <Demo />} />
          <Route exact path={"/longbeach-demo"} render={() => <Demo />} />
          <Route exact path={"/clarkshouse"} render={() => <Demo />} />
          <Route exact path={"/animalhouse"} render={() => <Demo />} />
          <Route exact path={"/explore-demo"} render={() => <Demo />} />
          <Route exact path={"/home-alone"} render={() => <Demo />} />
          <Route exact path={"/christmas-story"} render={() => <Demo />} />
          <Route exact path={"/partner"} render={() => <Demo />} />
          {/* Authenticated Pages */}
          <Route
            exact
            path={"/repair-estimate"}
            render={() => <MainLayout user={user} />}
          />
          <Route
            exact
            path={"/repair-estimate/home-inspection"}
            render={() => <MainLayout user={user} />}
          />
          <Route
            exact
            path={"/repair-estimate/documents"}
            render={() => <MainLayout user={user} />}
          />
          <Route
            exact
            path={"/repair-estimate/settings"}
            render={() => <MainLayout user={user} />}
          />
          {/* <Route
            exact
            path={"/explore"}
            render={() => <MainLayout user={user} />}
          /> */}
          <Route exact path={"/print-repair"} render={() => <RepairPrint />} />
          <Route
            exact
            path={"/request-list"}
            render={() => <RequestListPrintOptions user={user} />}
          />
          <Route
            exact
            path={"/request-list/:report_id"}
            render={() => <SharedRequestList />}
          />{" "}
          <Route path={"/"} render={() => <Route component={NotFound} />} />
        </Switch>
      </div>
    </Router>
  ) : (
    <LoadingSpinner />
  );
}
