import "./index.scss";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function RequestListSharingModal({ report, hideModal }) {
  const [message, setMessage] = useState(null);
  const { origin, pathname } = window.location;
  const { address, city, state, zip } = report.property;
  const fullAddress = `${address}, ${city}, ${state} ${zip}`;
  const shareUrl = origin + pathname + "/" + report.sharingId;

  function copyURL(e) {
    e.preventDefault();

    var copyText = document.getElementById("urlInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    setMessage("Link copied to clipboard");
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={hideModal}
      className="request-list-sharing-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="md-modal-title">
          Share My Request List
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {message && (
          <div className="copy-message">
            <p>{message}</p>
            <p className="close-button" onClick={() => setMessage(null)}>
              <i className="fa fa-close" />
            </p>
          </div>
        )}
        <div className="url-input-wrapper">
          <input
            id="urlInput"
            type="text"
            onFocus={(e) => e.target.select()}
            value={shareUrl}
          />
        </div>
        <div className="md-modal-actions">
          <Link to="#" onClick={copyURL} className="btn cancel-btn">
            Copy URL
          </Link>
          <a
            href={`mailto:?body=Here is my request list for the property located at ${fullAddress}: ${shareUrl}`}
            className="btn submit-btn"
          >
            Share Via Email
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default RequestListSharingModal;
