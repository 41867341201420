import "./index.scss";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { v1 } from "../../../services/axios";
import CostInput from "../../CostInput";

function CreateRequestModal({
  getDomoreport,
  hideModal,
  repair,
  reportID,
  setRepair,
}) {
  const [cost, setCost] = useState(null);
  const [details, setDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(null);
  const [message, setMessage] = useState(null);
  const [quote, setQuote] = useState(null);
  const [requestCredit, setRequestCredit] = useState(null);
  const [requestNotes, setRequestNotes] = useState(null);
  const [requestType, setRequestType] = useState("");
  const [title, setTitle] = useState(null);
  const disabled =
    !cost || !details || (!quote && !fileName) || !title || !requestType;

  useEffect(() => {
    hydrate();
  }, []);

  function hydrate() {
    if (repair) {
      const { quote, request } = repair;

      setCost(quote.amount);
      setDetails(repair.details);
      setFileName(quote.url);
      setRequestCredit(request.credit);
      setRequestNotes(request.notes);
      setRequestType(request.type);
      setTitle(repair.title);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    if (
      title &&
      details &&
      (quote || fileName) &&
      cost &&
      requestType &&
      (requestType !== "Credit" || (requestType === "Credit" && requestCredit))
    ) {
      let formData = new FormData();
      formData.append("title", title);
      formData.append("details", details);
      formData.append("quoteAmount", cost);
      formData.append("requestType", requestType);
      formData.append("requestNotes", requestNotes);

      if (quote) {
        formData.append("quote", quote[0]);
      }

      if (requestType === "Credit") {
        formData.append("requestCredit", requestCredit);
      }

      try {
        let postRequestData = {};
        if (repair) {
          postRequestData = await v1.put(
            `/domoreport/${reportID}/user-repairs/${repair.id}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
        } else {
          postRequestData = await v1.post(
            `/domoreport/${reportID}/user-repairs`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
        }

        if (postRequestData.data) {
          if (postRequestData.data.quote) {
            setFileName(postRequestData.data.quote.url);
          }

          hideModal();
          setRepair(null);
          getDomoreport();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setLoading(false);
      setMessage("All fields are required");
      setErrors({
        title: !title,
        details: !details,
        quote: !quote,
        cost: !cost,
        requestType: !requestType,
        requestCredit: !(requestType === "Credit" && requestCredit),
      });
    }
  }

  function onHide() {
    hideModal();
    setRepair(null);
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={onHide}
      className="create-project-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="md-modal-title">
          {repair ? "Edit" : "Add"} Additional Request
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {message && (
          <div className="md-error-message">
            <p>{message}</p>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setMessage(null);
                setErrors({});
              }}
            >
              X
            </a>
          </div>
        )}

        {!repair && (
          <div>
            <p>
              Add a request that's not included in your Thumbtack Home
              Inspection Report. This might be from supplemental inspections or
              contractor quotes.
            </p>
          </div>
        )}

        <div className="create-project-form">
          <label htmlFor="title">Request Title</label>
          <input
            className={`${errors.title ? "invalid" : ""}`}
            id="title"
            name="title"
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
            type="text"
            value={title || ""}
          />

          <label htmlFor="details">Request Details</label>
          <textarea
            className={`${errors.details ? "invalid" : ""}`}
            id="details"
            name="details"
            onChange={(e) => setDetails(e.target.value)}
            placeholder="Add a repair note..."
            value={details || ""}
          />

          <label htmlFor="cost">Contractor Quote</label>
          <CostInput
            cost={cost}
            errors={errors}
            placeholder="0"
            setCost={setCost}
          />

          <label htmlFor="quote">Quote Document (.pdf)</label>
          {fileName ? (
            <div className="quote-details">
              <p>
                <i className="fa fa-paperclip" />{" "}
                {fileName.length > 57
                  ? fileName.substr(57, fileName.length)
                  : fileName}
              </p>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setFileName(null);
                  setQuote(null);
                }}
              >
                Change File
              </a>
            </div>
          ) : (
            <input
              accept="application/pdf"
              className={`quote-file-input ${errors.quote ? "invalid" : ""}`}
              id="quote"
              name="quote"
              onChange={(e) => setQuote(e.target.files)}
              type="file"
            />
          )}

          <div className="request-wrapper">
            <div className="request-actions">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="request">Request</label>
                  <select
                    className={`${errors.requestType ? "invalid" : ""} ${
                      !requestType ? "default" : ""
                    }`}
                    id="request"
                    onChange={(e) => setRequestType(e.target.value)}
                    value={requestType || ""}
                  >
                    <option value="" disabled>
                      Make A Request
                    </option>
                    <option value="Repair">Request Repair</option>
                    <option value="Replace">Request Replacement</option>
                    <option value="Credit">Request Credit</option>
                    <option value="As-Is">Accept As-Is</option>
                    <option value="Exclude">Exclude From List</option>
                  </select>
                </div>

                {requestType === "Credit" && (
                  <div className="col-6">
                    <label htmlFor="requestCredit">Credit Amount</label>
                    <CostInput
                      cost={requestCredit}
                      errors={errors}
                      id="requestCredit"
                      placeholder="0"
                      setCost={setRequestCredit}
                    />
                  </div>
                )}
              </div>

              <label htmlFor="notes">Additional Notes</label>
              <textarea
                id="notes"
                onChange={(e) => setRequestNotes(e.target.value)}
                placeholder="Add a note..."
                value={requestNotes || ""}
              />
            </div>
          </div>
        </div>

        <div className="md-modal-actions">
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              hideModal();
            }}
            className="btn cancel-btn"
          >
            Cancel
          </Link>
          {loading ? (
            <Link
              to="#"
              onClick={handleSubmit}
              className={`btn submit-btn ${
                loading || disabled ? "disabled" : ""
              }`}
            >
              Processing...
            </Link>
          ) : (
            <Link
              to="#"
              onClick={handleSubmit}
              className={`btn submit-btn ${disabled ? "disabled" : ""}`}
            >
              {repair ? "Update" : "Add"} Request
            </Link>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default CreateRequestModal;
