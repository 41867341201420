import "./index.scss";
import React from "react";
import RootFooter from "../Root-Footer";
import { parseQueryString } from "../../services/parseQueryString";
import FeatureHeader from "../FeatureHeader";

export default function PendingOrder({
  reportID,
  domoreport,
  permission,
  title,
}) {
  const report = parseQueryString(window.location.search).report || 0;
  const is_public = parseQueryString(window.location.search).public || 0;

  if (is_public && !reportID) {
    this.props.history.push("/");
  }

  return (
    <div className="md-domoreport-page pending">
      {/* <div className="row md-actions-container">
        <div className="col d-lg-block md-actions">
          <a href="/repair-estimate/checkout" className="btn btn-thumbtack">
            + Order Home Repair Estimate
          </a>

          {domoreport && (
            <Link
              to={`/home-inspection?${
                is_public ? "public=1&" : ""
              }report=${reportID}`}
              className="btn btn-secondary d-none d-sm-flex"
            >
              View Inspection
            </Link>
          )}

          {permission === "OWNER" && (
            <button className="btn btn-tertiary" onClick={this.showSharing}>
              <span className="d-none d-sm-inline">Share Report&nbsp;</span>
              <i className="fa fa-share" />
            </button>
          )}
        </div>
      </div> */}

      <div className="md-domoreport-body bg-white">
        <FeatureHeader title={title} variant="center" showCredit={true} />

        <div className="md-placeholder-comments">
          {report && report !== "null" ? (
            <div>
              <p>
                Your Home Repair Estimate is being compiled. Within 24 hours
                you’ll receive an email telling you it’s ready.
              </p>

              <div>
                <p className="mb-0">
                  Get a head start on your to-do list.{" "}
                  <a
                    href={`https://thumbtack.com?utm_source=cma-majordomo&utm_medium=partnership&utm_campaign=report-in-progress-page&utm_content=${report}`}
                  >
                    Book a Thumbtack pro today
                  </a>
                  .
                </p>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>

        <div className="md-placeholder-content">
          {/* <div style={{ width: "100%", height: "100%", padding: "20px" }}>
              <embed
                src="https://www.youtube.com/v/GC0Vl9de1qA?version=3&amp;hl=en_US&amp;rel=0&amp;autohide=1&amp;autoplay=0"
                wmode="transparent"
                type="application/x-shockwave-flash"
                width="100%"
                height="500px"
              />
            </div> */}

          <img
            src="/assets/img/md-placeholder-2.png"
            width="75%"
            alt="generic majordomo placeholder"
          />
        </div>
      </div>

      {/* <div className="md-cta-container">
        <a href="/repair-estimate/checkout" className="btn btn-thumbtack">
          + Order Home Repair Estimate
        </a>
      </div> */}

      <RootFooter />
    </div>
  );
}
