export default window.analytics;

export function identifyUser(id, userInfo) {
  if (id) {
    let traits;
    if (userInfo) {
      traits = {
        name:
          userInfo.user_first_name && userInfo.user_last_name
            ? `${userInfo.user_first_name} ${userInfo.user_last_name}`
            : undefined,
        firstName: userInfo.user_first_name || undefined,
        lastName: userInfo.user_last_name || undefined,
        email: userInfo.user_email || undefined,
        phone: userInfo.phone_number || undefined,
        type: userInfo.user_type || undefined,
        id: userInfo.stripe_customer_id || undefined,
      };
    }

    window.analytics.identify(id, traits);
  }
}
